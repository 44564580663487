import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  Self,
  Optional,
  ChangeDetectorRef,
  Inject,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  booleanAttribute
} from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { OptionsFieldComponent } from '../options-field/options-field.component'
import { SelectOption } from '../../../types/forms/select-option'
import { TranslateService } from '@ngx-translate/core'
import { WsSpeechRecognitionService } from '../ws-speech-recognition.service'
import { WsNotificationsService } from '../../notifications/ws-notifications.service'
import { AbstractPlatformService } from '../../../types/module-view/abstract-platform-service'
import { MatInput } from '@angular/material/input'

@Component({
  selector: 'ws-form-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss', '../field/field.component.scss']
})
export class InputComponent
  extends OptionsFieldComponent
  implements ControlValueAccessor, OnChanges, OnInit, OnDestroy, AfterViewInit
{
  @Input() public icon = ''
  @Input() public placeholder = ''
  @Input() public type = 'text'
  @Input() public hideLabel = false
  @Input({ transform: booleanAttribute }) public autofocus = false
  @Input() public autocomplete = ''
  @Input() public enableCopyToClipboard = false

  // special input attributes for number input
  @Input() public step?: number
  @Input() public min?: number
  @Input() public max?: number
  @Input() public maxLength?: number

  // attributes to show prefix or suffix in input field
  @Input() public suffix?: string
  @Input() public prefix?: string

  // Adds autocomplete feature to input field
  @Output() public optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>()
  @Output() public iconClick = new EventEmitter<string>()

  @Output() public valueChange = new EventEmitter<string | number>()

  @Input() public debounceDelay = 0
  private lastValueChangeTimestamp = 0

  public handleInputChange() {
    this.lastValueChangeTimestamp = Date.now()
    setTimeout(() => {
      if (Date.now() - this.lastValueChangeTimestamp >= this.debounceDelay) {
        this.valueChange.emit(this.value)
      }
    }, this.debounceDelay)
  }

  @ViewChild('inputElement') inputElement?: ElementRef<MatInput>

  constructor(
    @Self()
    @Optional()
    public override ngControl: NgControl,
    public override translate: TranslateService,
    @Inject('PlatformService') public override platformService: AbstractPlatformService,
    public override speechRecognitionService: WsSpeechRecognitionService,
    public override notificationService: WsNotificationsService,
    public override cdRef: ChangeDetectorRef
  ) {
    super(ngControl, translate, platformService, speechRecognitionService, notificationService, cdRef)
  }

  public override ngOnInit() {
    super.ngOnInit()
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit()
    if (this.autofocus) {
      this.focus()
      this.cdRef.detectChanges()
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy()
    this.speechRecognitionSubject?.unsubscribe()
  }

  public override value: string | number = ''
  public filteredOptions: SelectOption[] = []

  public showPassword = false

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public override onChange(newValue: string | number) {}

  public override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes)

    // updates autocomplete options if options change from @Input
    if (changes['options']) {
      this.setFilteredOptions(this.value)
    }
  }

  public override registerOnChange(fn: (value: string | number) => void): void {
    this.onChange = fn
  }

  public override writeValue(value: string | number): void {
    this.value = value
  }

  protected override getHasErrors(): boolean {
    return this.formControl.invalid && !!this.validationErrorMessage
  }

  public override onFieldChange(input: string | number) {
    super.onFieldChange(input)
    this.value = input
    this.setFilteredOptions(input)
  }

  public focus() {
    this.inputElement?.nativeElement.focus()
  }

  public setFilteredOptions(value: string | number) {
    if (value) {
      const searchInput = typeof value === 'string' ? value.toLowerCase() : value.toString()
      // filters options depending on their label because that's what the user sees
      this.filteredOptions = this.options.filter((option) => option.label.toLowerCase().includes(searchInput))
    } else {
      // reset options to empty array to hide autocomplete if no value given, to distinct input with autocomplete from select
      this.filteredOptions = []
    }
  }
}
