<div class="ws-emoji-insert">
  <mat-tab-group
    (click)="$event.stopPropagation()"
    animationDuration="0ms"
    disablePagination
    (mousedown)="$event.preventDefault()"
  >
    <mat-tab (mousedown)="$event.preventDefault()" label="😀">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        <button
          *ngFor="let emoji of emojiMap.get('Smileys & Emotion')"
          class="my-2"
          (mousedown)="emojiSelected($event, emoji)"
        >
          <span class="emoji">{{ emoji }}</span>
        </button>
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="👋">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        <button
          *ngFor="let emoji of emojiMap.get('People & Body')"
          class="my-2"
          (mousedown)="emojiSelected($event, emoji)"
        >
          <span class="emoji">{{ emoji }}</span>
        </button>
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="🐼">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        <button
          *ngFor="let emoji of emojiMap.get('Animals & Nature')"
          class="my-2"
          (mousedown)="emojiSelected($event, emoji)"
        >
          <span class="emoji">{{ emoji }}</span>
        </button>
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="🍔">
      <div class="ws-emoji-insert-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        <button
          *ngFor="let emoji of emojiMap.get('Food & Drink')"
          class="my-2"
          (mousedown)="emojiSelected($event, emoji)"
        >
          <span class="emoji">{{ emoji }}</span>
        </button>
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="🏡">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        <button
          *ngFor="let emoji of emojiMap.get('Travel & Places')"
          class="my-2"
          (mousedown)="emojiSelected($event, emoji)"
        >
          <span class="emoji">{{ emoji }}</span>
        </button>
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="🎉">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        <button
          *ngFor="let emoji of emojiMap.get('Activities')"
          class="my-2"
          (mousedown)="emojiSelected($event, emoji)"
        >
          <span class="emoji">{{ emoji }}</span>
        </button>
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="🔣">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        <button *ngFor="let emoji of emojiMap.get('Objects')" class="my-2" (mousedown)="emojiSelected($event, emoji)">
          <span class="emoji">{{ emoji }}</span>
        </button>
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="🚩">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        <button *ngFor="let emoji of emojiMap.get('Flags')" class="my-2" (mousedown)="emojiSelected($event, emoji)">
          <span class="emoji">{{ emoji }}</span>
        </button>
      </div>
    </mat-tab>

    <mat-tab (mousedown)="$event.preventDefault()" label="☑️">
      <div class="ws-emoji-insert_emoji-list grid grid-cols-7 max-h-64" (mousedown)="$event.preventDefault()">
        <button *ngFor="let emoji of emojiMap.get('Symbols')" class="my-2" (mousedown)="emojiSelected($event, emoji)">
          <span class="emoji">{{ emoji }}</span>
        </button>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
