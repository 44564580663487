import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self
} from '@angular/core'
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { SpeechRecognitionResult } from '../../../types/forms/speech-recognition-result'
import { Subject, Subscription } from 'rxjs'
import { WsSpeechRecognitionService } from '../ws-speech-recognition.service'
import { AbstractPlatformService } from '../../../types/module-view/abstract-platform-service'
import { WsNotificationsService } from '../../notifications/ws-notifications.service'
import { FloatLabelType } from '@angular/material/form-field'
import { MatSnackBarRef } from '@angular/material/snack-bar'
import { SnackbarComponent } from '../../notifications/snackbar/snackbar.component'

@Component({
  selector: 'ws-form-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss']
})
export class FieldComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() public value: any = false
  @Input() public label = ''
  @Input() public disabled = false
  @Input() public floatLabel: FloatLabelType = 'auto'
  @Input() public clearable = false
  @Output() public clear = new EventEmitter<string>()

  @Input() public hint?: string
  @Output() public fieldBlur = new EventEmitter()
  public hasErrors = false
  public validationErrorMessage = ''

  // Adds speech recognition feature to field
  @Input() public speechRecognition = false
  @Input() inlineSpeechRecognition = false
  listening = false
  speechRecognitionSubject: Subject<SpeechRecognitionResult> | undefined
  private speechRecognitionNotificationRef?: MatSnackBarRef<SnackbarComponent>
  @Output() speechResultApproved = new EventEmitter<string>()
  public statusChangeSubscription?: Subscription

  public constructor(
    @Self()
    @Optional()
    public ngControl: NgControl,
    protected translate: TranslateService,
    @Inject('PlatformService') public platformService: AbstractPlatformService,
    public speechRecognitionService: WsSpeechRecognitionService,
    public notificationService: WsNotificationsService,
    public cdRef: ChangeDetectorRef
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }
  }

  ngAfterViewInit() {
    if (this.ngControl) {
      this.statusChangeSubscription = this.formControl.statusChanges.subscribe(() => {
        this.updateValidity()
      })
    }
  }

  ngOnDestroy() {
    this.statusChangeSubscription?.unsubscribe()
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onTouched() {}

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange(newValue: any) {}

  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn
  }

  public writeValue(value: any): void {
    this.value = value
  }

  public get formControl(): FormControl {
    return <FormControl>this.ngControl.control
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  public resetFormControlErrors() {
    this.formControl.setErrors(null)
    this.hasErrors = false
    this.validationErrorMessage = ''
  }

  public onFieldChange(input: any) {
    this.onChange(input)
    this.updateValidity()
  }

  public clearInputValue() {
    this.value = ''
    this.formControl.patchValue(this.value)
    this.onFieldChange(this.value)
    this.clear.emit(this.value)
  }

  protected getHasErrors(): boolean {
    return (
      this.formControl.invalid && (this.formControl.dirty || this.formControl.touched) && !!this.validationErrorMessage
    )
  }

  protected get hasValidators(): boolean {
    return this.formControl.validator !== null
  }

  public handleInputBlur() {
    this.updateValidity()
    this.formControl.markAsDirty()
    this.fieldBlur.emit()
  }

  public updateValidity() {
    this.validationErrorMessage = this.getValidationErrorMessage()
    this.hasErrors = this.getHasErrors()
  }

  protected getValidationErrorMessage(): string {
    if (!this.formControl.errors) {
      return ''
    }
    let translationKey = 'ws.forms.validation'
    let translationData: { [key: string]: string } = { label: this.label }

    for (const error in this.formControl.errors) {
      translationKey += `.${error}`
      translationData = { ...translationData, ...this.formControl.errors[error] }
    }

    return this.translate.instant(translationKey, translationData)
  }

  touchStartSpeechRecognition(grammar?: string) {
    if (this.inlineSpeechRecognition && this.platformService.isMobile) {
      this.startSpeechRecognition(grammar)
    }
  }

  touchStopSpeechRecognition() {
    if (this.inlineSpeechRecognition && this.platformService.isMobile) {
      this.stopSpeechRecognition()
    }
  }

  public blur() {
    setTimeout(() => {
      if (document.activeElement) {
        const activeElement = document.activeElement as HTMLElement
        activeElement.blur()
      }
    })
  }

  toggleSpeechRecognition(grammar?: string) {
    if (!this.inlineSpeechRecognition || (this.inlineSpeechRecognition && !this.platformService.isMobile)) {
      if (this.listening) {
        this.stopSpeechRecognition()
      } else {
        this.blur()
        this.startSpeechRecognition(grammar)
      }
    }
  }

  startSpeechRecognition(grammar?: string) {
    if (this.speechRecognitionNotificationRef) {
      this.speechRecognitionNotificationRef.dismiss()
    }

    this.speechRecognitionSubject = this.speechRecognitionService.startSpeechRecognition(
      this.inlineSpeechRecognition,
      grammar ? grammar : ''
    )
    this.listening = true

    this.speechRecognitionSubject.subscribe((result: SpeechRecognitionResult) => {
      switch (result.type) {
        case 'error':
          this.speechRecognitionNotificationRef = this.notificationService.createSnackBar(result.text, {
            severity: 'warning',
            duration: 3000
          })
          this.listening = false
          return
        case 'abort':
          this.listening = false
          return
        case 'final':
          if (result.text !== '' && result.text !== ' ') {
            this.value = result.text
            this.formControl.setValue(this.value)
            this.onFieldChange(this.value)
            this.speechResultApproved.emit(this.value)
          }
          this.listening = false
          break
        case 'interim':
          if (this.inlineSpeechRecognition) {
            this.value = result.text
            this.formControl.setValue(this.value)
            this.onFieldChange(this.value)
          }
          break
      }

      if (this.inlineSpeechRecognition) {
        this.cdRef.detectChanges()
      }
    })
  }

  stopSpeechRecognition() {
    if (this.inlineSpeechRecognition) {
      this.speechRecognitionService.stopSpeechRecognition()
    }
  }
}
