import { TranslateService } from '@ngx-translate/core'

/**
 * Compensates the timezone offset of a date. This can be used to display a desired date in the current timezone.
 */
export const compensateTimezone = (date: Date): Date => {
  const offset = new Date().getTimezoneOffset()
  return new Date(date.getTime() + offset * -1 * 60000)
}
/**
 * Restores the timezone offset of a date. This can be used to display a date in the correct timezone.
 */
export const restoreTimezone = (date: Date): Date => {
  const offset = new Date().getTimezoneOffset()
  return new Date(date.getTime() + offset * 60000)
}

/**
 * Wrapper class for Date object with additional functionality.
 * The class is timezone agnostic and always uses UTC.
 */
export class WsDate {
  date: Date
  translate?: TranslateService

  constructor(date: Date | string | number = new Date(), resetTime = false, translateService?: TranslateService) {
    this.translate = translateService

    if (typeof date === 'string') {
      // adds time to date string if it is missing to ensure correct timezone conversion
      if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        date += 'T00:00:00.000'
      }
    }

    this.date = new Date(date)

    if (resetTime) {
      this.resetTime()
    }
  }

  /**
   * Resets the time of the date to 00:00:00.000.
   * Can be used if only the date is relevant and the time should be ignored.
   */
  public resetTime() {
    this.date.setHours(0)
    this.date.setMinutes(0)
    this.date.setSeconds(0)
    this.date.setMilliseconds(0)
  }

  /**
   * Returns the date in the format specified
   * @param format 'long' | 'short' | 'iso'
   * @param withTime boolean Keeps the time if true, otherwise only returns the date
   */
  public format(format: WsDateFormat, withTime = false) {
    switch (format) {
      case 'long':
        return this.longDate(withTime)
      case 'short':
        return this.shortDate(withTime)
      case 'iso':
        return this.isoDate(withTime)
    }
  }

  /**
   * Returns the date in the 'long' format with or without time
   */
  private longDate(withTime = false): string {
    const dateToShow = compensateTimezone(this.date)
    const currentLang = this.getCurrentLang()
    return dateToShow.toLocaleDateString(currentLang, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      weekday: 'long',
      hour: withTime ? 'numeric' : undefined,
      minute: withTime ? '2-digit' : undefined,
      timeZone: 'UTC'
    })
  }

  /**
   * Returns the date in the 'iso' format with or without time
   */
  private isoDate(withTime = false) {
    const dateToShow = compensateTimezone(this.date)
    const isoString = dateToShow.toISOString()
    if (withTime) {
      return isoString
    }
    return isoString.split('T')[0]
  }

  /**
   * Returns the timezone offset in minutes to UTC
   */
  private timeZoneOffset(): number {
    const anyDate = new Date()
    return anyDate.getTimezoneOffset()
  }

  /**
   * Removes the timezone offset from a date and returns it
   */
  private removeTimezone(date: Date): Date {
    const offset = this.timeZoneOffset()
    return new Date(date.getTime() + offset * -1 * 60000)
  }

  /**
   * Adds minutes to the date
   */
  public addMinutes(minutes: number) {
    return new WsDate(this.date.getTime() + minutes * 60000)
  }

  /**
   * Adds hours to the date
   */
  public addHours(hours: number) {
    return new WsDate(this.date.getTime() + hours * 3600000)
  }

  /**
   * Adds days to the date
   */
  public addDays(days: number) {
    return new WsDate(this.date.getTime() + days * 86400000)
  }

  /**
   * Adds weeks to the date
   */
  public addWeeks(weeks: number) {
    return new WsDate(this.date.getTime() + weeks * 604800000)
  }

  /**
   * Adds months to the date
   */
  public addMonths(months: number) {
    const newDate = new Date(this.date.getTime())
    return new WsDate(newDate.setMonth(this.date.getMonth() + months))
  }

  /**
   * Adds years to the date
   */
  public addYears(years: number) {
    const newDate = new Date(this.date.getTime())
    return new WsDate(newDate.setFullYear(this.date.getFullYear() + years))
  }

  /**
   * Returns the date in the 'short' format with or without time
   */
  private shortDate(withTime = false) {
    const dateToShow = compensateTimezone(this.date)
    const currentLang = this.getCurrentLang()
    return dateToShow.toLocaleDateString(currentLang, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: withTime ? 'numeric' : undefined,
      minute: withTime ? '2-digit' : undefined,
      timeZone: 'UTC'
    })
  }

  /**
   * Returns the current language. If no language is set, the browser language is returned.
   * If no browser language is set, the default language is returned.
   */
  private getCurrentLang(): string | undefined {
    return this.translate?.currentLang || this.translate?.getBrowserLang() || this.translate?.getDefaultLang()
  }
}

export type WsDateFormat = 'long' | 'short' | 'iso'
