import { Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { wsCoreLibraryTranslations } from '@ws-core/core-library'
import translationsEn from '../translations/en.json'
import translationsDe from '../translations/de.json'
import { PdfService } from './pdf.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pdf'

  constructor(
    private translate: TranslateService,
    private pdfService: PdfService
  ) {
    translate.use('de')

    translate.setTranslation('en', wsCoreLibraryTranslations['en'])
    translate.setTranslation('de', wsCoreLibraryTranslations['de'])

    translate.setTranslation('en', translationsEn, true)
    translate.setTranslation('de', translationsDe, true)

    const url = new URL(window.location.href)
    const pdfTempKeyParamName = 'token'
    const tempApiKeyFromQueryParam = url.searchParams.get(pdfTempKeyParamName)

    const pdfUserIdParamName = 'user'
    const pdfUserIdFromQueryParam = url.searchParams.get(pdfUserIdParamName)

    if (tempApiKeyFromQueryParam) {
      this.pdfService.setTempApiKey(tempApiKeyFromQueryParam)
    }
    if (pdfUserIdFromQueryParam) {
      this.pdfService.setUserId(pdfUserIdFromQueryParam)
    }
  }
}
