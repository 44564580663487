import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-pdf-delete-template-dialog',
  templateUrl: './pdf-delete-template-dialog.component.html',
  styleUrls: ['./pdf-delete-template-dialog.component.scss']
})
export class PdfDeleteTemplateDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<PdfDeleteTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}

  ngOnInit() {}

  save() {
    this.dialogRef.close(true)
  }

  close() {
    this.dialogRef.close(false)
  }
}
