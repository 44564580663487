import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'localeNumber'
})
export class LocaleNumberPipe implements PipeTransform {
  transform(value: number, minimumFractionDigits = 0, maximumFractionDigits = 2): string {
    return value.toLocaleString(undefined, {
      style: 'decimal',
      minimumFractionDigits,
      maximumFractionDigits
    })
  }
}
