import { TranslateService } from '@ngx-translate/core'
import { NgxEditorConfig } from 'ngx-editor'

export function ngxEditorConfigFactory(translateService: TranslateService): NgxEditorConfig {
  return {
    locals: {
      bold: translateService.stream('ws.forms.textEditor.tooltip.bold'),
      italic: translateService.stream('ws.forms.textEditor.tooltip.italic'),
      underline: translateService.stream('ws.forms.textEditor.tooltip.underline'),
      strike: translateService.stream('ws.forms.textEditor.tooltip.strikeThrough'),
      blockquote: translateService.stream('ws.forms.textEditor.tooltip.blockquote'),
      bullet_list: translateService.stream('ws.forms.textEditor.tooltip.unorderedList'),
      ordered_list: translateService.stream('ws.forms.textEditor.tooltip.orderedList'),
      heading: translateService.stream('ws.forms.textEditor.formattingOption.heading'),
      h1: translateService.stream('ws.forms.textEditor.formattingOption.h1'),
      h2: translateService.stream('ws.forms.textEditor.formattingOption.h2'),
      h3: translateService.stream('ws.forms.textEditor.formattingOption.h3'),
      h4: translateService.stream('ws.forms.textEditor.formattingOption.h4'),
      h5: translateService.stream('ws.forms.textEditor.formattingOption.h5'),
      h6: translateService.stream('ws.forms.textEditor.formattingOption.h6'),
      align_left: translateService.stream('ws.forms.textEditor.tooltip.alignLeft'),
      align_center: translateService.stream('ws.forms.textEditor.tooltip.alignCenter'),
      align_right: translateService.stream('ws.forms.textEditor.tooltip.alignRight'),
      align_justify: translateService.stream('ws.forms.textEditor.tooltip.alignJustify'),
      text_color: translateService.stream('ws.forms.textEditor.tooltip.selectColor'),
      background_color: translateService.stream('ws.forms.textEditor.tooltip.backgroundColor'),
      horizontal_rule: translateService.stream('ws.forms.textEditor.tooltip.separator'),
      format_clear: translateService.stream('ws.forms.textEditor.tooltip.removeFormat'),
      insertLink: translateService.stream('ws.forms.textEditor.tooltip.link'),
      removeLink: translateService.stream('ws.forms.textEditor.tooltip.unlink'),
      insertImage: translateService.stream('ws.forms.textEditor.tooltip.insertImage'),
      indent: translateService.stream('ws.forms.textEditor.tooltip.indent'),
      outdent: translateService.stream('ws.forms.textEditor.tooltip.outdent'),
      superscript: translateService.stream('ws.forms.textEditor.tooltip.superScript'),
      subscript: translateService.stream('ws.forms.textEditor.tooltip.subScript'),
      undo: translateService.stream('ws.forms.textEditor.tooltip.undo'),
      redo: translateService.stream('ws.forms.textEditor.tooltip.redo'),

      // pupups, forms, others...
      url: translateService.stream('ws.forms.textEditor.tooltip.url'),
      text: translateService.stream('ws.forms.textEditor.tooltip.text'),
      openInNewTab: translateService.stream('ws.forms.textEditor.tooltip.openInNewTab'),
      insert: translateService.stream('ws.forms.textEditor.tooltip.insert'),
      altText: translateService.stream('ws.forms.textEditor.tooltip.altText'),
      title: translateService.stream('ws.forms.textEditor.tooltip.title'),
      remove: translateService.stream('ws.forms.textEditor.tooltip.remove'),
      enterValidUrl: translateService.stream('ws.forms.textEditor.tooltip.enterValidUrl')
    }
  }
}
