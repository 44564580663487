[
  {
    "category": "Smileys & Emotion",
    "emoji": "😀"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😃"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😄"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😁"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😆"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😅"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤣"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😂"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🙂"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🙃"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🫠"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😉"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😊"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😇"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🥰"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😍"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤩"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😘"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😗"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "☺️"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😚"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😙"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🥲"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😋"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😛"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😜"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤪"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😝"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤑"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤗"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤭"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🫢"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🫣"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤫"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤔"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🫡"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤐"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤨"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😐"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😑"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😶"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🫥"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😶‍🌫️"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😏"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😒"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🙄"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😬"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😮‍💨"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤥"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🫨"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😌"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😔"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😪"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤤"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😴"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😷"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤒"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤕"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤢"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤮"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤧"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🥵"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🥶"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🥴"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😵"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😵‍💫"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤯"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤠"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🥳"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🥸"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😎"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤓"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🧐"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😕"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🫤"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😟"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🙁"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "☹️"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😮"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😯"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😲"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😳"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🥺"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🥹"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😦"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😧"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😨"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😰"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😥"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😢"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😭"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😱"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😖"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😣"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😞"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😓"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😩"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😫"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🥱"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😤"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😡"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😠"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤬"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😈"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "👿"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💀"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "☠️"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💩"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤡"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "👹"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "👺"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "👻"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "👽"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "👾"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤖"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😺"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😸"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😹"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😻"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😼"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😽"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🙀"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😿"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "😾"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🙈"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🙉"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🙊"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💌"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💘"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💝"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💖"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💗"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💓"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💞"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💕"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💟"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "❣️"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💔"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "❤️‍🔥"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "❤️‍🩹"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "❤️"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🩷"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🧡"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💛"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💚"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💙"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🩵"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💜"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤎"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🖤"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🩶"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🤍"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💋"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💯"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💢"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💥"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💫"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💦"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💨"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🕳️"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💬"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "👁️‍🗨️"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🗨️"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "🗯️"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💭"
  },
  {
    "category": "Smileys & Emotion",
    "emoji": "💤"
  },
  {
    "category": "People & Body",
    "emoji": "👋"
  },
  {
    "category": "People & Body",
    "emoji": "🤚"
  },
  {
    "category": "People & Body",
    "emoji": "🖐️"
  },
  {
    "category": "People & Body",
    "emoji": "✋"
  },
  {
    "category": "People & Body",
    "emoji": "🖖"
  },
  {
    "category": "People & Body",
    "emoji": "🫱"
  },
  {
    "category": "People & Body",
    "emoji": "🫲"
  },
  {
    "category": "People & Body",
    "emoji": "🫳"
  },
  {
    "category": "People & Body",
    "emoji": "🫴"
  },
  {
    "category": "People & Body",
    "emoji": "🫷"
  },
  {
    "category": "People & Body",
    "emoji": "🫸"
  },
  {
    "category": "People & Body",
    "emoji": "👌"
  },
  {
    "category": "People & Body",
    "emoji": "🤌"
  },
  {
    "category": "People & Body",
    "emoji": "🤏"
  },
  {
    "category": "People & Body",
    "emoji": "✌️"
  },
  {
    "category": "People & Body",
    "emoji": "🤞"
  },
  {
    "category": "People & Body",
    "emoji": "🫰"
  },
  {
    "category": "People & Body",
    "emoji": "🤟"
  },
  {
    "category": "People & Body",
    "emoji": "🤘"
  },
  {
    "category": "People & Body",
    "emoji": "🤙"
  },
  {
    "category": "People & Body",
    "emoji": "👈"
  },
  {
    "category": "People & Body",
    "emoji": "👉"
  },
  {
    "category": "People & Body",
    "emoji": "👆"
  },
  {
    "category": "People & Body",
    "emoji": "🖕"
  },
  {
    "category": "People & Body",
    "emoji": "👇"
  },
  {
    "category": "People & Body",
    "emoji": "☝️"
  },
  {
    "category": "People & Body",
    "emoji": "🫵"
  },
  {
    "category": "People & Body",
    "emoji": "👍"
  },
  {
    "category": "People & Body",
    "emoji": "👎"
  },
  {
    "category": "People & Body",
    "emoji": "✊"
  },
  {
    "category": "People & Body",
    "emoji": "👊"
  },
  {
    "category": "People & Body",
    "emoji": "🤛"
  },
  {
    "category": "People & Body",
    "emoji": "🤜"
  },
  {
    "category": "People & Body",
    "emoji": "👏"
  },
  {
    "category": "People & Body",
    "emoji": "🙌"
  },
  {
    "category": "People & Body",
    "emoji": "🫶"
  },
  {
    "category": "People & Body",
    "emoji": "👐"
  },
  {
    "category": "People & Body",
    "emoji": "🤲"
  },
  {
    "category": "People & Body",
    "emoji": "🤝"
  },
  {
    "category": "People & Body",
    "emoji": "🙏"
  },
  {
    "category": "People & Body",
    "emoji": "✍️"
  },
  {
    "category": "People & Body",
    "emoji": "💅"
  },
  {
    "category": "People & Body",
    "emoji": "🤳"
  },
  {
    "category": "People & Body",
    "emoji": "💪"
  },
  {
    "category": "People & Body",
    "emoji": "🦾"
  },
  {
    "category": "People & Body",
    "emoji": "🦿"
  },
  {
    "category": "People & Body",
    "emoji": "🦵"
  },
  {
    "category": "People & Body",
    "emoji": "🦶"
  },
  {
    "category": "People & Body",
    "emoji": "👂"
  },
  {
    "category": "People & Body",
    "emoji": "🦻"
  },
  {
    "category": "People & Body",
    "emoji": "👃"
  },
  {
    "category": "People & Body",
    "emoji": "🧠"
  },
  {
    "category": "People & Body",
    "emoji": "🫀"
  },
  {
    "category": "People & Body",
    "emoji": "🫁"
  },
  {
    "category": "People & Body",
    "emoji": "🦷"
  },
  {
    "category": "People & Body",
    "emoji": "🦴"
  },
  {
    "category": "People & Body",
    "emoji": "👀"
  },
  {
    "category": "People & Body",
    "emoji": "👁️"
  },
  {
    "category": "People & Body",
    "emoji": "👅"
  },
  {
    "category": "People & Body",
    "emoji": "👄"
  },
  {
    "category": "People & Body",
    "emoji": "🫦"
  },
  {
    "category": "People & Body",
    "emoji": "👶"
  },
  {
    "category": "People & Body",
    "emoji": "🧒"
  },
  {
    "category": "People & Body",
    "emoji": "👦"
  },
  {
    "category": "People & Body",
    "emoji": "👧"
  },
  {
    "category": "People & Body",
    "emoji": "🧑"
  },
  {
    "category": "People & Body",
    "emoji": "👱"
  },
  {
    "category": "People & Body",
    "emoji": "👨"
  },
  {
    "category": "People & Body",
    "emoji": "🧔"
  },
  {
    "category": "People & Body",
    "emoji": "🧔‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧔‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🦰"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🦱"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🦳"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🦲"
  },
  {
    "category": "People & Body",
    "emoji": "👩"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🦰"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🦰"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🦱"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🦱"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🦳"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🦳"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🦲"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🦲"
  },
  {
    "category": "People & Body",
    "emoji": "👱‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "👱‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧓"
  },
  {
    "category": "People & Body",
    "emoji": "👴"
  },
  {
    "category": "People & Body",
    "emoji": "👵"
  },
  {
    "category": "People & Body",
    "emoji": "🙍"
  },
  {
    "category": "People & Body",
    "emoji": "🙍‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🙍‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🙎"
  },
  {
    "category": "People & Body",
    "emoji": "🙎‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🙎‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🙅"
  },
  {
    "category": "People & Body",
    "emoji": "🙅‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🙅‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🙆"
  },
  {
    "category": "People & Body",
    "emoji": "🙆‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🙆‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "💁"
  },
  {
    "category": "People & Body",
    "emoji": "💁‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "💁‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🙋"
  },
  {
    "category": "People & Body",
    "emoji": "🙋‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🙋‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧏"
  },
  {
    "category": "People & Body",
    "emoji": "🧏‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧏‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🙇"
  },
  {
    "category": "People & Body",
    "emoji": "🙇‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🙇‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🤦"
  },
  {
    "category": "People & Body",
    "emoji": "🤦‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🤦‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🤷"
  },
  {
    "category": "People & Body",
    "emoji": "🤷‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🤷‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍⚕️"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍⚕️"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍⚕️"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🎓"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🎓"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🎓"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🏫"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🏫"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🏫"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍⚖️"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍⚖️"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍⚖️"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🌾"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🌾"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🌾"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🍳"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🍳"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🍳"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🔧"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🔧"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🔧"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🏭"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🏭"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🏭"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍💼"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍💼"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍💼"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🔬"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🔬"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🔬"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍💻"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍💻"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍💻"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🎤"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🎤"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🎤"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🎨"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🎨"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🎨"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍✈️"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍✈️"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍✈️"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🚀"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🚀"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🚀"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🚒"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🚒"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🚒"
  },
  {
    "category": "People & Body",
    "emoji": "👮"
  },
  {
    "category": "People & Body",
    "emoji": "👮‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "👮‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🕵️"
  },
  {
    "category": "People & Body",
    "emoji": "🕵️‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🕵️‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "💂"
  },
  {
    "category": "People & Body",
    "emoji": "💂‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "💂‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🥷"
  },
  {
    "category": "People & Body",
    "emoji": "👷"
  },
  {
    "category": "People & Body",
    "emoji": "👷‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "👷‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🫅"
  },
  {
    "category": "People & Body",
    "emoji": "🤴"
  },
  {
    "category": "People & Body",
    "emoji": "👸"
  },
  {
    "category": "People & Body",
    "emoji": "👳"
  },
  {
    "category": "People & Body",
    "emoji": "👳‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "👳‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "👲"
  },
  {
    "category": "People & Body",
    "emoji": "🧕"
  },
  {
    "category": "People & Body",
    "emoji": "🤵"
  },
  {
    "category": "People & Body",
    "emoji": "🤵‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🤵‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "👰"
  },
  {
    "category": "People & Body",
    "emoji": "👰‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "👰‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🤰"
  },
  {
    "category": "People & Body",
    "emoji": "🫃"
  },
  {
    "category": "People & Body",
    "emoji": "🫄"
  },
  {
    "category": "People & Body",
    "emoji": "🤱"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🍼"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🍼"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🍼"
  },
  {
    "category": "People & Body",
    "emoji": "👼"
  },
  {
    "category": "People & Body",
    "emoji": "🎅"
  },
  {
    "category": "People & Body",
    "emoji": "🤶"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🎄"
  },
  {
    "category": "People & Body",
    "emoji": "🦸"
  },
  {
    "category": "People & Body",
    "emoji": "🦸‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🦸‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🦹"
  },
  {
    "category": "People & Body",
    "emoji": "🦹‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🦹‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧙"
  },
  {
    "category": "People & Body",
    "emoji": "🧙‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧙‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧚"
  },
  {
    "category": "People & Body",
    "emoji": "🧚‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧚‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧛"
  },
  {
    "category": "People & Body",
    "emoji": "🧛‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧛‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧜"
  },
  {
    "category": "People & Body",
    "emoji": "🧜‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧜‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧝"
  },
  {
    "category": "People & Body",
    "emoji": "🧝‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧝‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧞"
  },
  {
    "category": "People & Body",
    "emoji": "🧞‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧞‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧟"
  },
  {
    "category": "People & Body",
    "emoji": "🧟‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧟‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧌"
  },
  {
    "category": "People & Body",
    "emoji": "💆"
  },
  {
    "category": "People & Body",
    "emoji": "💆‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "💆‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "💇"
  },
  {
    "category": "People & Body",
    "emoji": "💇‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "💇‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🚶"
  },
  {
    "category": "People & Body",
    "emoji": "🚶‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🚶‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧍"
  },
  {
    "category": "People & Body",
    "emoji": "🧍‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧍‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧎"
  },
  {
    "category": "People & Body",
    "emoji": "🧎‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧎‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🦯"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🦯"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🦯"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🦼"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🦼"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🦼"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🦽"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍🦽"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍🦽"
  },
  {
    "category": "People & Body",
    "emoji": "🏃"
  },
  {
    "category": "People & Body",
    "emoji": "🏃‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🏃‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "💃"
  },
  {
    "category": "People & Body",
    "emoji": "🕺"
  },
  {
    "category": "People & Body",
    "emoji": "🕴️"
  },
  {
    "category": "People & Body",
    "emoji": "👯"
  },
  {
    "category": "People & Body",
    "emoji": "👯‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "👯‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧖"
  },
  {
    "category": "People & Body",
    "emoji": "🧖‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧖‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧗"
  },
  {
    "category": "People & Body",
    "emoji": "🧗‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧗‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🤺"
  },
  {
    "category": "People & Body",
    "emoji": "🏇"
  },
  {
    "category": "People & Body",
    "emoji": "⛷️"
  },
  {
    "category": "People & Body",
    "emoji": "🏂"
  },
  {
    "category": "People & Body",
    "emoji": "🏌️"
  },
  {
    "category": "People & Body",
    "emoji": "🏌️‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🏌️‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🏄"
  },
  {
    "category": "People & Body",
    "emoji": "🏄‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🏄‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🚣"
  },
  {
    "category": "People & Body",
    "emoji": "🚣‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🚣‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🏊"
  },
  {
    "category": "People & Body",
    "emoji": "🏊‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🏊‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "⛹️"
  },
  {
    "category": "People & Body",
    "emoji": "⛹️‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "⛹️‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🏋️"
  },
  {
    "category": "People & Body",
    "emoji": "🏋️‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🏋️‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🚴"
  },
  {
    "category": "People & Body",
    "emoji": "🚴‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🚴‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🚵"
  },
  {
    "category": "People & Body",
    "emoji": "🚵‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🚵‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🤸"
  },
  {
    "category": "People & Body",
    "emoji": "🤸‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🤸‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🤼"
  },
  {
    "category": "People & Body",
    "emoji": "🤼‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🤼‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🤽"
  },
  {
    "category": "People & Body",
    "emoji": "🤽‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🤽‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🤾"
  },
  {
    "category": "People & Body",
    "emoji": "🤾‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🤾‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🤹"
  },
  {
    "category": "People & Body",
    "emoji": "🤹‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🤹‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🧘"
  },
  {
    "category": "People & Body",
    "emoji": "🧘‍♂️"
  },
  {
    "category": "People & Body",
    "emoji": "🧘‍♀️"
  },
  {
    "category": "People & Body",
    "emoji": "🛀"
  },
  {
    "category": "People & Body",
    "emoji": "🛌"
  },
  {
    "category": "People & Body",
    "emoji": "🧑‍🤝‍🧑"
  },
  {
    "category": "People & Body",
    "emoji": "👭"
  },
  {
    "category": "People & Body",
    "emoji": "👫"
  },
  {
    "category": "People & Body",
    "emoji": "👬"
  },
  {
    "category": "People & Body",
    "emoji": "💏"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍❤️‍💋‍👨"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍❤️‍💋‍👨"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍❤️‍💋‍👩"
  },
  {
    "category": "People & Body",
    "emoji": "💑"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍❤️‍👨"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍❤️‍👨"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍❤️‍👩"
  },
  {
    "category": "People & Body",
    "emoji": "👪"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👩‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👩‍👧"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👩‍👧‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👩‍👦‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👩‍👧‍👧"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👨‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👨‍👧"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👨‍👧‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👨‍👦‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👨‍👧‍👧"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍👩‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍👩‍👧"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍👩‍👧‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍👩‍👦‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍👩‍👧‍👧"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👦‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👧"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👧‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👨‍👧‍👧"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍👦‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍👧"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍👧‍👦"
  },
  {
    "category": "People & Body",
    "emoji": "👩‍👧‍👧"
  },
  {
    "category": "People & Body",
    "emoji": "🗣️"
  },
  {
    "category": "People & Body",
    "emoji": "👤"
  },
  {
    "category": "People & Body",
    "emoji": "👥"
  },
  {
    "category": "People & Body",
    "emoji": "🫂"
  },
  {
    "category": "People & Body",
    "emoji": "👣"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐵"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐒"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦍"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦧"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐶"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐕"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦮"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐕‍🦺"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐩"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐺"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦊"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦝"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐱"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐈"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐈‍⬛"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦁"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐯"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐅"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐆"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐴"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🫎"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🫏"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐎"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦄"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦓"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦌"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦬"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐮"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐂"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐃"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐄"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐷"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐖"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐗"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐽"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐏"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐑"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐐"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐪"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐫"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦙"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦒"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐘"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦣"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦏"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦛"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐭"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐁"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐀"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐹"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐰"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐇"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐿️"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦫"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦔"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦇"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐻"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐻‍❄️"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐨"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐼"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦥"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦦"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦨"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦘"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦡"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐾"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦃"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐔"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐓"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐣"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐤"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐥"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐦"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐧"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🕊️"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦅"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦆"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦢"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦉"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦤"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🪶"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦩"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦚"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦜"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🪽"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐦‍⬛"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🪿"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐸"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐊"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐢"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦎"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐍"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐲"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐉"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦕"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦖"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐳"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐋"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐬"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦭"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐟"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐠"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐡"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦈"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐙"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐚"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🪸"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🪼"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐌"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦋"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐛"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐜"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐝"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🪲"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🐞"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦗"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🪳"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🕷️"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🕸️"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦂"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦟"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🪰"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🪱"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🦠"
  },
  {
    "category": "Animals & Nature",
    "emoji": "💐"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🌸"
  },
  {
    "category": "Animals & Nature",
    "emoji": "💮"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🪷"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🏵️"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🌹"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🥀"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🌺"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🌻"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🌼"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🌷"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🪻"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🌱"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🪴"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🌲"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🌳"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🌴"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🌵"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🌾"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🌿"
  },
  {
    "category": "Animals & Nature",
    "emoji": "☘️"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🍀"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🍁"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🍂"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🍃"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🪹"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🪺"
  },
  {
    "category": "Animals & Nature",
    "emoji": "🍄"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍇"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍈"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍉"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍊"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍋"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍌"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍍"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥭"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍎"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍏"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍐"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍑"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍒"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍓"
  },
  {
    "category": "Food & Drink",
    "emoji": "🫐"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥝"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍅"
  },
  {
    "category": "Food & Drink",
    "emoji": "🫒"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥥"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥑"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍆"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥔"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥕"
  },
  {
    "category": "Food & Drink",
    "emoji": "🌽"
  },
  {
    "category": "Food & Drink",
    "emoji": "🌶️"
  },
  {
    "category": "Food & Drink",
    "emoji": "🫑"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥒"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥬"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥦"
  },
  {
    "category": "Food & Drink",
    "emoji": "🧄"
  },
  {
    "category": "Food & Drink",
    "emoji": "🧅"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥜"
  },
  {
    "category": "Food & Drink",
    "emoji": "🫘"
  },
  {
    "category": "Food & Drink",
    "emoji": "🌰"
  },
  {
    "category": "Food & Drink",
    "emoji": "🫚"
  },
  {
    "category": "Food & Drink",
    "emoji": "🫛"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍞"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥐"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥖"
  },
  {
    "category": "Food & Drink",
    "emoji": "🫓"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥨"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥯"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥞"
  },
  {
    "category": "Food & Drink",
    "emoji": "🧇"
  },
  {
    "category": "Food & Drink",
    "emoji": "🧀"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍖"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍗"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥩"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥓"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍔"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍟"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍕"
  },
  {
    "category": "Food & Drink",
    "emoji": "🌭"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥪"
  },
  {
    "category": "Food & Drink",
    "emoji": "🌮"
  },
  {
    "category": "Food & Drink",
    "emoji": "🌯"
  },
  {
    "category": "Food & Drink",
    "emoji": "🫔"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥙"
  },
  {
    "category": "Food & Drink",
    "emoji": "🧆"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥚"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍳"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥘"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍲"
  },
  {
    "category": "Food & Drink",
    "emoji": "🫕"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥣"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥗"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍿"
  },
  {
    "category": "Food & Drink",
    "emoji": "🧈"
  },
  {
    "category": "Food & Drink",
    "emoji": "🧂"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥫"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍱"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍘"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍙"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍚"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍛"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍜"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍝"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍠"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍢"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍣"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍤"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍥"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥮"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍡"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥟"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥠"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥡"
  },
  {
    "category": "Food & Drink",
    "emoji": "🦀"
  },
  {
    "category": "Food & Drink",
    "emoji": "🦞"
  },
  {
    "category": "Food & Drink",
    "emoji": "🦐"
  },
  {
    "category": "Food & Drink",
    "emoji": "🦑"
  },
  {
    "category": "Food & Drink",
    "emoji": "🦪"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍦"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍧"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍨"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍩"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍪"
  },
  {
    "category": "Food & Drink",
    "emoji": "🎂"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍰"
  },
  {
    "category": "Food & Drink",
    "emoji": "🧁"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥧"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍫"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍬"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍭"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍮"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍯"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍼"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥛"
  },
  {
    "category": "Food & Drink",
    "emoji": "☕"
  },
  {
    "category": "Food & Drink",
    "emoji": "🫖"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍵"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍶"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍾"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍷"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍸"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍹"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍺"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍻"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥂"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥃"
  },
  {
    "category": "Food & Drink",
    "emoji": "🫗"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥤"
  },
  {
    "category": "Food & Drink",
    "emoji": "🧋"
  },
  {
    "category": "Food & Drink",
    "emoji": "🧃"
  },
  {
    "category": "Food & Drink",
    "emoji": "🧉"
  },
  {
    "category": "Food & Drink",
    "emoji": "🧊"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥢"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍽️"
  },
  {
    "category": "Food & Drink",
    "emoji": "🍴"
  },
  {
    "category": "Food & Drink",
    "emoji": "🥄"
  },
  {
    "category": "Food & Drink",
    "emoji": "🔪"
  },
  {
    "category": "Food & Drink",
    "emoji": "🫙"
  },
  {
    "category": "Food & Drink",
    "emoji": "🏺"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌍"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌎"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌏"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌐"
  },
  {
    "category": "Travel & Places",
    "emoji": "🗺️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🗾"
  },
  {
    "category": "Travel & Places",
    "emoji": "🧭"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏔️"
  },
  {
    "category": "Travel & Places",
    "emoji": "⛰️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌋"
  },
  {
    "category": "Travel & Places",
    "emoji": "🗻"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏕️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏖️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏜️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏝️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏞️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏟️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏛️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏗️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🧱"
  },
  {
    "category": "Travel & Places",
    "emoji": "🪨"
  },
  {
    "category": "Travel & Places",
    "emoji": "🪵"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛖"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏘️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏚️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏠"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏡"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏢"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏣"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏤"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏥"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏦"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏨"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏩"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏪"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏫"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏬"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏭"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏯"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏰"
  },
  {
    "category": "Travel & Places",
    "emoji": "💒"
  },
  {
    "category": "Travel & Places",
    "emoji": "🗼"
  },
  {
    "category": "Travel & Places",
    "emoji": "🗽"
  },
  {
    "category": "Travel & Places",
    "emoji": "⛪"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕌"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛕"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕍"
  },
  {
    "category": "Travel & Places",
    "emoji": "⛩️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕋"
  },
  {
    "category": "Travel & Places",
    "emoji": "⛲"
  },
  {
    "category": "Travel & Places",
    "emoji": "⛺"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌁"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌃"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏙️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌄"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌅"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌆"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌇"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌉"
  },
  {
    "category": "Travel & Places",
    "emoji": "♨️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🎠"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛝"
  },
  {
    "category": "Travel & Places",
    "emoji": "🎡"
  },
  {
    "category": "Travel & Places",
    "emoji": "🎢"
  },
  {
    "category": "Travel & Places",
    "emoji": "💈"
  },
  {
    "category": "Travel & Places",
    "emoji": "🎪"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚂"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚃"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚄"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚅"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚆"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚇"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚈"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚉"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚊"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚝"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚞"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚋"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚌"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚍"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚎"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚐"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚑"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚒"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚓"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚔"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚕"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚖"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚗"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚘"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚙"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛻"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚚"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚛"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚜"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏎️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🏍️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛵"
  },
  {
    "category": "Travel & Places",
    "emoji": "🦽"
  },
  {
    "category": "Travel & Places",
    "emoji": "🦼"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛺"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚲"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛴"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛹"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛼"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚏"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛣️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛤️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛢️"
  },
  {
    "category": "Travel & Places",
    "emoji": "⛽"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛞"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚨"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚥"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚦"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛑"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚧"
  },
  {
    "category": "Travel & Places",
    "emoji": "⚓"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛟"
  },
  {
    "category": "Travel & Places",
    "emoji": "⛵"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛶"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚤"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛳️"
  },
  {
    "category": "Travel & Places",
    "emoji": "⛴️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛥️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚢"
  },
  {
    "category": "Travel & Places",
    "emoji": "✈️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛩️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛫"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛬"
  },
  {
    "category": "Travel & Places",
    "emoji": "🪂"
  },
  {
    "category": "Travel & Places",
    "emoji": "💺"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚁"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚟"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚠"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚡"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛰️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🚀"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛸"
  },
  {
    "category": "Travel & Places",
    "emoji": "🛎️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🧳"
  },
  {
    "category": "Travel & Places",
    "emoji": "⌛"
  },
  {
    "category": "Travel & Places",
    "emoji": "⏳"
  },
  {
    "category": "Travel & Places",
    "emoji": "⌚"
  },
  {
    "category": "Travel & Places",
    "emoji": "⏰"
  },
  {
    "category": "Travel & Places",
    "emoji": "⏱️"
  },
  {
    "category": "Travel & Places",
    "emoji": "⏲️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕰️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕛"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕧"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕐"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕜"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕑"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕝"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕒"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕞"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕓"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕟"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕔"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕠"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕕"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕡"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕖"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕢"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕗"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕣"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕘"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕤"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕙"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕥"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕚"
  },
  {
    "category": "Travel & Places",
    "emoji": "🕦"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌑"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌒"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌓"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌔"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌕"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌖"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌗"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌘"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌙"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌚"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌛"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌜"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌡️"
  },
  {
    "category": "Travel & Places",
    "emoji": "☀️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌝"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌞"
  },
  {
    "category": "Travel & Places",
    "emoji": "🪐"
  },
  {
    "category": "Travel & Places",
    "emoji": "⭐"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌟"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌠"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌌"
  },
  {
    "category": "Travel & Places",
    "emoji": "☁️"
  },
  {
    "category": "Travel & Places",
    "emoji": "⛅"
  },
  {
    "category": "Travel & Places",
    "emoji": "⛈️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌤️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌥️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌦️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌧️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌨️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌩️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌪️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌫️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌬️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌀"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌈"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌂"
  },
  {
    "category": "Travel & Places",
    "emoji": "☂️"
  },
  {
    "category": "Travel & Places",
    "emoji": "☔"
  },
  {
    "category": "Travel & Places",
    "emoji": "⛱️"
  },
  {
    "category": "Travel & Places",
    "emoji": "⚡"
  },
  {
    "category": "Travel & Places",
    "emoji": "❄️"
  },
  {
    "category": "Travel & Places",
    "emoji": "☃️"
  },
  {
    "category": "Travel & Places",
    "emoji": "⛄"
  },
  {
    "category": "Travel & Places",
    "emoji": "☄️"
  },
  {
    "category": "Travel & Places",
    "emoji": "🔥"
  },
  {
    "category": "Travel & Places",
    "emoji": "💧"
  },
  {
    "category": "Travel & Places",
    "emoji": "🌊"
  },
  {
    "category": "Activities",
    "emoji": "🎃"
  },
  {
    "category": "Activities",
    "emoji": "🎄"
  },
  {
    "category": "Activities",
    "emoji": "🎆"
  },
  {
    "category": "Activities",
    "emoji": "🎇"
  },
  {
    "category": "Activities",
    "emoji": "🧨"
  },
  {
    "category": "Activities",
    "emoji": "✨"
  },
  {
    "category": "Activities",
    "emoji": "🎈"
  },
  {
    "category": "Activities",
    "emoji": "🎉"
  },
  {
    "category": "Activities",
    "emoji": "🎊"
  },
  {
    "category": "Activities",
    "emoji": "🎋"
  },
  {
    "category": "Activities",
    "emoji": "🎍"
  },
  {
    "category": "Activities",
    "emoji": "🎎"
  },
  {
    "category": "Activities",
    "emoji": "🎏"
  },
  {
    "category": "Activities",
    "emoji": "🎐"
  },
  {
    "category": "Activities",
    "emoji": "🎑"
  },
  {
    "category": "Activities",
    "emoji": "🧧"
  },
  {
    "category": "Activities",
    "emoji": "🎀"
  },
  {
    "category": "Activities",
    "emoji": "🎁"
  },
  {
    "category": "Activities",
    "emoji": "🎗️"
  },
  {
    "category": "Activities",
    "emoji": "🎟️"
  },
  {
    "category": "Activities",
    "emoji": "🎫"
  },
  {
    "category": "Activities",
    "emoji": "🎖️"
  },
  {
    "category": "Activities",
    "emoji": "🏆"
  },
  {
    "category": "Activities",
    "emoji": "🏅"
  },
  {
    "category": "Activities",
    "emoji": "🥇"
  },
  {
    "category": "Activities",
    "emoji": "🥈"
  },
  {
    "category": "Activities",
    "emoji": "🥉"
  },
  {
    "category": "Activities",
    "emoji": "⚽"
  },
  {
    "category": "Activities",
    "emoji": "⚾"
  },
  {
    "category": "Activities",
    "emoji": "🥎"
  },
  {
    "category": "Activities",
    "emoji": "🏀"
  },
  {
    "category": "Activities",
    "emoji": "🏐"
  },
  {
    "category": "Activities",
    "emoji": "🏈"
  },
  {
    "category": "Activities",
    "emoji": "🏉"
  },
  {
    "category": "Activities",
    "emoji": "🎾"
  },
  {
    "category": "Activities",
    "emoji": "🥏"
  },
  {
    "category": "Activities",
    "emoji": "🎳"
  },
  {
    "category": "Activities",
    "emoji": "🏏"
  },
  {
    "category": "Activities",
    "emoji": "🏑"
  },
  {
    "category": "Activities",
    "emoji": "🏒"
  },
  {
    "category": "Activities",
    "emoji": "🥍"
  },
  {
    "category": "Activities",
    "emoji": "🏓"
  },
  {
    "category": "Activities",
    "emoji": "🏸"
  },
  {
    "category": "Activities",
    "emoji": "🥊"
  },
  {
    "category": "Activities",
    "emoji": "🥋"
  },
  {
    "category": "Activities",
    "emoji": "🥅"
  },
  {
    "category": "Activities",
    "emoji": "⛳"
  },
  {
    "category": "Activities",
    "emoji": "⛸️"
  },
  {
    "category": "Activities",
    "emoji": "🎣"
  },
  {
    "category": "Activities",
    "emoji": "🤿"
  },
  {
    "category": "Activities",
    "emoji": "🎽"
  },
  {
    "category": "Activities",
    "emoji": "🎿"
  },
  {
    "category": "Activities",
    "emoji": "🛷"
  },
  {
    "category": "Activities",
    "emoji": "🥌"
  },
  {
    "category": "Activities",
    "emoji": "🎯"
  },
  {
    "category": "Activities",
    "emoji": "🪀"
  },
  {
    "category": "Activities",
    "emoji": "🪁"
  },
  {
    "category": "Activities",
    "emoji": "🔫"
  },
  {
    "category": "Activities",
    "emoji": "🎱"
  },
  {
    "category": "Activities",
    "emoji": "🔮"
  },
  {
    "category": "Activities",
    "emoji": "🪄"
  },
  {
    "category": "Activities",
    "emoji": "🎮"
  },
  {
    "category": "Activities",
    "emoji": "🕹️"
  },
  {
    "category": "Activities",
    "emoji": "🎰"
  },
  {
    "category": "Activities",
    "emoji": "🎲"
  },
  {
    "category": "Activities",
    "emoji": "🧩"
  },
  {
    "category": "Activities",
    "emoji": "🧸"
  },
  {
    "category": "Activities",
    "emoji": "🪅"
  },
  {
    "category": "Activities",
    "emoji": "🪩"
  },
  {
    "category": "Activities",
    "emoji": "🪆"
  },
  {
    "category": "Activities",
    "emoji": "♠️"
  },
  {
    "category": "Activities",
    "emoji": "♥️"
  },
  {
    "category": "Activities",
    "emoji": "♦️"
  },
  {
    "category": "Activities",
    "emoji": "♣️"
  },
  {
    "category": "Activities",
    "emoji": "♟️"
  },
  {
    "category": "Activities",
    "emoji": "🃏"
  },
  {
    "category": "Activities",
    "emoji": "🀄"
  },
  {
    "category": "Activities",
    "emoji": "🎴"
  },
  {
    "category": "Activities",
    "emoji": "🎭"
  },
  {
    "category": "Activities",
    "emoji": "🖼️"
  },
  {
    "category": "Activities",
    "emoji": "🎨"
  },
  {
    "category": "Activities",
    "emoji": "🧵"
  },
  {
    "category": "Activities",
    "emoji": "🪡"
  },
  {
    "category": "Activities",
    "emoji": "🧶"
  },
  {
    "category": "Activities",
    "emoji": "🪢"
  },
  {
    "category": "Objects",
    "emoji": "👓"
  },
  {
    "category": "Objects",
    "emoji": "🕶️"
  },
  {
    "category": "Objects",
    "emoji": "🥽"
  },
  {
    "category": "Objects",
    "emoji": "🥼"
  },
  {
    "category": "Objects",
    "emoji": "🦺"
  },
  {
    "category": "Objects",
    "emoji": "👔"
  },
  {
    "category": "Objects",
    "emoji": "👕"
  },
  {
    "category": "Objects",
    "emoji": "👖"
  },
  {
    "category": "Objects",
    "emoji": "🧣"
  },
  {
    "category": "Objects",
    "emoji": "🧤"
  },
  {
    "category": "Objects",
    "emoji": "🧥"
  },
  {
    "category": "Objects",
    "emoji": "🧦"
  },
  {
    "category": "Objects",
    "emoji": "👗"
  },
  {
    "category": "Objects",
    "emoji": "👘"
  },
  {
    "category": "Objects",
    "emoji": "🥻"
  },
  {
    "category": "Objects",
    "emoji": "🩱"
  },
  {
    "category": "Objects",
    "emoji": "🩲"
  },
  {
    "category": "Objects",
    "emoji": "🩳"
  },
  {
    "category": "Objects",
    "emoji": "👙"
  },
  {
    "category": "Objects",
    "emoji": "👚"
  },
  {
    "category": "Objects",
    "emoji": "🪭"
  },
  {
    "category": "Objects",
    "emoji": "👛"
  },
  {
    "category": "Objects",
    "emoji": "👜"
  },
  {
    "category": "Objects",
    "emoji": "👝"
  },
  {
    "category": "Objects",
    "emoji": "🛍️"
  },
  {
    "category": "Objects",
    "emoji": "🎒"
  },
  {
    "category": "Objects",
    "emoji": "🩴"
  },
  {
    "category": "Objects",
    "emoji": "👞"
  },
  {
    "category": "Objects",
    "emoji": "👟"
  },
  {
    "category": "Objects",
    "emoji": "🥾"
  },
  {
    "category": "Objects",
    "emoji": "🥿"
  },
  {
    "category": "Objects",
    "emoji": "👠"
  },
  {
    "category": "Objects",
    "emoji": "👡"
  },
  {
    "category": "Objects",
    "emoji": "🩰"
  },
  {
    "category": "Objects",
    "emoji": "👢"
  },
  {
    "category": "Objects",
    "emoji": "🪮"
  },
  {
    "category": "Objects",
    "emoji": "👑"
  },
  {
    "category": "Objects",
    "emoji": "👒"
  },
  {
    "category": "Objects",
    "emoji": "🎩"
  },
  {
    "category": "Objects",
    "emoji": "🎓"
  },
  {
    "category": "Objects",
    "emoji": "🧢"
  },
  {
    "category": "Objects",
    "emoji": "🪖"
  },
  {
    "category": "Objects",
    "emoji": "⛑️"
  },
  {
    "category": "Objects",
    "emoji": "📿"
  },
  {
    "category": "Objects",
    "emoji": "💄"
  },
  {
    "category": "Objects",
    "emoji": "💍"
  },
  {
    "category": "Objects",
    "emoji": "💎"
  },
  {
    "category": "Objects",
    "emoji": "🔇"
  },
  {
    "category": "Objects",
    "emoji": "🔈"
  },
  {
    "category": "Objects",
    "emoji": "🔉"
  },
  {
    "category": "Objects",
    "emoji": "🔊"
  },
  {
    "category": "Objects",
    "emoji": "📢"
  },
  {
    "category": "Objects",
    "emoji": "📣"
  },
  {
    "category": "Objects",
    "emoji": "📯"
  },
  {
    "category": "Objects",
    "emoji": "🔔"
  },
  {
    "category": "Objects",
    "emoji": "🔕"
  },
  {
    "category": "Objects",
    "emoji": "🎼"
  },
  {
    "category": "Objects",
    "emoji": "🎵"
  },
  {
    "category": "Objects",
    "emoji": "🎶"
  },
  {
    "category": "Objects",
    "emoji": "🎙️"
  },
  {
    "category": "Objects",
    "emoji": "🎚️"
  },
  {
    "category": "Objects",
    "emoji": "🎛️"
  },
  {
    "category": "Objects",
    "emoji": "🎤"
  },
  {
    "category": "Objects",
    "emoji": "🎧"
  },
  {
    "category": "Objects",
    "emoji": "📻"
  },
  {
    "category": "Objects",
    "emoji": "🎷"
  },
  {
    "category": "Objects",
    "emoji": "🪗"
  },
  {
    "category": "Objects",
    "emoji": "🎸"
  },
  {
    "category": "Objects",
    "emoji": "🎹"
  },
  {
    "category": "Objects",
    "emoji": "🎺"
  },
  {
    "category": "Objects",
    "emoji": "🎻"
  },
  {
    "category": "Objects",
    "emoji": "🪕"
  },
  {
    "category": "Objects",
    "emoji": "🥁"
  },
  {
    "category": "Objects",
    "emoji": "🪘"
  },
  {
    "category": "Objects",
    "emoji": "🪇"
  },
  {
    "category": "Objects",
    "emoji": "🪈"
  },
  {
    "category": "Objects",
    "emoji": "📱"
  },
  {
    "category": "Objects",
    "emoji": "📲"
  },
  {
    "category": "Objects",
    "emoji": "☎️"
  },
  {
    "category": "Objects",
    "emoji": "📞"
  },
  {
    "category": "Objects",
    "emoji": "📟"
  },
  {
    "category": "Objects",
    "emoji": "📠"
  },
  {
    "category": "Objects",
    "emoji": "🔋"
  },
  {
    "category": "Objects",
    "emoji": "🪫"
  },
  {
    "category": "Objects",
    "emoji": "🔌"
  },
  {
    "category": "Objects",
    "emoji": "💻"
  },
  {
    "category": "Objects",
    "emoji": "🖥️"
  },
  {
    "category": "Objects",
    "emoji": "🖨️"
  },
  {
    "category": "Objects",
    "emoji": "⌨️"
  },
  {
    "category": "Objects",
    "emoji": "🖱️"
  },
  {
    "category": "Objects",
    "emoji": "🖲️"
  },
  {
    "category": "Objects",
    "emoji": "💽"
  },
  {
    "category": "Objects",
    "emoji": "💾"
  },
  {
    "category": "Objects",
    "emoji": "💿"
  },
  {
    "category": "Objects",
    "emoji": "📀"
  },
  {
    "category": "Objects",
    "emoji": "🧮"
  },
  {
    "category": "Objects",
    "emoji": "🎥"
  },
  {
    "category": "Objects",
    "emoji": "🎞️"
  },
  {
    "category": "Objects",
    "emoji": "📽️"
  },
  {
    "category": "Objects",
    "emoji": "🎬"
  },
  {
    "category": "Objects",
    "emoji": "📺"
  },
  {
    "category": "Objects",
    "emoji": "📷"
  },
  {
    "category": "Objects",
    "emoji": "📸"
  },
  {
    "category": "Objects",
    "emoji": "📹"
  },
  {
    "category": "Objects",
    "emoji": "📼"
  },
  {
    "category": "Objects",
    "emoji": "🔍"
  },
  {
    "category": "Objects",
    "emoji": "🔎"
  },
  {
    "category": "Objects",
    "emoji": "🕯️"
  },
  {
    "category": "Objects",
    "emoji": "💡"
  },
  {
    "category": "Objects",
    "emoji": "🔦"
  },
  {
    "category": "Objects",
    "emoji": "🏮"
  },
  {
    "category": "Objects",
    "emoji": "🪔"
  },
  {
    "category": "Objects",
    "emoji": "📔"
  },
  {
    "category": "Objects",
    "emoji": "📕"
  },
  {
    "category": "Objects",
    "emoji": "📖"
  },
  {
    "category": "Objects",
    "emoji": "📗"
  },
  {
    "category": "Objects",
    "emoji": "📘"
  },
  {
    "category": "Objects",
    "emoji": "📙"
  },
  {
    "category": "Objects",
    "emoji": "📚"
  },
  {
    "category": "Objects",
    "emoji": "📓"
  },
  {
    "category": "Objects",
    "emoji": "📒"
  },
  {
    "category": "Objects",
    "emoji": "📃"
  },
  {
    "category": "Objects",
    "emoji": "📜"
  },
  {
    "category": "Objects",
    "emoji": "📄"
  },
  {
    "category": "Objects",
    "emoji": "📰"
  },
  {
    "category": "Objects",
    "emoji": "🗞️"
  },
  {
    "category": "Objects",
    "emoji": "📑"
  },
  {
    "category": "Objects",
    "emoji": "🔖"
  },
  {
    "category": "Objects",
    "emoji": "🏷️"
  },
  {
    "category": "Objects",
    "emoji": "💰"
  },
  {
    "category": "Objects",
    "emoji": "🪙"
  },
  {
    "category": "Objects",
    "emoji": "💴"
  },
  {
    "category": "Objects",
    "emoji": "💵"
  },
  {
    "category": "Objects",
    "emoji": "💶"
  },
  {
    "category": "Objects",
    "emoji": "💷"
  },
  {
    "category": "Objects",
    "emoji": "💸"
  },
  {
    "category": "Objects",
    "emoji": "💳"
  },
  {
    "category": "Objects",
    "emoji": "🧾"
  },
  {
    "category": "Objects",
    "emoji": "💹"
  },
  {
    "category": "Objects",
    "emoji": "✉️"
  },
  {
    "category": "Objects",
    "emoji": "📧"
  },
  {
    "category": "Objects",
    "emoji": "📨"
  },
  {
    "category": "Objects",
    "emoji": "📩"
  },
  {
    "category": "Objects",
    "emoji": "📤"
  },
  {
    "category": "Objects",
    "emoji": "📥"
  },
  {
    "category": "Objects",
    "emoji": "📦"
  },
  {
    "category": "Objects",
    "emoji": "📫"
  },
  {
    "category": "Objects",
    "emoji": "📪"
  },
  {
    "category": "Objects",
    "emoji": "📬"
  },
  {
    "category": "Objects",
    "emoji": "📭"
  },
  {
    "category": "Objects",
    "emoji": "📮"
  },
  {
    "category": "Objects",
    "emoji": "🗳️"
  },
  {
    "category": "Objects",
    "emoji": "✏️"
  },
  {
    "category": "Objects",
    "emoji": "✒️"
  },
  {
    "category": "Objects",
    "emoji": "🖋️"
  },
  {
    "category": "Objects",
    "emoji": "🖊️"
  },
  {
    "category": "Objects",
    "emoji": "🖌️"
  },
  {
    "category": "Objects",
    "emoji": "🖍️"
  },
  {
    "category": "Objects",
    "emoji": "📝"
  },
  {
    "category": "Objects",
    "emoji": "💼"
  },
  {
    "category": "Objects",
    "emoji": "📁"
  },
  {
    "category": "Objects",
    "emoji": "📂"
  },
  {
    "category": "Objects",
    "emoji": "🗂️"
  },
  {
    "category": "Objects",
    "emoji": "📅"
  },
  {
    "category": "Objects",
    "emoji": "📆"
  },
  {
    "category": "Objects",
    "emoji": "🗒️"
  },
  {
    "category": "Objects",
    "emoji": "🗓️"
  },
  {
    "category": "Objects",
    "emoji": "📇"
  },
  {
    "category": "Objects",
    "emoji": "📈"
  },
  {
    "category": "Objects",
    "emoji": "📉"
  },
  {
    "category": "Objects",
    "emoji": "📊"
  },
  {
    "category": "Objects",
    "emoji": "📋"
  },
  {
    "category": "Objects",
    "emoji": "📌"
  },
  {
    "category": "Objects",
    "emoji": "📍"
  },
  {
    "category": "Objects",
    "emoji": "📎"
  },
  {
    "category": "Objects",
    "emoji": "🖇️"
  },
  {
    "category": "Objects",
    "emoji": "📏"
  },
  {
    "category": "Objects",
    "emoji": "📐"
  },
  {
    "category": "Objects",
    "emoji": "✂️"
  },
  {
    "category": "Objects",
    "emoji": "🗃️"
  },
  {
    "category": "Objects",
    "emoji": "🗄️"
  },
  {
    "category": "Objects",
    "emoji": "🗑️"
  },
  {
    "category": "Objects",
    "emoji": "🔒"
  },
  {
    "category": "Objects",
    "emoji": "🔓"
  },
  {
    "category": "Objects",
    "emoji": "🔏"
  },
  {
    "category": "Objects",
    "emoji": "🔐"
  },
  {
    "category": "Objects",
    "emoji": "🔑"
  },
  {
    "category": "Objects",
    "emoji": "🗝️"
  },
  {
    "category": "Objects",
    "emoji": "🔨"
  },
  {
    "category": "Objects",
    "emoji": "🪓"
  },
  {
    "category": "Objects",
    "emoji": "⛏️"
  },
  {
    "category": "Objects",
    "emoji": "⚒️"
  },
  {
    "category": "Objects",
    "emoji": "🛠️"
  },
  {
    "category": "Objects",
    "emoji": "🗡️"
  },
  {
    "category": "Objects",
    "emoji": "⚔️"
  },
  {
    "category": "Objects",
    "emoji": "💣"
  },
  {
    "category": "Objects",
    "emoji": "🪃"
  },
  {
    "category": "Objects",
    "emoji": "🏹"
  },
  {
    "category": "Objects",
    "emoji": "🛡️"
  },
  {
    "category": "Objects",
    "emoji": "🪚"
  },
  {
    "category": "Objects",
    "emoji": "🔧"
  },
  {
    "category": "Objects",
    "emoji": "🪛"
  },
  {
    "category": "Objects",
    "emoji": "🔩"
  },
  {
    "category": "Objects",
    "emoji": "⚙️"
  },
  {
    "category": "Objects",
    "emoji": "🗜️"
  },
  {
    "category": "Objects",
    "emoji": "⚖️"
  },
  {
    "category": "Objects",
    "emoji": "🦯"
  },
  {
    "category": "Objects",
    "emoji": "🔗"
  },
  {
    "category": "Objects",
    "emoji": "⛓️"
  },
  {
    "category": "Objects",
    "emoji": "🪝"
  },
  {
    "category": "Objects",
    "emoji": "🧰"
  },
  {
    "category": "Objects",
    "emoji": "🧲"
  },
  {
    "category": "Objects",
    "emoji": "🪜"
  },
  {
    "category": "Objects",
    "emoji": "⚗️"
  },
  {
    "category": "Objects",
    "emoji": "🧪"
  },
  {
    "category": "Objects",
    "emoji": "🧫"
  },
  {
    "category": "Objects",
    "emoji": "🧬"
  },
  {
    "category": "Objects",
    "emoji": "🔬"
  },
  {
    "category": "Objects",
    "emoji": "🔭"
  },
  {
    "category": "Objects",
    "emoji": "📡"
  },
  {
    "category": "Objects",
    "emoji": "💉"
  },
  {
    "category": "Objects",
    "emoji": "🩸"
  },
  {
    "category": "Objects",
    "emoji": "💊"
  },
  {
    "category": "Objects",
    "emoji": "🩹"
  },
  {
    "category": "Objects",
    "emoji": "🩼"
  },
  {
    "category": "Objects",
    "emoji": "🩺"
  },
  {
    "category": "Objects",
    "emoji": "🩻"
  },
  {
    "category": "Objects",
    "emoji": "🚪"
  },
  {
    "category": "Objects",
    "emoji": "🛗"
  },
  {
    "category": "Objects",
    "emoji": "🪞"
  },
  {
    "category": "Objects",
    "emoji": "🪟"
  },
  {
    "category": "Objects",
    "emoji": "🛏️"
  },
  {
    "category": "Objects",
    "emoji": "🛋️"
  },
  {
    "category": "Objects",
    "emoji": "🪑"
  },
  {
    "category": "Objects",
    "emoji": "🚽"
  },
  {
    "category": "Objects",
    "emoji": "🪠"
  },
  {
    "category": "Objects",
    "emoji": "🚿"
  },
  {
    "category": "Objects",
    "emoji": "🛁"
  },
  {
    "category": "Objects",
    "emoji": "🪤"
  },
  {
    "category": "Objects",
    "emoji": "🪒"
  },
  {
    "category": "Objects",
    "emoji": "🧴"
  },
  {
    "category": "Objects",
    "emoji": "🧷"
  },
  {
    "category": "Objects",
    "emoji": "🧹"
  },
  {
    "category": "Objects",
    "emoji": "🧺"
  },
  {
    "category": "Objects",
    "emoji": "🧻"
  },
  {
    "category": "Objects",
    "emoji": "🪣"
  },
  {
    "category": "Objects",
    "emoji": "🧼"
  },
  {
    "category": "Objects",
    "emoji": "🫧"
  },
  {
    "category": "Objects",
    "emoji": "🪥"
  },
  {
    "category": "Objects",
    "emoji": "🧽"
  },
  {
    "category": "Objects",
    "emoji": "🧯"
  },
  {
    "category": "Objects",
    "emoji": "🛒"
  },
  {
    "category": "Objects",
    "emoji": "🚬"
  },
  {
    "category": "Objects",
    "emoji": "⚰️"
  },
  {
    "category": "Objects",
    "emoji": "🪦"
  },
  {
    "category": "Objects",
    "emoji": "⚱️"
  },
  {
    "category": "Objects",
    "emoji": "🧿"
  },
  {
    "category": "Objects",
    "emoji": "🪬"
  },
  {
    "category": "Objects",
    "emoji": "🗿"
  },
  {
    "category": "Objects",
    "emoji": "🪧"
  },
  {
    "category": "Objects",
    "emoji": "🪪"
  },
  {
    "category": "Symbols",
    "emoji": "🏧"
  },
  {
    "category": "Symbols",
    "emoji": "🚮"
  },
  {
    "category": "Symbols",
    "emoji": "🚰"
  },
  {
    "category": "Symbols",
    "emoji": "♿"
  },
  {
    "category": "Symbols",
    "emoji": "🚹"
  },
  {
    "category": "Symbols",
    "emoji": "🚺"
  },
  {
    "category": "Symbols",
    "emoji": "🚻"
  },
  {
    "category": "Symbols",
    "emoji": "🚼"
  },
  {
    "category": "Symbols",
    "emoji": "🚾"
  },
  {
    "category": "Symbols",
    "emoji": "🛂"
  },
  {
    "category": "Symbols",
    "emoji": "🛃"
  },
  {
    "category": "Symbols",
    "emoji": "🛄"
  },
  {
    "category": "Symbols",
    "emoji": "🛅"
  },
  {
    "category": "Symbols",
    "emoji": "⚠️"
  },
  {
    "category": "Symbols",
    "emoji": "🚸"
  },
  {
    "category": "Symbols",
    "emoji": "⛔"
  },
  {
    "category": "Symbols",
    "emoji": "🚫"
  },
  {
    "category": "Symbols",
    "emoji": "🚳"
  },
  {
    "category": "Symbols",
    "emoji": "🚭"
  },
  {
    "category": "Symbols",
    "emoji": "🚯"
  },
  {
    "category": "Symbols",
    "emoji": "🚱"
  },
  {
    "category": "Symbols",
    "emoji": "🚷"
  },
  {
    "category": "Symbols",
    "emoji": "📵"
  },
  {
    "category": "Symbols",
    "emoji": "🔞"
  },
  {
    "category": "Symbols",
    "emoji": "☢️"
  },
  {
    "category": "Symbols",
    "emoji": "☣️"
  },
  {
    "category": "Symbols",
    "emoji": "⬆️"
  },
  {
    "category": "Symbols",
    "emoji": "↗️"
  },
  {
    "category": "Symbols",
    "emoji": "➡️"
  },
  {
    "category": "Symbols",
    "emoji": "↘️"
  },
  {
    "category": "Symbols",
    "emoji": "⬇️"
  },
  {
    "category": "Symbols",
    "emoji": "↙️"
  },
  {
    "category": "Symbols",
    "emoji": "⬅️"
  },
  {
    "category": "Symbols",
    "emoji": "↖️"
  },
  {
    "category": "Symbols",
    "emoji": "↕️"
  },
  {
    "category": "Symbols",
    "emoji": "↔️"
  },
  {
    "category": "Symbols",
    "emoji": "↩️"
  },
  {
    "category": "Symbols",
    "emoji": "↪️"
  },
  {
    "category": "Symbols",
    "emoji": "⤴️"
  },
  {
    "category": "Symbols",
    "emoji": "⤵️"
  },
  {
    "category": "Symbols",
    "emoji": "🔃"
  },
  {
    "category": "Symbols",
    "emoji": "🔄"
  },
  {
    "category": "Symbols",
    "emoji": "🔙"
  },
  {
    "category": "Symbols",
    "emoji": "🔚"
  },
  {
    "category": "Symbols",
    "emoji": "🔛"
  },
  {
    "category": "Symbols",
    "emoji": "🔜"
  },
  {
    "category": "Symbols",
    "emoji": "🔝"
  },
  {
    "category": "Symbols",
    "emoji": "🛐"
  },
  {
    "category": "Symbols",
    "emoji": "⚛️"
  },
  {
    "category": "Symbols",
    "emoji": "🕉️"
  },
  {
    "category": "Symbols",
    "emoji": "✡️"
  },
  {
    "category": "Symbols",
    "emoji": "☸️"
  },
  {
    "category": "Symbols",
    "emoji": "☯️"
  },
  {
    "category": "Symbols",
    "emoji": "✝️"
  },
  {
    "category": "Symbols",
    "emoji": "☦️"
  },
  {
    "category": "Symbols",
    "emoji": "☪️"
  },
  {
    "category": "Symbols",
    "emoji": "☮️"
  },
  {
    "category": "Symbols",
    "emoji": "🕎"
  },
  {
    "category": "Symbols",
    "emoji": "🔯"
  },
  {
    "category": "Symbols",
    "emoji": "🪯"
  },
  {
    "category": "Symbols",
    "emoji": "♈"
  },
  {
    "category": "Symbols",
    "emoji": "♉"
  },
  {
    "category": "Symbols",
    "emoji": "♊"
  },
  {
    "category": "Symbols",
    "emoji": "♋"
  },
  {
    "category": "Symbols",
    "emoji": "♌"
  },
  {
    "category": "Symbols",
    "emoji": "♍"
  },
  {
    "category": "Symbols",
    "emoji": "♎"
  },
  {
    "category": "Symbols",
    "emoji": "♏"
  },
  {
    "category": "Symbols",
    "emoji": "♐"
  },
  {
    "category": "Symbols",
    "emoji": "♑"
  },
  {
    "category": "Symbols",
    "emoji": "♒"
  },
  {
    "category": "Symbols",
    "emoji": "♓"
  },
  {
    "category": "Symbols",
    "emoji": "⛎"
  },
  {
    "category": "Symbols",
    "emoji": "🔀"
  },
  {
    "category": "Symbols",
    "emoji": "🔁"
  },
  {
    "category": "Symbols",
    "emoji": "🔂"
  },
  {
    "category": "Symbols",
    "emoji": "▶️"
  },
  {
    "category": "Symbols",
    "emoji": "⏩"
  },
  {
    "category": "Symbols",
    "emoji": "⏭️"
  },
  {
    "category": "Symbols",
    "emoji": "⏯️"
  },
  {
    "category": "Symbols",
    "emoji": "◀️"
  },
  {
    "category": "Symbols",
    "emoji": "⏪"
  },
  {
    "category": "Symbols",
    "emoji": "⏮️"
  },
  {
    "category": "Symbols",
    "emoji": "🔼"
  },
  {
    "category": "Symbols",
    "emoji": "⏫"
  },
  {
    "category": "Symbols",
    "emoji": "🔽"
  },
  {
    "category": "Symbols",
    "emoji": "⏬"
  },
  {
    "category": "Symbols",
    "emoji": "⏸️"
  },
  {
    "category": "Symbols",
    "emoji": "⏹️"
  },
  {
    "category": "Symbols",
    "emoji": "⏺️"
  },
  {
    "category": "Symbols",
    "emoji": "⏏️"
  },
  {
    "category": "Symbols",
    "emoji": "🎦"
  },
  {
    "category": "Symbols",
    "emoji": "🔅"
  },
  {
    "category": "Symbols",
    "emoji": "🔆"
  },
  {
    "category": "Symbols",
    "emoji": "📶"
  },
  {
    "category": "Symbols",
    "emoji": "🛜"
  },
  {
    "category": "Symbols",
    "emoji": "📳"
  },
  {
    "category": "Symbols",
    "emoji": "📴"
  },
  {
    "category": "Symbols",
    "emoji": "♀️"
  },
  {
    "category": "Symbols",
    "emoji": "♂️"
  },
  {
    "category": "Symbols",
    "emoji": "⚧️"
  },
  {
    "category": "Symbols",
    "emoji": "✖️"
  },
  {
    "category": "Symbols",
    "emoji": "➕"
  },
  {
    "category": "Symbols",
    "emoji": "➖"
  },
  {
    "category": "Symbols",
    "emoji": "➗"
  },
  {
    "category": "Symbols",
    "emoji": "🟰"
  },
  {
    "category": "Symbols",
    "emoji": "♾️"
  },
  {
    "category": "Symbols",
    "emoji": "‼️"
  },
  {
    "category": "Symbols",
    "emoji": "⁉️"
  },
  {
    "category": "Symbols",
    "emoji": "❓"
  },
  {
    "category": "Symbols",
    "emoji": "❔"
  },
  {
    "category": "Symbols",
    "emoji": "❕"
  },
  {
    "category": "Symbols",
    "emoji": "❗"
  },
  {
    "category": "Symbols",
    "emoji": "〰️"
  },
  {
    "category": "Symbols",
    "emoji": "💱"
  },
  {
    "category": "Symbols",
    "emoji": "💲"
  },
  {
    "category": "Symbols",
    "emoji": "⚕️"
  },
  {
    "category": "Symbols",
    "emoji": "♻️"
  },
  {
    "category": "Symbols",
    "emoji": "⚜️"
  },
  {
    "category": "Symbols",
    "emoji": "🔱"
  },
  {
    "category": "Symbols",
    "emoji": "📛"
  },
  {
    "category": "Symbols",
    "emoji": "🔰"
  },
  {
    "category": "Symbols",
    "emoji": "⭕"
  },
  {
    "category": "Symbols",
    "emoji": "✅"
  },
  {
    "category": "Symbols",
    "emoji": "☑️"
  },
  {
    "category": "Symbols",
    "emoji": "✔️"
  },
  {
    "category": "Symbols",
    "emoji": "❌"
  },
  {
    "category": "Symbols",
    "emoji": "❎"
  },
  {
    "category": "Symbols",
    "emoji": "➰"
  },
  {
    "category": "Symbols",
    "emoji": "➿"
  },
  {
    "category": "Symbols",
    "emoji": "〽️"
  },
  {
    "category": "Symbols",
    "emoji": "✳️"
  },
  {
    "category": "Symbols",
    "emoji": "✴️"
  },
  {
    "category": "Symbols",
    "emoji": "❇️"
  },
  {
    "category": "Symbols",
    "emoji": "©️"
  },
  {
    "category": "Symbols",
    "emoji": "®️"
  },
  {
    "category": "Symbols",
    "emoji": "™️"
  },
  {
    "category": "Symbols",
    "emoji": "#️⃣"
  },
  {
    "category": "Symbols",
    "emoji": "*️⃣"
  },
  {
    "category": "Symbols",
    "emoji": "0️⃣"
  },
  {
    "category": "Symbols",
    "emoji": "1️⃣"
  },
  {
    "category": "Symbols",
    "emoji": "2️⃣"
  },
  {
    "category": "Symbols",
    "emoji": "3️⃣"
  },
  {
    "category": "Symbols",
    "emoji": "4️⃣"
  },
  {
    "category": "Symbols",
    "emoji": "5️⃣"
  },
  {
    "category": "Symbols",
    "emoji": "6️⃣"
  },
  {
    "category": "Symbols",
    "emoji": "7️⃣"
  },
  {
    "category": "Symbols",
    "emoji": "8️⃣"
  },
  {
    "category": "Symbols",
    "emoji": "9️⃣"
  },
  {
    "category": "Symbols",
    "emoji": "🔟"
  },
  {
    "category": "Symbols",
    "emoji": "🔠"
  },
  {
    "category": "Symbols",
    "emoji": "🔡"
  },
  {
    "category": "Symbols",
    "emoji": "🔢"
  },
  {
    "category": "Symbols",
    "emoji": "🔣"
  },
  {
    "category": "Symbols",
    "emoji": "🔤"
  },
  {
    "category": "Symbols",
    "emoji": "🅰️"
  },
  {
    "category": "Symbols",
    "emoji": "🆎"
  },
  {
    "category": "Symbols",
    "emoji": "🅱️"
  },
  {
    "category": "Symbols",
    "emoji": "🆑"
  },
  {
    "category": "Symbols",
    "emoji": "🆒"
  },
  {
    "category": "Symbols",
    "emoji": "🆓"
  },
  {
    "category": "Symbols",
    "emoji": "ℹ️"
  },
  {
    "category": "Symbols",
    "emoji": "🆔"
  },
  {
    "category": "Symbols",
    "emoji": "Ⓜ️"
  },
  {
    "category": "Symbols",
    "emoji": "🆕"
  },
  {
    "category": "Symbols",
    "emoji": "🆖"
  },
  {
    "category": "Symbols",
    "emoji": "🅾️"
  },
  {
    "category": "Symbols",
    "emoji": "🆗"
  },
  {
    "category": "Symbols",
    "emoji": "🅿️"
  },
  {
    "category": "Symbols",
    "emoji": "🆘"
  },
  {
    "category": "Symbols",
    "emoji": "🆙"
  },
  {
    "category": "Symbols",
    "emoji": "🆚"
  },
  {
    "category": "Symbols",
    "emoji": "🈁"
  },
  {
    "category": "Symbols",
    "emoji": "🈂️"
  },
  {
    "category": "Symbols",
    "emoji": "🈷️"
  },
  {
    "category": "Symbols",
    "emoji": "🈶"
  },
  {
    "category": "Symbols",
    "emoji": "🈯"
  },
  {
    "category": "Symbols",
    "emoji": "🉐"
  },
  {
    "category": "Symbols",
    "emoji": "🈹"
  },
  {
    "category": "Symbols",
    "emoji": "🈚"
  },
  {
    "category": "Symbols",
    "emoji": "🈲"
  },
  {
    "category": "Symbols",
    "emoji": "🉑"
  },
  {
    "category": "Symbols",
    "emoji": "🈸"
  },
  {
    "category": "Symbols",
    "emoji": "🈴"
  },
  {
    "category": "Symbols",
    "emoji": "🈳"
  },
  {
    "category": "Symbols",
    "emoji": "㊗️"
  },
  {
    "category": "Symbols",
    "emoji": "㊙️"
  },
  {
    "category": "Symbols",
    "emoji": "🈺"
  },
  {
    "category": "Symbols",
    "emoji": "🈵"
  },
  {
    "category": "Symbols",
    "emoji": "🔴"
  },
  {
    "category": "Symbols",
    "emoji": "🟠"
  },
  {
    "category": "Symbols",
    "emoji": "🟡"
  },
  {
    "category": "Symbols",
    "emoji": "🟢"
  },
  {
    "category": "Symbols",
    "emoji": "🔵"
  },
  {
    "category": "Symbols",
    "emoji": "🟣"
  },
  {
    "category": "Symbols",
    "emoji": "🟤"
  },
  {
    "category": "Symbols",
    "emoji": "⚫"
  },
  {
    "category": "Symbols",
    "emoji": "⚪"
  },
  {
    "category": "Symbols",
    "emoji": "🟥"
  },
  {
    "category": "Symbols",
    "emoji": "🟧"
  },
  {
    "category": "Symbols",
    "emoji": "🟨"
  },
  {
    "category": "Symbols",
    "emoji": "🟩"
  },
  {
    "category": "Symbols",
    "emoji": "🟦"
  },
  {
    "category": "Symbols",
    "emoji": "🟪"
  },
  {
    "category": "Symbols",
    "emoji": "🟫"
  },
  {
    "category": "Symbols",
    "emoji": "⬛"
  },
  {
    "category": "Symbols",
    "emoji": "⬜"
  },
  {
    "category": "Symbols",
    "emoji": "◼️"
  },
  {
    "category": "Symbols",
    "emoji": "◻️"
  },
  {
    "category": "Symbols",
    "emoji": "◾"
  },
  {
    "category": "Symbols",
    "emoji": "◽"
  },
  {
    "category": "Symbols",
    "emoji": "▪️"
  },
  {
    "category": "Symbols",
    "emoji": "▫️"
  },
  {
    "category": "Symbols",
    "emoji": "🔶"
  },
  {
    "category": "Symbols",
    "emoji": "🔷"
  },
  {
    "category": "Symbols",
    "emoji": "🔸"
  },
  {
    "category": "Symbols",
    "emoji": "🔹"
  },
  {
    "category": "Symbols",
    "emoji": "🔺"
  },
  {
    "category": "Symbols",
    "emoji": "🔻"
  },
  {
    "category": "Symbols",
    "emoji": "💠"
  },
  {
    "category": "Symbols",
    "emoji": "🔘"
  },
  {
    "category": "Symbols",
    "emoji": "🔳"
  },
  {
    "category": "Symbols",
    "emoji": "🔲"
  },
  {
    "category": "Flags",
    "emoji": "🏁"
  },
  {
    "category": "Flags",
    "emoji": "🚩"
  },
  {
    "category": "Flags",
    "emoji": "🎌"
  },
  {
    "category": "Flags",
    "emoji": "🏴"
  },
  {
    "category": "Flags",
    "emoji": "🏳️"
  },
  {
    "category": "Flags",
    "emoji": "🏳️‍🌈"
  },
  {
    "category": "Flags",
    "emoji": "🏳️‍⚧️"
  },
  {
    "category": "Flags",
    "emoji": "🏴‍☠️"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇨"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇩"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇫"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇬"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇮"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇱"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇴"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇶"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇸"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇹"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇺"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇼"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇽"
  },
  {
    "category": "Flags",
    "emoji": "🇦🇿"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇦"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇧"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇩"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇫"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇬"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇭"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇮"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇯"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇱"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇳"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇴"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇶"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇸"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇹"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇻"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇼"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇾"
  },
  {
    "category": "Flags",
    "emoji": "🇧🇿"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇦"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇨"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇩"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇫"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇬"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇭"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇮"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇰"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇱"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇳"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇴"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇵"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇺"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇻"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇼"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇽"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇾"
  },
  {
    "category": "Flags",
    "emoji": "🇨🇿"
  },
  {
    "category": "Flags",
    "emoji": "🇩🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇩🇬"
  },
  {
    "category": "Flags",
    "emoji": "🇩🇯"
  },
  {
    "category": "Flags",
    "emoji": "🇩🇰"
  },
  {
    "category": "Flags",
    "emoji": "🇩🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇩🇴"
  },
  {
    "category": "Flags",
    "emoji": "🇩🇿"
  },
  {
    "category": "Flags",
    "emoji": "🇪🇦"
  },
  {
    "category": "Flags",
    "emoji": "🇪🇨"
  },
  {
    "category": "Flags",
    "emoji": "🇪🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇪🇬"
  },
  {
    "category": "Flags",
    "emoji": "🇪🇭"
  },
  {
    "category": "Flags",
    "emoji": "🇪🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇪🇸"
  },
  {
    "category": "Flags",
    "emoji": "🇪🇹"
  },
  {
    "category": "Flags",
    "emoji": "🇪🇺"
  },
  {
    "category": "Flags",
    "emoji": "🇫🇮"
  },
  {
    "category": "Flags",
    "emoji": "🇫🇯"
  },
  {
    "category": "Flags",
    "emoji": "🇫🇰"
  },
  {
    "category": "Flags",
    "emoji": "🇫🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇫🇴"
  },
  {
    "category": "Flags",
    "emoji": "🇫🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇦"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇧"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇩"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇫"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇬"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇭"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇮"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇱"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇳"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇵"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇶"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇸"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇹"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇺"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇼"
  },
  {
    "category": "Flags",
    "emoji": "🇬🇾"
  },
  {
    "category": "Flags",
    "emoji": "🇭🇰"
  },
  {
    "category": "Flags",
    "emoji": "🇭🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇭🇳"
  },
  {
    "category": "Flags",
    "emoji": "🇭🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇭🇹"
  },
  {
    "category": "Flags",
    "emoji": "🇭🇺"
  },
  {
    "category": "Flags",
    "emoji": "🇮🇨"
  },
  {
    "category": "Flags",
    "emoji": "🇮🇩"
  },
  {
    "category": "Flags",
    "emoji": "🇮🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇮🇱"
  },
  {
    "category": "Flags",
    "emoji": "🇮🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇮🇳"
  },
  {
    "category": "Flags",
    "emoji": "🇮🇴"
  },
  {
    "category": "Flags",
    "emoji": "🇮🇶"
  },
  {
    "category": "Flags",
    "emoji": "🇮🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇮🇸"
  },
  {
    "category": "Flags",
    "emoji": "🇮🇹"
  },
  {
    "category": "Flags",
    "emoji": "🇯🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇯🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇯🇴"
  },
  {
    "category": "Flags",
    "emoji": "🇯🇵"
  },
  {
    "category": "Flags",
    "emoji": "🇰🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇰🇬"
  },
  {
    "category": "Flags",
    "emoji": "🇰🇭"
  },
  {
    "category": "Flags",
    "emoji": "🇰🇮"
  },
  {
    "category": "Flags",
    "emoji": "🇰🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇰🇳"
  },
  {
    "category": "Flags",
    "emoji": "🇰🇵"
  },
  {
    "category": "Flags",
    "emoji": "🇰🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇰🇼"
  },
  {
    "category": "Flags",
    "emoji": "🇰🇾"
  },
  {
    "category": "Flags",
    "emoji": "🇰🇿"
  },
  {
    "category": "Flags",
    "emoji": "🇱🇦"
  },
  {
    "category": "Flags",
    "emoji": "🇱🇧"
  },
  {
    "category": "Flags",
    "emoji": "🇱🇨"
  },
  {
    "category": "Flags",
    "emoji": "🇱🇮"
  },
  {
    "category": "Flags",
    "emoji": "🇱🇰"
  },
  {
    "category": "Flags",
    "emoji": "🇱🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇱🇸"
  },
  {
    "category": "Flags",
    "emoji": "🇱🇹"
  },
  {
    "category": "Flags",
    "emoji": "🇱🇺"
  },
  {
    "category": "Flags",
    "emoji": "🇱🇻"
  },
  {
    "category": "Flags",
    "emoji": "🇱🇾"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇦"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇨"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇩"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇫"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇬"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇭"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇰"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇱"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇳"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇴"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇵"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇶"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇸"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇹"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇺"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇻"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇼"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇽"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇾"
  },
  {
    "category": "Flags",
    "emoji": "🇲🇿"
  },
  {
    "category": "Flags",
    "emoji": "🇳🇦"
  },
  {
    "category": "Flags",
    "emoji": "🇳🇨"
  },
  {
    "category": "Flags",
    "emoji": "🇳🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇳🇫"
  },
  {
    "category": "Flags",
    "emoji": "🇳🇬"
  },
  {
    "category": "Flags",
    "emoji": "🇳🇮"
  },
  {
    "category": "Flags",
    "emoji": "🇳🇱"
  },
  {
    "category": "Flags",
    "emoji": "🇳🇴"
  },
  {
    "category": "Flags",
    "emoji": "🇳🇵"
  },
  {
    "category": "Flags",
    "emoji": "🇳🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇳🇺"
  },
  {
    "category": "Flags",
    "emoji": "🇳🇿"
  },
  {
    "category": "Flags",
    "emoji": "🇴🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇵🇦"
  },
  {
    "category": "Flags",
    "emoji": "🇵🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇵🇫"
  },
  {
    "category": "Flags",
    "emoji": "🇵🇬"
  },
  {
    "category": "Flags",
    "emoji": "🇵🇭"
  },
  {
    "category": "Flags",
    "emoji": "🇵🇰"
  },
  {
    "category": "Flags",
    "emoji": "🇵🇱"
  },
  {
    "category": "Flags",
    "emoji": "🇵🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇵🇳"
  },
  {
    "category": "Flags",
    "emoji": "🇵🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇵🇸"
  },
  {
    "category": "Flags",
    "emoji": "🇵🇹"
  },
  {
    "category": "Flags",
    "emoji": "🇵🇼"
  },
  {
    "category": "Flags",
    "emoji": "🇵🇾"
  },
  {
    "category": "Flags",
    "emoji": "🇶🇦"
  },
  {
    "category": "Flags",
    "emoji": "🇷🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇷🇴"
  },
  {
    "category": "Flags",
    "emoji": "🇷🇸"
  },
  {
    "category": "Flags",
    "emoji": "🇷🇺"
  },
  {
    "category": "Flags",
    "emoji": "🇷🇼"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇦"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇧"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇨"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇩"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇬"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇭"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇮"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇯"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇰"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇱"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇳"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇴"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇸"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇹"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇻"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇽"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇾"
  },
  {
    "category": "Flags",
    "emoji": "🇸🇿"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇦"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇨"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇩"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇫"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇬"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇭"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇯"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇰"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇱"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇳"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇴"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇷"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇹"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇻"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇼"
  },
  {
    "category": "Flags",
    "emoji": "🇹🇿"
  },
  {
    "category": "Flags",
    "emoji": "🇺🇦"
  },
  {
    "category": "Flags",
    "emoji": "🇺🇬"
  },
  {
    "category": "Flags",
    "emoji": "🇺🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇺🇳"
  },
  {
    "category": "Flags",
    "emoji": "🇺🇸"
  },
  {
    "category": "Flags",
    "emoji": "🇺🇾"
  },
  {
    "category": "Flags",
    "emoji": "🇺🇿"
  },
  {
    "category": "Flags",
    "emoji": "🇻🇦"
  },
  {
    "category": "Flags",
    "emoji": "🇻🇨"
  },
  {
    "category": "Flags",
    "emoji": "🇻🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇻🇬"
  },
  {
    "category": "Flags",
    "emoji": "🇻🇮"
  },
  {
    "category": "Flags",
    "emoji": "🇻🇳"
  },
  {
    "category": "Flags",
    "emoji": "🇻🇺"
  },
  {
    "category": "Flags",
    "emoji": "🇼🇫"
  },
  {
    "category": "Flags",
    "emoji": "🇼🇸"
  },
  {
    "category": "Flags",
    "emoji": "🇽🇰"
  },
  {
    "category": "Flags",
    "emoji": "🇾🇪"
  },
  {
    "category": "Flags",
    "emoji": "🇾🇹"
  },
  {
    "category": "Flags",
    "emoji": "🇿🇦"
  },
  {
    "category": "Flags",
    "emoji": "🇿🇲"
  },
  {
    "category": "Flags",
    "emoji": "🇿🇼"
  },
  {
    "category": "Flags",
    "emoji": "🏴󠁧󠁢󠁥󠁮󠁧󠁿"
  },
  {
    "category": "Flags",
    "emoji": "🏴󠁧󠁢󠁳󠁣󠁴󠁿"
  },
  {
    "category": "Flags",
    "emoji": "🏴󠁧󠁢󠁷󠁬󠁳󠁿"
  }
]
