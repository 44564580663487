@if (ngControl && !compact) {
  <mat-form-field class="w-full">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }

    <input
      type="text"
      matNativeControl
      matInput
      [formControl]="formControl"
      [placeholder]="placeholder"
      (keydown)="processInput($event, 'form')"
      (paste)="hexCodePasted($event, true)"
      (focus)="!platformService.isMobile ? openColorPicker() : null"
      (keydown.Tab)="closeColorPickerMenu()"
      (keyup)="keyUp($event, 'form')"
      [readonly]="colorPickerOpen ? true : null"
    />
    <div matSuffix class="flex flex-nowrap items-center">
      <div
        [matMenuTriggerFor]="!platformService.isMobile ? menu : null"
        [style.background-color]="value ? value : '#00A9E2'"
        class="ws-color-picker_button-background w-6 h-6 mr-2 cursor-pointer"
        (click)="openColorPicker($event)"
      ></div>

      @if (clearable) {
        <mat-icon class="ws-simple-input_icon cursor-pointer mr-2" (click)="clearValue($event)">close</mat-icon>
      }
    </div>

    @if (hint) {
      <mat-hint>{{ hint }}</mat-hint>
    }

    @if (hasErrors) {
      <mat-error>{{ validationErrorMessage }}</mat-error>
    }
  </mat-form-field>
}

<div class="mat-mdc-form-field" *ngIf="ngControl && compact">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }

  @if (platformService.isMobile) {
    <button matSuffix mat-icon-button (click)="openColorPicker()">
      <div
        [style.background-color]="value ? value : '#00A9E2'"
        class="ws-color-picker_button-background w-full h-full"
      ></div>
    </button>
  }

  @if (!platformService.isMobile) {
    <button matSuffix mat-icon-button (click)="openColorPicker()" [matMenuTriggerFor]="menu">
      <div
        [style.background-color]="value ? value : '#00A9E2'"
        class="ws-color-picker_button-background w-full h-full"
      ></div>
    </button>
  }

  @if (hasErrors) {
    <div class="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align">
      <div class="mat-mdc-form-field-error-wrapper">
        <mat-error>
          {{ validationErrorMessage }}
        </mat-error>
      </div>
    </div>
  }
</div>

<ng-template #colorPickerDialog>
  <div
    class="ws-color-picker flex flex-wrap flex-col items-center justify-center mt-4 mx-4 md:mx-8 mb-4 pointer-events-auto"
    (click)="$event.stopPropagation()"
    (keyup.enter)="closeColorPickerMenu()"
  >
    <div class="w-full flex justify-center" [style.max-width.px]="sliderWidth">
      <div class="relative w-fit h-fit">
        <canvas
          [id]="canvasId"
          [width]="size"
          [height]="size"
          class="ws-color-picker_gradient relative"
          [ngClass]="draggingSaturation ? 'cursor-rab' : 'cursor-pointer'"
        ></canvas>
        <div
          class="ws-color-picker_gradient-indicator absolute pointer-events-none flex justify-center items-center"
          [style.left.px]="saturationMarkerPosition.x - 10"
          [style.top.px]="saturationMarkerPosition.y - 10"
          [style.background-color]="colorToDisplay"
        ></div>
      </div>

      <div class="w-32 sm:w-36 ml-auto">
        <ws-form-input
          prefix="R:&nbsp;"
          [(ngModel)]="valueRGB.r"
          type="number"
          [min]="0"
          [max]="255"
          [style.padding.top.px]="0"
          [style.--ws-form-field-padding-top.px]="0"
          (ngModelChange)="rgbInputChanged()"
          (keydown)="processInput($event, 'rgb-r')"
        ></ws-form-input>
        <ws-form-input
          prefix="G:&nbsp;"
          [(ngModel)]="valueRGB.g"
          type="number"
          [min]="0"
          [max]="255"
          (ngModelChange)="rgbInputChanged()"
          (keydown)="processInput($event, 'rgb-g')"
        ></ws-form-input>
        <ws-form-input
          prefix="B:&nbsp;"
          [(ngModel)]="valueRGB.b"
          type="number"
          [min]="0"
          [max]="255"
          (ngModelChange)="rgbInputChanged()"
          (keydown)="processInput($event, 'rgb-b')"
        ></ws-form-input>
      </div>
    </div>

    <div class="relative w-full h-fit mt-8" [style.max-width.px]="sliderWidth">
      <canvas
        [id]="sliderId"
        [width]="sliderWidth"
        [height]="10"
        class="w-full ws-color-picker_slider"
        [ngClass]="draggingColor ? 'cursor-rab' : 'cursor-pointer'"
      ></canvas>
      <div
        class="ws-color-picker_slider-indicator absolute pointer-events-none flex justify-center items-center"
        [style.left.px]="colorMarkerPosition - 10"
        [style.background-color]="'#' + colorToDraw"
      ></div>
    </div>

    <div
      class="w-full mt-4 flex items-center"
      [style.max-width.px]="sliderWidth"
      [style.--ws-form-field-width]="'calc(100% - 2rem - 56px)'"
    >
      <ws-form-input
        prefix="Hex:&nbsp;#"
        [(ngModel)]="valueHex"
        type="text"
        class="ws-color-picker_hex-input"
        [maxLength]="7"
        [enableCopyToClipboard]="true"
        (ngModelChange)="hexInputChanged()"
        (paste)="hexCodePasted($event)"
        (keydown)="processInput($event, 'hex')"
        (keyup)="keyUp($event, 'hex')"
      ></ws-form-input>

      <div class="ws-color-picker_preview-color cursor-pointer ml-auto" [style.background-color]="colorToDisplay"></div>
    </div>

    @if (preselectColors && preselectColors.length > 0) {
      <div
        class="ws-color-picker_preselect-colors w-full h-8 flex flex-wrap justify-between mt-8"
        [style.max-width.px]="sliderWidth"
      >
        @for (color of preselectColors; track color) {
          <div
            class="ws-color-picker_preselect-color h-8 w-8 aspect-square cursor-pointer"
            [style.background-color]="'#' + color"
            (click)="selectPreselectColor(color)"
          ></div>
        }
      </div>
    }
  </div>
</ng-template>
<mat-menu #menu="matMenu" class="ws-color-picker_desktop pointer-events-none" (closed)="emitValueChange()">
  <ng-container *ngTemplateOutlet="colorPickerDialog"></ng-container>
</mat-menu>
