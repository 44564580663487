import { Component } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'
import { OptionsFieldComponent } from '../options-field/options-field.component'

@Component({
  selector: 'ws-form-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss']
})
export class ButtonToggleComponent extends OptionsFieldComponent implements ControlValueAccessor {
  public override value: string | number | boolean = ''

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public override onChange(newValue: string | number | boolean) {}

  public override onFieldChange(input: string | number | boolean) {
    this.value = input
    super.onFieldChange(input)
  }

  public override registerOnChange(fn: (value: string | number | boolean) => void): void {
    this.onChange = fn
  }

  public override writeValue(value: string | number | boolean): void {
    this.value = value
  }
}
