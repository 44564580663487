import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'objectValue'
})
export class ObjectValuePipe implements PipeTransform {
  transform(object: any, propString: string): any {
    return this.getPropByString(object, propString)
  }

  private getPropByString(object: any, propString: string) {
    if (!propString) return object

    const properties: string[] = propString.split('.')
    return properties.reduce((prev, curr: string) => prev?.[curr], object)
  }
}
