<div class="p-4">
  <form [formGroup]="pageLayoutForm">
    <div id="wspdf-page-layout-settings-background">
      <h1 class="element-settings-title">
        {{ 'template.element.background' | translate }}
      </h1>
      <ws-form-input
        label="{{ 'template.element.backgroundImageUrl' | translate }}"
        formControlName="backgroundImage"
        class="mt-4"
      ></ws-form-input>
    </div>
    <div class="mt-4">
      <ws-form-color-picker
        formControlName="backgroundColor"
        label="{{ 'template.element.backgroundColor' | translate }}"
      >
      </ws-form-color-picker>
    </div>
    <div class="my-6">
      <h1 class="element-settings-title pb-2">
        {{ 'template.settings.margins' | translate }}
      </h1>
      <div class="grid grid-cols-2 space-x-4">
        <div>
          <ws-form-checkbox
            formControlName="header"
            label="{{ 'template.settings.header' | translate }}"
          ></ws-form-checkbox>
          <ws-form-input
            *ngIf="pageLayoutForm.controls && pageLayoutForm.controls['header'].value"
            formControlName="headerHeight"
            label="{{ 'height' | translate }}"
            type="number"
            [min]="0"
            [step]="0.1"
            suffix="cm"
          ></ws-form-input>
        </div>
        <div>
          <ws-form-checkbox
            formControlName="footer"
            label="{{ 'template.settings.footer' | translate }}"
          ></ws-form-checkbox>
          <ws-form-input
            *ngIf="pageLayoutForm.controls && pageLayoutForm.controls['footer'].value"
            formControlName="footerHeight"
            label="{{ 'height' | translate }}"
            type="number"
            [min]="0"
            [step]="0.1"
            suffix="cm"
          ></ws-form-input>
        </div>
      </div>
    </div>
    <div class="my-6">
      <h1 class="element-settings-title pb-4">
        {{ 'template.settings.margins' | translate }}
      </h1>
      <ws-form-input
        type="number"
        [min]="0"
        [step]="0.1"
        suffix="cm"
        formControlName="marginTop"
        label="{{ 'template.settings.marginTop' | translate }}"
      ></ws-form-input>
      <ws-form-input
        style="--ws-form-field-padding-top: 2em"
        type="number"
        [min]="0"
        [step]="0.1"
        suffix="cm"
        formControlName="marginRight"
        label="{{ 'template.settings.marginRight' | translate }}"
      ></ws-form-input>
      <ws-form-input
        style="--ws-form-field-padding-top: 2em"
        type="number"
        [min]="0"
        [step]="0.1"
        suffix="cm"
        formControlName="marginBottom"
        label="{{ 'template.settings.marginBottom' | translate }}"
      ></ws-form-input>
      <ws-form-input
        style="--ws-form-field-padding-top: 2em"
        type="number"
        [min]="0"
        [step]="0.1"
        suffix="cm"
        formControlName="marginLeft"
        label="{{ 'template.settings.marginLeft' | translate }}"
      ></ws-form-input>
    </div>
  </form>
</div>
