import { Injectable } from '@angular/core'
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar'
import { NotificationSettings } from '../../types/notifications/notification-settings'
import { SnackbarComponent } from './snackbar/snackbar.component'

/**
 * This service provides all methods to create notifications.
 * @example
 * constructor(private wsNotificationsService: WsNotificationsService) {}
 * this.wsNotificationsService.createNotification('This is a notification')
 */
@Injectable({
  providedIn: 'root'
})
export class WsNotificationsService {
  /**
   * The constructor for the notifications service.
   * @param snackBar The Material snackbar
   * @param dialog The Material dialog
   */
  constructor(public snackBar: MatSnackBar) {}

  /**
   * Creates a notification as a snackbar or dialog.
   * @param message The message to display in the notification
   * @param settings The notification settings
   */
  createNotification(message: string, settings?: NotificationSettings) {
    if (!message) {
      console.warn('Cannot create notification.\n"message" needs to be supplied')
      return
    }
    switch (settings?.type) {
      case 'snackbar': {
        this.createSnackBar(message, settings)
        break
      }
      default: {
        this.createSnackBar(message, settings)
        break
      }
    }
  }

  /**
   * Creates a snackbar notification.
   * Snackbars can be displayed at the top, bottom, left or right of the screen and have different colors depending on the severity.
   * @param message The message to display in the notification
   * @param settings The notification settings
   */
  createSnackBar(message: string, settings?: NotificationSettings): MatSnackBarRef<SnackbarComponent> {
    const duration = settings?.duration ? settings?.duration : 5000
    const severity = (settings?.severity || 'info') + '-notification'
    const horizontalPosition = settings?.horizontalPosition || 'right'
    const verticalPosition = settings?.verticalPosition || 'bottom'
    const iconPosition = settings?.iconPosition || 'left'

    return this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message,
        action: settings?.action,
        icon: settings?.icon,
        iconPosition: iconPosition
      },
      duration: duration,
      panelClass: [severity, 'ws-snackbar--' + horizontalPosition, 'ws-snackbar--' + verticalPosition],
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition
    })
  }

  success(message: string, settings?: NotificationSettings) {
    this.createNotification(message, { ...settings, severity: 'success' })
  }
  info(message: string, settings?: NotificationSettings) {
    this.createNotification(message, { ...settings, severity: 'info' })
  }
  warning(message: string, settings?: NotificationSettings) {
    this.createNotification(message, { ...settings, severity: 'warning' })
  }
  error(message: string, settings?: NotificationSettings) {
    this.createNotification(message, { ...settings, severity: 'error' })
  }
}
