import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormControl, FormGroup } from '@angular/forms'
import { WspdfTemplate } from '../../types/wspdf-template'
import { first } from 'rxjs'
import { PdfService } from '../pdf.service'

export enum Orientation {
  PORTRAIT,
  LANDSCAPE
}

@Component({
  selector: 'app-pdf-create-template-dialog',
  templateUrl: './pdf-create-template-dialog.component.html',
  styleUrls: ['./pdf-create-template-dialog.component.scss']
})
export class PdfCreateTemplateDialogComponent {
  Orientation: typeof Orientation = Orientation

  createTemplateForm = new FormGroup({
    templateName: new FormControl(''),
    size: new FormControl()
  })

  constructor(
    private pdfService: PdfService,
    private dialogRef: MatDialogRef<PdfCreateTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormGroup
  ) {}

  save() {
    if (!this.createTemplateForm.valid || !this.createTemplateForm.value['templateName']) {
      return
    }
    this.createNewTemplate(this.createTemplateForm.value['templateName'])
  }

  close() {
    this.dialogRef.close()
  }

  createNewTemplate(templateName: string): WspdfTemplate | null | undefined {
    if (templateName == '' || templateName.includes('%')) {
      this.handleResponse(400)
      return null
    }
    const templateContent = ''
    const templateStyles = JSON.stringify({
      '@page': {
        'margin-top': 2,
        'margin-right': 2,
        'margin-bottom': 2,
        'margin-left': 2,
        'counter-increment': 'page',
        'counter-reset': 'page 1',
        header: false,
        footer: false,
        headerHeight: 0,
        footerHeight: 0,
        backgroundImage: '',
        backgroundColor: '#FFFFFF'
      }
    })
    this.pdfService
      .saveTemplate(templateName, templateContent, templateStyles)
      .pipe(first())
      .subscribe({
        next: (response: WspdfTemplate) => {
          this.dialogRef.close(response)
          this.handleResponse(200)
          return response
        },
        error: (err: any) => {
          this.handleResponse(500)
        }
      })
    return null
  }

  private handleResponse(code: number) {
    console.log('HANDLE RESPONSE | CODE: ', code)
  }
}
