import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { WspdfTemplate } from '../types/wspdf-template'
import { ApiConstants } from './api.constants'
import { WspdfTemplateHistory } from '../types/wspdf-template-history'

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  private templateIsReadyToPreview = new BehaviorSubject(true)

  setTemplateIsReadyToPreview(value: boolean) {
    this.templateIsReadyToPreview.next(value)
  }

  getTemplateIsReadyToPreview(): Observable<boolean> {
    return this.templateIsReadyToPreview.asObservable()
  }

  constructor(
    private http: HttpClient,
    private apiConstants: ApiConstants
  ) {}

  loadAllTemplates(): Observable<WspdfTemplate[]> {
    return this.http.get<WspdfTemplate[]>(
      this.apiConstants.apiServerUrl +
        this.apiConstants.api_prefix +
        this.apiConstants.wspdf +
        this.apiConstants.wspdf__template
    )
  }

  loadTemplateById(templateId: number) {
    return this.http.get<WspdfTemplate>(
      this.apiConstants.apiServerUrl +
        this.apiConstants.api_prefix +
        this.apiConstants.wspdf +
        this.apiConstants.wspdf__template +
        templateId
    )
  }

  setTempApiKey(key: string) {
    sessionStorage.setItem('ws|pdf-api-key', key)
  }

  getTempApiKey(): string {
    return sessionStorage.getItem('ws|pdf-api-key') || ''
  }

  setUserId(id: string) {
    sessionStorage.setItem('ws|pdf-user-id', id)
  }

  getUserId(): string {
    return sessionStorage.getItem('ws|pdf-user-id') || ''
  }

  loadSync(templateId: number) {
    return new WspdfTemplate()
  }

  saveTemplate(templateName: string, templateContent: string, templateStyles: string) {
    console.log({ templateName, templateContent, templateStyles })
    return this.http.post<WspdfTemplate>(
      this.apiConstants.apiServerUrl +
        this.apiConstants.api_prefix +
        this.apiConstants.wspdf +
        this.apiConstants.wspdf__template,
      { name: templateName, content: templateContent, styles: templateStyles }
    )
  }

  duplicateTemplate(templateId: number) {
    return this.http.post<WspdfTemplate>(
      this.apiConstants.apiServerUrl +
        this.apiConstants.api_prefix +
        this.apiConstants.wspdf +
        this.apiConstants.wspdf__template +
        templateId +
        this.apiConstants.wspdf__duplicate,
      {}
    )
  }

  updateTemplate(template: WspdfTemplate) {
    return this.http.put<WspdfTemplate>(
      this.apiConstants.apiServerUrl +
        this.apiConstants.api_prefix +
        this.apiConstants.wspdf +
        this.apiConstants.wspdf__template +
        template.wsPdfTemplateId,
      template
    )
  }

  deleteTemplate(templateId: number) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8')
    return this.http.delete<any>(
      this.apiConstants.apiServerUrl +
        this.apiConstants.api_prefix +
        this.apiConstants.wspdf +
        this.apiConstants.wspdf__template +
        templateId,
      { headers, responseType: 'text' as 'json' }
    )
  }

  createPdfFromTemplate(templateId: number, dataJsonString: string, isPreview: boolean) {
    const params = new HttpParams().set('isPreview', isPreview)
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8')
    return this.http.post<any>(
      this.apiConstants.apiServerUrl +
        this.apiConstants.api_prefix +
        this.apiConstants.wspdf +
        this.apiConstants.wspdf__pdf +
        templateId,
      dataJsonString,
      { params, headers, responseType: 'blob' as 'json' }
    )
  }

  uploadFileToServer(file: File, redirectAttributes: RequestRedirect) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8')
    const formData = new FormData()
    formData.append('file', file)
    return this.http.post<any>(
      this.apiConstants.apiServerUrl + this.apiConstants.api_prefix + this.apiConstants.upload,
      formData,
      { headers, responseType: 'text' as 'json' }
    )
  }

  serveFile(fileName: string) {
    return this.http.get<Object>(
      this.apiConstants.apiServerUrl +
        this.apiConstants.api_prefix +
        this.apiConstants.upload +
        this.apiConstants.upload__files +
        fileName
    )
  }

  historyOfTemplate(templateId: number): Observable<WspdfTemplateHistory[]> {
    return this.http.get<WspdfTemplateHistory[]>(
      this.apiConstants.apiServerUrl +
        this.apiConstants.api_prefix +
        this.apiConstants.wspdf +
        this.apiConstants.wspdf__template +
        `${templateId}/` +
        this.apiConstants.wspdf__history
    )
  }
}
