import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core'

/**
 * This component provides a dialog for speech recognition.
 * The component is only used internally as a template by the WsSpeechRecognitionService.
 */
@Component({
  selector: 'ws-speech-recognition-dialog',
  templateUrl: './speech-recognition-dialog.component.html',
  styleUrls: ['./speech-recognition-dialog.component.scss']
})
export class SpeechRecognitionDialogComponent {
  /**
   * The final speech result to display in the dialog.
   */
  speechResult = ''
  /**
   * The current speech result to display in the dialog, including interim results.
   */
  currentSpeechResult = ''

  /**
   * The template for the dialog content.
   */
  @ViewChild('speechRecognitionDialogContent', { static: true }) speechRecognitionDialogContent!: TemplateRef<Element>

  /**
   * The constructor for the speech recognition dialog component.
   * @param cdRef ChangeDetectorRef
   */
  constructor(public cdRef: ChangeDetectorRef) {}
}
