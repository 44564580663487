import { Component, OnInit } from '@angular/core'
import { ComponentType } from '../wspdf-editor/wspdf-editor.component'
import { WspdfElementComponent } from '../wspdf-element/wspdf-element.component'
import { CdkDragMove } from '@angular/cdk/drag-drop'

@Component({
  selector: 'app-wspdf-image',
  templateUrl: './wspdf-image.component.html',
  styleUrls: ['../wspdf-element/wspdf-element.component.scss', './wspdf-image.component.scss']
})
export class WspdfImageComponent extends WspdfElementComponent implements OnInit {
  public override type: ComponentType = ComponentType.IMAGE
  public imageSource = 'https://www.waldhart.at/fileadmin/user_upload/illustrationen/bergegondeln.png'
  public imageSourcePlaceholder = ''
  public override keepAspectRatio = true

  override ngOnInit(): void {
    super.ngOnInit()
    this.el.nativeElement.classList.add('wspdf-image')
  }

  override resizeDragMoved(dragHandlePosition: 'right' | 'bottom' | 'corner', dragMove: CdkDragMove) {
    this.keepAspectRatio = !dragMove.event.shiftKey
    super.resizeDragMoved(dragHandlePosition, dragMove)
  }

  setImage(imageSource: string, testData: { [key: string]: any } = {}): void {
    const placeHolderRegex = /^\${(.*)}$/
    if (placeHolderRegex.test(imageSource)) {
      const matchingGroups = imageSource.match(placeHolderRegex)
      const placeHolderIdentifier = matchingGroups ? matchingGroups[1] : ''
      if (placeHolderIdentifier) {
        this.imageSourcePlaceholder = testData[placeHolderIdentifier]
      }
    }

    this.imageSource = imageSource
  }

  getImage(): string {
    return this.imageSource
  }

  /**
   * Set Height of each Native Element depending on width
   * (default width is specified in css file)
   */
  setNativeElementHeight() {
    // image container needs always height and width to be set
    if (this.el.nativeElement.offsetHeight > 0) {
      this.el.nativeElement.style.height = this.el.nativeElement.offsetHeight + 'px' || '200px'
    }
    this.cdr.detectChanges()
  }

  getImageSourceLink() {
    if (this.imageSourcePlaceholder) {
      return this.imageSourcePlaceholder
    }
    return this.imageSource
  }
}
