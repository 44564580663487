<div class="mat-mdc-form-field w-full py-2" *ngIf="ngControl">
  <mat-checkbox class="px-2 -my-2" [formControl]="formControl" (ngModelChange)="onFieldChange($event)">
    {{ label }}
  </mat-checkbox>
  <div class="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align" *ngIf="hasErrors">
    <div class="mat-mdc-form-field-error-wrapper">
      <mat-error>
        {{ validationErrorMessage }}
      </mat-error>
    </div>
  </div>
</div>
