/*
 * Public API Surface of core-library
 */

/*
 * calendar module
 */

export * from './lib/calendar/ws-calendar.module'

export * from './lib/calendar/calendar/calendar.component'
export * from './lib/calendar/calendar-dialog/calendar-dialog.component'
export * from './lib/calendar/calendar-segmented-dates/calendar-segmented-dates.component'
export * from './lib/calendar/scheduler/scheduler.component'

export * from './types/calendar/highlighted-date'
export * from './types/calendar/highlighted-dates'
export * from './types/calendar/highlighted-display-date'
export * from './types/calendar/highlighted-date'
export * from './types/calendar/segmented-date'
export * from './types/calendar/resource'
export * from './types/calendar/scheduled-event'

/*
 * notifications module
 */

export * from './lib/notifications/ws-notifications.module'

export * from './lib/notifications/dialog/dialog.component'
export * from './lib/notifications/dialog-container/dialog-container.component'
export * from './lib/notifications/snackbar/snackbar.component'
export * from './lib/notifications/notification-dialog/notification-dialog.component'

export * from './lib/notifications/ws-dialog-service'
export * from './lib/notifications/ws-notifications.service'

export * from './directives/dialog.directive'

export * from './types/notifications/notification-data'
export * from './types/notifications/notification-settings'

/*
 * grid module
 */

export * from './lib/grid/ws-grid.module'

export * from './lib/grid/grid/grid.component'

export * from './types/grid/grid-item'

/*
 * chips module
 */

export * from './lib/chips/ws-chips.module'

export * from './lib/chips/chips/chips.component'
export * from './lib/chips/chip/chip.component'

export * from './types/chip'

/*
 * forms module
 */

export * from './lib/forms/ws-forms.module'

export * from './lib/forms/input/input.component'
export * from './lib/forms/simple-input/simple-input.component'
export * from './lib/forms/textarea/textarea.component'
export * from './lib/forms/toggle/toggle.component'
export * from './lib/forms/select/select.component'
export * from './lib/forms/checkbox/checkbox.component'
export * from './lib/forms/basic-model/basic-model.component'
export * from './lib/forms/slider/slider.component'
export * from './lib/forms/button-toggle/button-toggle.component'
export * from './lib/forms/simple-upload/simple-upload.component'
export * from './lib/forms/advanced-file-upload/advanced-file-upload.component'
export * from './lib/forms/file-preview/file-preview.component'
export * from './lib/forms/profile-picture-upload/profile-picture-upload.component'
export * from './lib/forms/datepicker/datepicker.component'
export * from './lib/forms/image-cropper/image-cropper.component'
export * from './lib/forms/chips-select/chips-select.component'
export * from './lib/forms/chips-input/chips-input.component'
export * from './lib/forms/address-input/address-input.component'
export * from './lib/forms/color-picker/color-picker.component'
export * from './lib/forms/text-editor/text-editor.component'
export * from './lib/forms/translation-tabs/translation-tabs.component'
export * from './lib/forms/timepicker/timepicker.component'
export * from './lib/forms/datetime-picker/datetime-picker.component'
export * from './lib/forms/text-editor-new/text-editor-new.component'
export * from './lib/forms/text-editor-new/custom-floating-menu/custom-floating-menu.component'
export * from './lib/forms/text-editor-new/custom-editor-menu/custom-editor-menu.component'

export * from './lib/forms/ws-google-places.service'
export * from './lib/forms/ws-forms.service'

export * from './types/forms/ws-form-field'
export * from './types/forms/select-option'
export * from './types/forms/field-optionals'
export * from './types/forms/translations'
export * from './types/forms/google-places-address'
export * from './types/forms/speech-recognition-result'
export * from './types/forms/ws-validators'

/*
 * module-view module
 */

export * from './lib/module-view/ws-module-view.module'

export * from './lib/module-view/module-view.service'

export * from './lib/module-view/module-view/module-view.component'
export * from './lib/module-view/module-view-list-view/module-view-list-view.component'
export * from './lib/module-view/advanced-search/advanced-search.component'
export * from './lib/module-view/module-view-sorting/module-view-sorting.component'

export * from './types/module-view/icon-button'
export * from './types/module-view/sidebar'
export * from './types/module-view/module-view-event'
export * from './types/module-view/module-view-search-event'
export * from './types/module-view/abstract-platform-service'
export * from './types/module-view/selection-action-click-event'
export * from './types/module-view/table-tool-tip'
export * from './types/module-view/table-column'
export * from './types/module-view/search-field'

/*
 *  scroll-menu module
 */

export * from './lib/scroll-menu/ws-scroll-menu.module'
export * from './lib/scroll-menu/scroll-menu-view/scroll-menu-view.component'
export * from './lib/scroll-menu/scroll-menu-tab/scroll-menu-tab.component'

/*
 * stepper
 */

export * from './lib/stepper/ws-stepper.module'
export * from './lib/stepper/stepper/stepper.component'
export * from './lib/stepper/step/step.component'

/*
 *  pipes module
 */
export * from './lib/pipes/ws-pipes.module'

export * from './lib/pipes/locale-number.pipe'
export * from './lib/pipes/locale-currency.pipe'
export * from './lib/pipes/localized.pipe'
export * from './lib/pipes/object-value.pipe'
export * from './lib/pipes/array-includes.pipe'
export * from './lib/pipes/image-file-to-object-url.pipe'
export * from './lib/pipes/selected-options.pipe'
export * from './lib/pipes/function-to-pipe.pipe'
export * from './lib/pipes/array-length.pipe'

/*
 *  ws button component
 */
export * from './lib/ws-button/ws-button.component'

/*
 *  ws icon component
 */
export * from './lib/ws-icon/ws-icon.component'

/*
 *  pdf template service
 */
export * from './lib/pdf/pdf.service'
export * from './types/pdf/pdf-template'

/*
 *  ws-date class
 */
export * from './types/calendar/ws-date'

/*
 *  ws-time class
 */
export * from './types/calendar/ws-time'

/*
 *  ws table component
 */
export * from './lib/ws-table/ws-table.component'

/*
 *  ws sidebar component
 */
export * from './lib/ws-sidebar/ws-sidebar.component'

import translationsDe from './translations/de.json'
import translationsEn from './translations/en.json'

const wsCoreLibraryTranslations = {
  de: translationsDe,
  en: translationsEn
}

export { wsCoreLibraryTranslations }
