import { NgModule } from '@angular/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { CommonModule, NgClass, NgForOf, NgIf, NgTemplateOutlet, TitleCasePipe } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatFormFieldModule } from '@angular/material/form-field'
import { TranslateModule } from '@ngx-translate/core'
import { DialogDirective } from '../../directives/dialog.directive'
import { SnackbarComponent } from './snackbar/snackbar.component'
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component'
import { DialogComponent } from './dialog/dialog.component'
import { DialogContainerComponent } from './dialog-container/dialog-container.component'

@NgModule({
  declarations: [
    SnackbarComponent,
    NotificationDialogComponent,
    DialogComponent,
    DialogContainerComponent,
    DialogDirective
  ],
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    CommonModule,
    MatFormFieldModule,
    NgIf,
    NgTemplateOutlet,
    NgForOf,
    TranslateModule.forRoot({
      defaultLanguage: 'en'
    }),
    TitleCasePipe,
    NgClass
  ],
  exports: [SnackbarComponent, NotificationDialogComponent, DialogComponent, DialogContainerComponent, DialogDirective]
})
export class WsNotificationsModule {}
