<div
  style="width: 100%; height: 100%"
  class="cursor-move"
  (cdkDragStarted)="repositionEditorElementComponentStarted($event)"
  [cdkDragBoundary]="elementBoundary"
  (cdkDragEnded)="repositionEditorElementComponent($event)"
  cdkDrag
>
  <span
    *ngIf="inFocus"
    class="dragHandle corner"
    cdkDrag
    (cdkDragStarted)="resizeDragStarted($event)"
    (cdkDragEnded)="resizeDragEnded($event)"
    (cdkDragMoved)="resizeDragMoved('corner', $event)"
  ></span>
  <img
    (load)="setNativeElementHeight()"
    class="wspdf-element__image wspdf-editor-only"
    style="width: 100%; height: 100%; object-fit: contain"
    [src]="getImageSourceLink()"
    [alt]="getImageSourceLink()"
  />
  <div
    class="wspdf-element__image wspdf-editor-hidden"
    [ngStyle]="{'background-image': 'url(' + imageSource + ')',}"
    style="
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    "
  ></div>
</div>
