import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges
} from '@angular/core'
import { FieldComponent } from '../field/field.component'
import { Subscription } from 'rxjs'
import { SelectOption } from '../../../types/forms/select-option'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { WsSpeechRecognitionService } from '../ws-speech-recognition.service'
import { AbstractPlatformService } from '../../../types/module-view/abstract-platform-service'
import { WsNotificationsService } from '../../notifications/ws-notifications.service'

@Component({
  selector: 'ws-form-options-field',
  templateUrl: './options-field.component.html',
  styleUrls: ['./options-field.component.scss']
})
export class OptionsFieldComponent
  extends FieldComponent
  implements ControlValueAccessor, OnDestroy, OnInit, OnChanges
{
  @Input() public options: SelectOption[] = []
  @Input() public filter = false

  onLangChange?: Subscription
  onDefaultLangChange?: Subscription

  constructor(
    @Self()
    @Optional()
    public override ngControl: NgControl,
    public override translate: TranslateService,
    @Inject('PlatformService') public override platformService: AbstractPlatformService,
    public override speechRecognitionService: WsSpeechRecognitionService,
    public override notificationService: WsNotificationsService,
    public override cdRef: ChangeDetectorRef
  ) {
    super(ngControl, translate, platformService, speechRecognitionService, notificationService, cdRef)

    this.onLangChange = this.translate.onLangChange.subscribe(this.handleTranslationChanges)
    this.onDefaultLangChange = this.translate.onDefaultLangChange.subscribe(this.handleTranslationChanges)
  }

  ngOnInit() {
    this.handleTranslationChanges()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      this.handleTranslationChanges()
    }
  }

  handleTranslationChanges = () => {
    this.options = this.options.map((option: SelectOption) => {
      option.label = this.translateOptionLabel(option)
      return option
    })
  }

  private translateOptionLabel(option: SelectOption): string {
    const optionLabel: string = option.label
    // if no translations exist always return current option label
    if (!option.translations) return optionLabel

    // first checks if translations include current selected language or fall back to english translation
    const fallbackLang = 'en'
    const currentLangLabel = option.translations[this.translate.currentLang] || option.translations[fallbackLang]
    if (currentLangLabel) return currentLangLabel

    // if neither a translation for the current language nor for the fallback language exists > iterate to find any translation
    let anyTranslationLabel = ''
    for (const key in option.translations) {
      if (option.translations[key]) {
        anyTranslationLabel = option.translations[key]
        break
      }
    }
    if (anyTranslationLabel) return anyTranslationLabel

    // if translations exist, but they are all empty > return current option label again
    return optionLabel
  }

  generateGrammarString() {
    if (this.options.length > 0) {
      let grammarString = '#JSGF V1.0; grammar options; public <option> ='

      for (const option of this.options) {
        grammarString += ' ' + option.label + ' |'
      }

      return grammarString.slice(0, -1) + ';'
    } else {
      return ''
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy()
    this.onLangChange?.unsubscribe()
    this.onDefaultLangChange?.unsubscribe()
    this.speechRecognitionSubject?.unsubscribe()
  }
}
