import { OnDestroy, Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Translations } from '../../types/forms/translations'
import { Subscription } from 'rxjs'

@Pipe({
  name: 'localized',
  pure: false // required to update the value when the promise is resolved
})
export class LocalizedPipe implements PipeTransform, OnDestroy {
  value: string = ''
  onLangChange?: Subscription

  constructor(private translate: TranslateService) {}

  public transform(value: Translations | string | number | undefined | null): string {
    this.updateValueIfNecessary(value)

    // subscribe to onLangChange event, in case the language changes
    if (!this.onLangChange) {
      this.onLangChange = this.translate.onLangChange.subscribe(() => {
        this.updateValueIfNecessary(value)
      })
    }

    return this.value
  }

  updateValueIfNecessary(value: Translations | string | number | undefined | null) {
    this.value = this.getStringFromInputValue(value)
  }

  getStringFromInputValue(value: Translations | string | number | undefined | null): string {
    if (!value) {
      return ''
    }
    if (typeof value === 'string') {
      return value
    }
    if (typeof value === 'number') {
      return value.toString()
    }

    // first checks if translations include current selected language or fall back to english translation
    const fallbackLang = 'en'
    const currentLangLabel = value[this.translate.currentLang] || value[fallbackLang]
    if (currentLangLabel) return currentLangLabel

    // if neither a translation for the current language nor for the fallback language exists > iterate to find any translation
    for (const key in value) {
      if (value[key]) {
        if (typeof value[key] === 'string') {
          return value[key]
        }
        if (typeof value[key] === 'number') {
          return value[key].toString()
        }
        return value[key]
      }
    }

    return ''
  }

  ngOnDestroy() {
    this.onLangChange?.unsubscribe()
  }
}
