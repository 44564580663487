<mat-dialog-content>
  <div class="flex flex-col items-center">
    <span mat-dialog-title class="flex">{{ 'template.datasetChoiceDialog' | translate }}</span>
    <ws-form-select
      [options]="this.data"
      [(ngModel)]="this.selectedDataSet"
      [filter]="false"
      [style]="{ width: '20vw' }"
    ></ws-form-select>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex justify-between">
  <button
    mat-icon-button
    class="mat-button"
    (click)="save()"
    matTooltip="{{ 'template.delete' | translate }}"
    matTooltipPosition="below"
  >
    <mat-icon>check</mat-icon>
  </button>
  <button
    mat-icon-button
    class="mat-button"
    (click)="close()"
    matTooltip="{{ 'template.close' | translate }}"
    matTooltipPosition="below"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
