import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core'
import { ComponentType } from '../wspdf-editor/wspdf-editor.component'
import { WspdfElementComponent } from '../wspdf-element/wspdf-element.component'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-wspdf-qr-code',
  templateUrl: './wspdf-qr-code.component.html',
  styleUrls: ['../wspdf-element/wspdf-element.component.scss', './wspdf-qr-code.component.scss']
})
export class WspdfQrCodeComponent extends WspdfElementComponent implements OnInit, OnDestroy {
  public override type: ComponentType = ComponentType.QRCODE
  public qrData = 'https://www.waldhart.at/'
  public widthQrCode = 100
  public override keepAspectRatio = true
  private qrCodeResizeSubscription: Subscription

  constructor(
    public override el: ElementRef,
    public override viewContainerRef: ViewContainerRef,
    public override cdr: ChangeDetectorRef
  ) {
    super(el, viewContainerRef, cdr)
    this.qrCodeResizeSubscription = this.elementChangedEvent.subscribe(({ data }) => {
      this.widthQrCode = data.width
    })
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.el.nativeElement.classList.add('wspdf-qr-code')
  }

  setQrData(qrData: string, widthQrCode?: number): void {
    this.qrData = qrData
    this.widthQrCode = widthQrCode ? widthQrCode : this.widthQrCode
  }

  getQrData(): string {
    return this.qrData
  }

  override ngOnDestroy() {
    super.ngOnDestroy()
    this.qrCodeResizeSubscription.unsubscribe()
  }
}
