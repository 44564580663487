<ng-template #speechRecognitionDialogContent>
  <div class="mx-5 md:mx-6 mt-5 md:mt-6">
    <div class="ws-input_speech-recognition w-24 aspect-square ml-auto mr-auto relative flex">
      <div class="flex justify-center items-center rounded-full bg-primary w-80 h-full z-10">
        <mat-icon class="text-2xl scale-150 text-white" matSuffix>mic</mat-icon>
      </div>
      <div
        class="ws-input_speech-pulse w-100 aspect-square ml-auto mr-auto rounded-full flex justify-center items-center bg-primary absolute top-0 left-0 bottom-0 z-0 animate-pulse"
      ></div>
    </div>

    <div class="mt-5 md:mt-6">{{ speechResult }} {{ currentSpeechResult }}</div>
  </div>
</ng-template>
