import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { SelectOption } from '@ws-core/core-library'

export const fontOptions: SelectOption[] = [
  { label: 'Arial', value: 'Arial, sans-serif' },
  { label: 'Verdana', value: 'Verdana, sans-serif' },
  { label: 'Helvetica', value: 'Helvetica, sans-serif' },
  { label: 'Times New Roman', value: 'Times New Roman, sans-serif' },
  { label: 'Georgia', value: 'Georgia, sans-serif' },
  { label: 'Garamond', value: 'Garamond, sans-serif' },
  { label: 'Saria-Condensed Light', value: 'SariaCondensedLight, sans-serif' },
  { label: 'Saria-Condensed Bold', value: 'SariaCondensedBold, sans-serif' },
  { label: 'Raleway', value: 'Raleway, sans-serif' }
]

@Component({
  selector: 'app-pdf-page-settings',
  templateUrl: './pdf-page-settings.component.html',
  styleUrls: ['./pdf-page-settings.component.scss']
})
export class PdfPageSettingsComponent {
  fontOptions = fontOptions

  pageLayoutForm = new FormGroup({
    header: new FormControl(false, []),
    headerHeight: new FormControl(0, [Validators.min(0)]),
    footer: new FormControl(false, []),
    footerHeight: new FormControl(0, [Validators.min(0)]),
    marginTop: new FormControl(2, [Validators.required, Validators.min(0)]),
    marginRight: new FormControl(2, [Validators.required, Validators.min(0)]),
    marginBottom: new FormControl(2, [Validators.required, Validators.min(0)]),
    marginLeft: new FormControl(2, [Validators.required, Validators.min(0)]),
    backgroundImage: new FormControl('', [Validators.pattern(/^(https:\/\/.*|\$\{[^{}]*})$/)]),
    backgroundColor: new FormControl('#FFFFFF', [])
  })

  private imageSourcePlaceholder = ''
  private imageSourceUrl = ''

  setBackgroundImage(imageSource: string, testData: { [key: string]: any } = {}): void {
    const placeHolderRegex = /^\${(.*)}$/
    if (placeHolderRegex.test(imageSource)) {
      const matchingGroups = imageSource.match(placeHolderRegex)
      const placeHolderIdentifier = matchingGroups ? matchingGroups[1] : ''
      if (placeHolderIdentifier) {
        this.imageSourcePlaceholder = imageSource
        this.imageSourceUrl = testData[placeHolderIdentifier]
        return
      }
    }
    this.imageSourcePlaceholder = ''
    this.imageSourceUrl = imageSource
  }

  getBackgroundImage(): string {
    return this.imageSourceUrl
  }

  getBackgroundImagePlaceholder(): string {
    if (this.imageSourcePlaceholder === '' && this.imageSourceUrl !== 'none') {
      return this.imageSourceUrl
    }
    return this.imageSourcePlaceholder
  }
}
