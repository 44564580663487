<div
  class="resizeable-text-element"
  (cdkDragStarted)="repositionEditorElementComponentStarted($event)"
  [cdkDragBoundary]="elementBoundary"
  (cdkDragEnded)="repositionEditorElementComponent($event)"
  cdkDrag
  [class.cursor-move]="!doubleClicked"
  (dblclick)="doubleClicked = true"
  [cdkDragDisabled]="doubleClicked"
  [title]="labelValue"
>
  <span
    *ngIf="inFocus && !doubleClicked"
    class="dragHandle corner"
    cdkDrag
    (cdkDragStarted)="resizeDragStarted($event)"
    (cdkDragEnded)="resizeDragEnded($event)"
    (cdkDragMoved)="resizeDragMoved('corner', $event)"
  ></span>
  <span
    cdkDragLockAxis="x"
    *ngIf="inFocus && !doubleClicked"
    class="dragHandle right"
    cdkDrag
    (cdkDragStarted)="resizeDragStarted($event)"
    (cdkDragEnded)="resizeDragEnded($event)"
    (cdkDragMoved)="resizeDragMoved('right', $event)"
  ></span>
  <span
    cdkDragLockAxis="y"
    *ngIf="inFocus && !doubleClicked"
    class="dragHandle bottom"
    cdkDrag
    (cdkDragStarted)="resizeDragStarted($event)"
    (cdkDragEnded)="resizeDragEnded($event)"
    (cdkDragMoved)="resizeDragMoved('bottom', $event)"
  ></span>
  <span
    class="wspdf-element__text"
    style="white-space: pre-line"
    [style.width.%]="100"
    [contentEditable]="doubleClicked"
    [innerHTML]="labelValue"
  ></span>
</div>
