import { Component, OnInit } from '@angular/core'
import { ComponentType } from '../wspdf-editor/wspdf-editor.component'
import { WspdfElementComponent } from '../wspdf-element/wspdf-element.component'

@Component({
  selector: 'app-wspdf-static-label',
  templateUrl: './wspdf-static-label.component.html',
  styleUrls: ['../wspdf-element/wspdf-element.component.scss', './wspdf-static-label.component.scss']
})
export class WspdfStaticLabelComponent extends WspdfElementComponent implements OnInit {
  public labelValue = 'new text'
  public override type: ComponentType = ComponentType.STATICLABEL

  override ngOnInit(): void {
    super.ngOnInit()
    this.el.nativeElement.classList.add('wspdf-static-label')
    this.el.nativeElement.style.position = 'absolute'
  }

  setLabel(newLabel: string): void {
    this.labelValue = newLabel
  }

  getLabel(): string {
    return this.el.nativeElement.innerText
  }
}
