import { Component, Input } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'
import { FieldComponent } from '../field/field.component'

@Component({
  selector: 'ws-form-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss', '../field/field.component.scss']
})
export class TextareaComponent extends FieldComponent implements ControlValueAccessor {
  @Input() public placeholder = ''
  @Input() public rows = 5
  @Input() public autosize = false

  public override value = ''

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public override onChange(newValue: string) {}

  public override registerOnChange(fn: (value: string | number) => void): void {
    this.onChange = fn
  }

  public override writeValue(value: string): void {
    this.value = value
  }

  public override onFieldChange(input: string) {
    super.onFieldChange(input)
  }
}
