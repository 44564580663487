import { inject, Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { PdfService } from '../pdf.service'

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  private readonly pdfService: PdfService = inject(PdfService)

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith('/api')) {
      // uses temporary api key that has been set in session storage from query param
      const tempApiKey = this.pdfService.getTempApiKey()
      const userId = this.pdfService.getUserId()

      request = request.clone({
        setHeaders: {
          'ws-pdf-api-key': tempApiKey,
          'ws-pdf-user': userId
        }
      })
    }

    return next.handle(request)
  }
}
