import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'

@Component({
  selector: 'ws-icon',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './ws-icon.component.html',
  styleUrl: './ws-icon.component.scss'
})
export class WsIconComponent {
  @Input() public size: number | string = 24

  public get wsIconStyle() {
    return {
      'font-size': typeof this.size === 'number' ? `${this.size}px` : this.size
    }
  }
}
