<mat-form-field [floatLabel]="floatLabel" class="w-full" [class.hide-subscript]="!hint && !hasErrors" *ngIf="ngControl">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select
    #selectElement
    [placeholder]="placeholder"
    [formControl]="formControl"
    (blur)="handleInputBlur()"
    (valueChange)="handleValueChange($event)"
    [multiple]="multiple"
    [compareWith]="compareWithFunction ? compareWithFunction : compareObjects"
    [panelWidth]="panelWidth"
    (openedChange)="onSelectOpenedChange($event); toggleSelectSpeechRecognition(false)"
    (selectionChange)="toggleSelectSpeechRecognition(false)"
  >
    <mat-select-trigger>
      <div class="flex mr-2 justify-between">
        <div class="overflow-ellipsis overflow-hidden">
          @if (multiple) {
            @for (option of options; track option.value) {
              @if (formControl.value && formControl.value.includes(option.value)) {
                {{ option.label | translate }},
              }
            }
          } @else {
            @for (option of options; track option.value) {
              @if (formControl.value === option.value) {
                {{ option.label | translate }}
              }
            }
          }
        </div>

        @if (clearable && formControl.value) {
          <mat-icon class="cursor-pointer" (click)="handleClearIconClick($event)">close</mat-icon>
        }
      </div>
    </mat-select-trigger>

    <div class="ws-select_filter-input">
      <input
        *ngIf="filter"
        #filterInput
        placeholder="{{ 'ws.forms.select.filter' | translate }}"
        type="text"
        (ngModelChange)="setFilteredOptions($event)"
        [(ngModel)]="search"
        class="w-full border-b-2 p-3 outline-0"
      />
      <mat-icon
        class="ws-select_speech-recognition-icon ws-speech-recognition-icon cursor-pointer"
        [ngClass]="{ recording: listening }"
        *ngIf="speechRecognition && speechRecognitionIcon && speechRecognitionService.speechRecognitionSupported"
        (click)="toggleSpeechRecognition()"
        (touchstart)="touchStartSpeechRecognition()"
        (touchend)="touchStopSpeechRecognition()"
        >{{ speechRecognitionIcon }}</mat-icon
      >
    </div>

    <mat-option
      [class]="matOptionClass"
      *ngFor="let option of options"
      [disabled]="option.disabled"
      [class.hidden]="matchesFilter(option)"
      [value]="option.value"
    >
      {{ option.label | translate }}
    </mat-option>
    <mat-option disabled *ngIf="filteredOptions.length === 0">{{ 'ws.forms.select.noResults' | translate }}</mat-option>
  </mat-select>

  <mat-error *ngIf="hasErrors">
    {{ validationErrorMessage }}
  </mat-error>

  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
