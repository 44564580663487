import { Injectable } from '@angular/core'
import { WspdfTemplate } from '../types/wspdf-template'

@Injectable({
  providedIn: 'root'
})
export class WsPdfUndoRedoService {
  private actionStack: WspdfTemplate[] = []
  private stackPointer = -1

  public undoAvailable(): boolean {
    return this.stackPointer > 0
  }
  public redoAvailable(): boolean {
    return this.stackPointer < this.actionStack.length - 1
  }

  public resetStackPointer() {
    this.stackPointer = -1
  }

  public clearStack() {
    this.actionStack = []
    this.resetStackPointer()
  }

  isCurrentActionSameAsLast(current: WspdfTemplate, last: WspdfTemplate) {
    return last.content === current.content && last.styles === current.styles && last.testData === current.testData
  }

  public captureAction(action: WspdfTemplate): void {
    // If the action is the same as the last action, don't add it to the stack
    if (this.stackPointer >= 0 && this.stackPointer < this.actionStack.length) {
      const last = this.actionStack[this.stackPointer]
      if (this.isCurrentActionSameAsLast(action, last)) {
        return
      }
    }
    // If the stack pointer is not at the end of the stack, remove all actions after the stack pointer
    if (this.stackPointer !== -1 && this.stackPointer < this.actionStack.length - 1) {
      this.actionStack = this.actionStack.slice(0, this.stackPointer + 1)
    }

    this.actionStack.push(action)
    this.stackPointer = this.actionStack.length - 1
  }

  public undo(): WspdfTemplate | null {
    if (this.stackPointer > 0) {
      this.stackPointer--
      return this.actionStack[this.stackPointer]
    }
    return null
  }

  public redo(): WspdfTemplate | null {
    if (this.stackPointer < this.actionStack.length - 1) {
      this.stackPointer++
      return this.actionStack[this.stackPointer]
    }
    return null
  }
}
