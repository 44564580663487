export abstract class AbstractPlatformService {
  abstract breakpoints: { sm: number; md: number; lg: number; xl: number; '2xl': number }
  abstract width: number
  abstract height: number

  abstract calculateWindowSizes: () => void
  abstract get isMobile(): boolean
  abstract get hasTouch(): boolean
  abstract get size(): 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}
