<mat-form-field class="w-full" [class.hide-subscript]="!hasValidators && !hint" *ngIf="ngControl">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <textarea
    [cdkTextareaAutosize]="autosize"
    [rows]="rows"
    matNativeControl
    matInput
    (blur)="handleInputBlur()"
    [formControl]="formControl"
    (ngModelChange)="onFieldChange($event)"
  ></textarea>

  <mat-error *ngIf="hasErrors">
    {{ validationErrorMessage }}
  </mat-error>

  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
