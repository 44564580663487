import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'imageFileToObjectUrl',
  standalone: true
})
export class ImageFileToObjectUrlPipe implements PipeTransform {
  transform(file: File): string {
    return URL.createObjectURL(file)
  }
}
