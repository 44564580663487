import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class ApiConstants {
  public readonly apiServerUrl: string = '/'
  public readonly api_prefix: string = 'api/'
  public readonly wspdf: string = 'wspdf/'
  public readonly wspdf__pdf: string = 'pdf/'
  public readonly wspdf__template: string = 'template/'
  public readonly wspdf__duplicate: string = '/duplicate/'
  public readonly wspdf__history: string = 'history/'
  public readonly upload: string = 'upload/'
  public readonly upload__files: string = 'files/'
}
