export class WspdfTemplate {
  // constructor({
  //   wsPdfTemplateId = 0,
  //   name = '',
  //   content = '',
  //   styles = '',
  //   testData = ''
  // }: {
  //   wsPdfTemplateId?: number
  //   name?: string
  //   content?: string
  //   styles?: string
  //   testData?: string
  // }) {
  //   this.wsPdfTemplateId = wsPdfTemplateId
  //   this.name = name
  //   this.content = content
  //   this.styles = styles
  //   this.testData = testData
  // }

  wsPdfTemplateId = 0
  name = ''
  content = ''
  styles = ''
  testData = ''
  owner = -1
}
