<div
  style="width: 100%; height: 100%"
  [cdkDragBoundary]="elementBoundary"
  (cdkDragStarted)="repositionEditorElementComponentStarted($event)"
  (cdkDragEnded)="repositionEditorElementComponent($event)"
  cdkDrag
  [class.cursor-move]="!doubleClicked && !columnIsResizing"
  [cdkDragDisabled]="doubleClicked || columnIsResizing"
>
  <span
    *ngIf="inFocus && !doubleClicked"
    class="dragHandle corner"
    cdkDrag
    [cdkDragDisabled]="columnIsResizing"
    (cdkDragStarted)="resizeDragStarted($event)"
    (cdkDragEnded)="resizeDragEnded($event)"
    (cdkDragMoved)="resizeDragMoved('corner', $event)"
  ></span>
  <span
    cdkDragLockAxis="x"
    *ngIf="inFocus && !doubleClicked"
    class="dragHandle right"
    cdkDrag
    [cdkDragDisabled]="columnIsResizing"
    (cdkDragStarted)="resizeDragStarted($event)"
    (cdkDragEnded)="resizeDragEnded($event)"
    (cdkDragMoved)="resizeDragMoved('right', $event)"
  ></span>
  <span
    cdkDragLockAxis="y"
    *ngIf="inFocus && !doubleClicked"
    class="dragHandle bottom"
    cdkDrag
    [cdkDragDisabled]="columnIsResizing"
    (cdkDragStarted)="resizeDragStarted($event)"
    (cdkDragEnded)="resizeDragEnded($event)"
    (cdkDragMoved)="resizeDragMoved('bottom', $event)"
  ></span>
  <table #wspdfDatatable class="wspdf-element__table" [id]="dataSetName" style="width: 100%">
    <colgroup>
      <col
        *ngFor="let column of dataSetColumns; let headerIndex = index"
        #resizableElement
        [ngStyle]="dataSetColumnStyles[headerIndex]"
      />
    </colgroup>
    <thead id="{{ dataSetName }}-thead" style="border-bottom: 2px solid #000; font-weight: bold">
      <tr>
        <td *ngFor="let column of dataSetColumns; let headerIndex = index">
          <span class="wspdf-editor-only">
            {{ dataSetHeaders[headerIndex] || column }}
          </span>
          <span class="wspdf-editor-hidden">
            {{ '${' + dataSetName + '.columns[' + headerIndex + ']}' }}
          </span>
          <div
            *ngIf="headerIndex !== dataSetColumns.length - 1"
            [ngStyle]="{ 'border-right': inFocus && !doubleClicked ? '2px solid #00a9e2' : 'none' }"
            (mousedown)="onMouseDown($event, headerIndex)"
            class="wspdf-editor-only column-adjustment"
          ></div>
        </td>
      </tr>
    </thead>
    <tbody id="{{ dataSetName }}-tbody">
      <tr *ngFor="let object of dataSetObjects">
        <td *ngFor="let column of dataSetColumns; let index = index">
          {{ object[column] }}
          <div
            *ngIf="index !== dataSetColumns.length - 1"
            [ngStyle]="{ 'border-right': inFocus && !doubleClicked ? '2px solid #00a9e2' : 'none' }"
            (mousedown)="onMouseDown($event, index)"
            class="wspdf-editor-only column-adjustment"
          ></div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
