import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { debounceTime, distinctUntilChanged, first, Subscription } from 'rxjs'
import { WspdfElementComponent } from '../wspdf-element/wspdf-element.component'
import { WspdfLabelComponent } from '../wspdf-label/wspdf-label.component'
import { WspdfStaticLabelComponent } from '../wspdf-static-label/wspdf-static-label.component'
import { WspdfTemplate } from '../../types/wspdf-template'
import { TranslateService } from '@ngx-translate/core'
import { SelectOption, WsDialogService } from '@ws-core/core-library'
import { PdfService } from '../pdf.service'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { WspdfImageComponent } from '../wspdf-image/wspdf-image.component'
import { WspdfQrCodeComponent } from '../wspdf-qr-code/wspdf-qr-code.component'
import { PdfEditTestDataDialogComponent } from '../pdf-edit-test-data-dialog/pdf-edit-test-data-dialog.component'
import { WspdfDatasetComponent } from '../wspdf-dataset/wspdf-dataset.component'
import { PdfDatasetChoiceDialogComponent } from '../pdf-dataset-choice-dialog/pdf-dataset-choice-dialog.component'
import { fontOptions, PdfPageSettingsComponent } from '../pdf-page-settings/pdf-page-settings.component'
import { WspdfTemplateHistory } from '../../types/wspdf-template-history'
import { DatePipe } from '@angular/common'
import { WsPdfUndoRedoService } from '../ws-pdf-undo-redo.service'

export enum ComponentType {
  LABEL,
  STATICLABEL,
  SECTION,
  IMAGE,
  QRCODE,
  DATASET
}

export enum Orientation {
  PORTRAIT = 0,
  LANDSCAPE = 1
}

export enum DinA4 {
  HEIGHT = 297,
  WIDTH = 210
}

@Component({
  selector: 'app-wspdf-editor',
  templateUrl: './wspdf-editor.component.html',
  styleUrls: ['./wspdf-editor.component.scss'],
  providers: [DatePipe]
})
export class WspdfEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  // needed to re-define enum in component such that it can be used in html template
  ComponentType: typeof ComponentType = ComponentType
  Orientation: typeof Orientation = Orientation

  pageLayoutFormSubscription: Subscription | undefined = undefined

  fontOptions = fontOptions

  cachedElementForElementSettings: ComponentRef<WspdfElementComponent> | null = null
  cachedElementSettingsFormValues = ''
  elementSettingsForm = new FormGroup({
    elementQrCodeContent: new FormControl('', [Validators.pattern(/^(https:\/\/.*|\$\{[^{}]*})$/)]),
    elementImageUrl: new FormControl('', [Validators.pattern(/^(https:\/\/.*|\$\{[^{}]*})$/)]),
    elementHeight: new FormControl(),
    elementWidth: new FormControl(),
    elementScaleProportionally: new FormControl(),
    elementRotation: new FormControl(),
    elementZIndex: new FormControl(),
    elementBackgroundColor: new FormControl(),
    elementVisibility: new FormControl(),
    elementOpacity: new FormControl(),
    elementBorderWidth: new FormControl(),
    elementBorderStyle: new FormControl(),
    elementBorderColor: new FormControl(),
    elementPositionHorizontal: new FormControl(),
    elementPositionVertical: new FormControl(),
    elementBoundary: new FormControl(),
    elementHorizontalAlign: new FormControl(),
    elementVerticalAlign: new FormControl()
  })

  elementSettingsFormSubscription: Subscription | undefined = undefined

  WSPDF_EDITOR_PAGE_MARKER_BOUNDARY = '#wspdfEditorPageMarker'
  WSPDF_EDITOR_INNER_PAGE_MARKER_BOUNDARY = '#wspdfEditorInnerPageMarker'
  CONTENT_WRAPPER_BOUNDARY = '#content-wrapper'
  HEADER_BOUNDARY = '#header'
  FOOTER_BOUNDARY = '#footer'

  elementBoundaryOptions: SelectOption[] = [
    { label: 'Page', value: this.WSPDF_EDITOR_INNER_PAGE_MARKER_BOUNDARY },
    { label: 'Content inside margins', value: this.CONTENT_WRAPPER_BOUNDARY },
    { label: 'Header', value: this.HEADER_BOUNDARY },
    { label: 'Footer', value: this.FOOTER_BOUNDARY }
  ]
  cachedInnerTextCurrentSelectedElement = ''
  cachedElementOfElementTextSettings: ComponentRef<WspdfElementComponent> | null = null
  cachedElementTextSettingsFormValues = ''
  elementTextSettingsForm = new FormGroup({
    elementText: new FormControl(),
    elementFont: new FormControl(),
    elementFontSize: new FormControl(),
    elementFontWeight: new FormControl(),
    elementFontStyle: new FormControl(),
    elementFontColor: new FormControl(),
    elementTextUnderline: new FormControl(),
    elementTextTransform: new FormControl(),
    elementTextAlign: new FormControl(),
    elementTextVerticalAlign: new FormControl(),
    elementTextLineSpacing: new FormControl(),
    elementTextWordBreak: new FormControl()
  })

  elementTextSettingsFormSubscription: Subscription | undefined = undefined

  templateName = new FormControl()

  labelTextFormControl = new FormControl()
  labelTextSubscription: Subscription | undefined

  @Input() template?: WspdfTemplate
  @Input() saving = false
  restoringTemplate = false
  templateToEdit?: WspdfTemplate
  templates: WspdfTemplate[] = []
  latestSavedTemplate: WspdfTemplate = new WspdfTemplate()
  templateHistory: WspdfTemplateHistory[] = []
  templateHistoryOptions: SelectOption[] = []
  draftMode = false

  templateNameChanged = false
  templateLoaded = false
  opened = true
  selectedTabIndex = 0
  testDataJson: { [key: string]: any } = {}
  @Output() save = new EventEmitter<WspdfTemplate>()

  // element references
  @ViewChild('wspdfEditorDisplayContainer') wspdfEditorDisplayContainer!: ElementRef
  @ViewChild('pdfContentContainer', { read: ViewContainerRef }) pdfContentContainer!: ViewContainerRef
  @ViewChild('pdfPageContainer', { read: ViewContainerRef }) pdfPageContainer!: ViewContainerRef
  @ViewChild('wspdfEditorPageMarker') wspdfEditorPageMarker!: ElementRef
  @ViewChild('wspdfEditorInnerPageMarker') wspdfEditorInnerPageMarker!: ElementRef
  @ViewChild('pdfContentWrapperContainer') pdfContentWrapper!: ElementRef
  @ViewChild('pdfHeaderWrapperContainer') pdfHeaderWrapperContainer!: ElementRef
  @ViewChild('pdfFooterWrapperContainer') pdfFooterWrapperContainer!: ElementRef
  @ViewChild('pdfHeaderContainer', { read: ViewContainerRef }) pdfHeaderContainer!: ViewContainerRef
  @ViewChild('pdfFooterContainer', { read: ViewContainerRef }) pdfFooterContainer!: ViewContainerRef

  @ViewChild('pdfPageSettings') pdfPageSettings!: PdfPageSettingsComponent
  cachedPageSettingsFormValues: string = ''

  cmToPxFactor = 1
  pxToCmFactor = 1

  defaultFontSize = 12

  enableHelpingGrid = ''

  public templateComponents = new Map<string, ComponentRef<WspdfElementComponent>>()
  public templateComponentsArray: ComponentRef<WspdfElementComponent>[] = []
  public currentSelectedElement?: ComponentRef<WspdfElementComponent>
  public currentSelectedElementChangedSubscription?: Subscription
  public currentActiveElementType = ''

  public SIDEBAR_TABS = {
    PAGE: 0,
    ELEMENT: 1,
    TEXT_SETTINGS: 2
  }

  constructor(
    private pdfService: PdfService,
    public translate: TranslateService,
    public dialog: MatDialog,
    public renderer: Renderer2,
    private cd: ChangeDetectorRef,
    public wsPdfUndoRedoService: WsPdfUndoRedoService,
    private wsDialogService: WsDialogService
  ) {}

  private setTemplateComponentsAsArray() {
    this.templateComponentsArray = Array.from(this.templateComponents.values())
  }

  ngOnDestroy(): void {
    this.templateComponents.forEach((comp: ComponentRef<WspdfElementComponent>, compId: string) => {
      comp.instance.el.nativeElement.removeEventListener('mousedown', (event: Event) => this.elementClicked(event))
    })

    if (this.pageLayoutFormSubscription !== undefined) {
      this.pageLayoutFormSubscription.unsubscribe()
    }
  }

  ngOnInit(): void {
    // this.calculatePageDimensions()
    this.loadTemplateHistory()
  }

  ngAfterViewInit(): void {
    // set editor container height to have h/w ratio of Din A4
    this.calculatePageDimensions()

    // load template into editor
    if (this.template) {
      this.setTemplateToEdit(this.template)
    }

    // add listener to page marker to listen for click events
    // TODO: make this not on page marker but on whole pdf page
    this.wspdfEditorDisplayContainer.nativeElement.addEventListener('mousedown', (event: Event) =>
      this.pageClicked(event)
    )

    // this.wspdfEditorPageMarker.nativeElement.addEventListener('keydown', () => {
    //   setTimeout(() => {
    //     this.updateElementTextSettings()
    //   }, 100)
    // })

    setTimeout(() => {
      this.captureUndoRedoTemplate()
    }, 100)
  }

  captureUndoRedoTemplate() {
    if (this.restoringTemplate) {
      return
    }
    const template: WspdfTemplate = {
      name: this.template?.name || '',
      owner: this.template?.owner || 0,
      wsPdfTemplateId: this.template?.wsPdfTemplateId || 0,
      content: this.getTemplateContentFromEditor(),
      styles: this.getTemplateStylesFromEditor(),
      testData: JSON.stringify(this.testDataJson)
    }
    this.wsPdfUndoRedoService.captureAction(template)
  }

  selectElement(elementId: string) {
    const clickedElementComponent = this.templateComponents.get(elementId)

    // do not set focus again if clicked element is already selected
    if (!clickedElementComponent || elementId === this.currentSelectedElement?.instance.el.nativeElement.id) {
      return
    }

    this.currentSelectedElement = clickedElementComponent

    // set current selected element via components map and id of clicked element
    this.currentSelectedElement.instance.setFocus(true)
    // this.currentSelectedElement.instance.el.nativeElement.classList.add('wspdf-active-element')
    this.currentActiveElementType = this.currentSelectedElement?.instance.el.nativeElement.tagName

    // update element and element text settings once after selecting element and subscribe to resize event of element
    this.updateElementSettings()
    this.updateElementTextSettings()

    this.currentSelectedElementChangedSubscription = this.currentSelectedElement.instance.elementChangedEvent.subscribe(
      ({ event, data, component }: { event: string; data: any; component: WspdfElementComponent }) => {
        // TODO: handle all changes emitted from wspdf element component
        switch (event) {
          case 'boundaryChanged':
            this.moveComponentToContainer(component, data.oldElementBoundary, component.elementBoundary)
            break
          case 'moved':
            this.updateElementPositionInElementSettingsForm(data.x, data.y)
            setTimeout(() => {
              this.captureUndoRedoTemplate()
            }, 100)
            break
          case 'resizeDragMoved':
            // We need to break because otherwise settings will update in default branch
            break
          case 'resized':
            // We need to break because otherwise settings will update in default branch
            break
          case 'resizeDragEnded':
            this.updateElementSizeInElementSettingsForm(data.width, data.height)
            setTimeout(() => {
              this.captureUndoRedoTemplate()
            }, 100)
            break
          default:
            this.updateElementSettings()
            this.updateElementTextSettings()
        }
      }
    )
  }

  /**
   * Updates the element position in the element settings form by converting pixel values to centimeters.
   * @param x - Horizontal position in pixels.
   * @param y - Vertical position in pixels.
   */
  updateElementPositionInElementSettingsForm(x: number, y: number) {
    this.elementSettingsForm.controls['elementPositionHorizontal'].patchValue(x * this.pxToCmFactor)
    this.elementSettingsForm.controls['elementPositionVertical'].patchValue(y * this.pxToCmFactor)
  }

  /**
   * Updates the element size in the element settings form by converting pixel values to centimeters.
   * @param width - width in pixels.
   * @param height - height in pixels.
   */
  updateElementSizeInElementSettingsForm(width: number, height: number) {
    const roundedWidthCm = this.roundToTwoDecimalPlaces(width * this.pxToCmFactor)
    const roundedHeightCm = this.roundToTwoDecimalPlaces(height * this.pxToCmFactor)

    this.elementSettingsForm.patchValue({ elementWidth: roundedWidthCm, elementHeight: roundedHeightCm })
  }

  /**
   * Rounds a number to two decimal places.
   * @param value - The number to round.
   * @returns The rounded number.
   */
  private roundToTwoDecimalPlaces(value: number): number {
    return Math.round(value * 100) / 100
  }

  /**
   *  only remove focus of current selected element if present and id differs from clicked element and element is not being resized
   */
  deselectCurrentElementIfNecessary(newElementId: string, elementIsBeingRemoved = false) {
    this.updateElementSettings()
    this.updateElementTextSettings()
    // component that is currently selected
    const currentSelectedElementComponent = this.currentSelectedElement?.instance

    if (!currentSelectedElementComponent) {
      return
    }
    // html element that is currently selected
    const currentSelectedHTMLElement = this.currentSelectedElement?.instance.el.nativeElement
    if (
      (currentSelectedHTMLElement &&
        currentSelectedHTMLElement.id !== newElementId &&
        !currentSelectedElementComponent.isBeingResized) ||
      elementIsBeingRemoved
    ) {
      this.currentSelectedElement?.instance.setFocus(false)
      this.currentSelectedElementChangedSubscription?.unsubscribe()
      this.currentSelectedElement = undefined
    }
  }

  wspdfEditorSettingsClicked() {
    this.draftMode = true
  }

  changeSideBarTab(tabToOpen: number) {
    this.selectedTabIndex = tabToOpen
  }

  layerElementClicked() {
    this.draftMode = true

    if (this.selectedTabIndex === this.SIDEBAR_TABS.PAGE) {
      setTimeout(() => {
        this.changeSideBarTab(this.SIDEBAR_TABS.ELEMENT)
      }, 250)
    }
  }

  pageClicked(event: Event): void {
    this.draftMode = true
    // stop propagation to not trigger multiple click
    event.stopImmediatePropagation()

    this.deselectCurrentElementIfNecessary('')
  }

  // function that is called when an element is clicked
  elementClicked(event: Event): void {
    this.draftMode = true
    if (this.selectedTabIndex === this.SIDEBAR_TABS.PAGE) {
      this.changeSideBarTab(this.SIDEBAR_TABS.ELEMENT)
    }
    // stop propagation to not trigger multiple click
    event.stopImmediatePropagation()

    // html element that has been clicked
    const clickedElement: HTMLElement = event.currentTarget as HTMLElement

    this.deselectCurrentElementIfNecessary(clickedElement.id)
    this.selectElement(clickedElement.id)
  }

  updateElementSettings(): void {
    if (this.currentSelectedElement) {
      //only init if we have new element selected
      if (this.cachedElementForElementSettings !== this.currentSelectedElement) {
        this.initElementSettingsForm()
        this.cachedElementSettingsFormValues = JSON.stringify(this.elementSettingsForm.getRawValue())
      }

      this.elementSettingsForm.controls['elementBoundary'].valueChanges.subscribe((elementBoundary) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.elementBoundary = elementBoundary
        }
      })

      // listen for changes in element settings form
      if (this.currentActiveElementType == 'APP-WSPDF-IMAGE') {
        this.elementSettingsForm.controls['elementImageUrl'].valueChanges.subscribe((elementImageUrl) => {
          if (this.currentSelectedElement && this.elementSettingsForm.get('elementImageUrl')?.valid) {
            ;(this.currentSelectedElement.instance as WspdfImageComponent).setImage(
              elementImageUrl || '',
              this.testDataJson
            )
          }
        })
      }
      if (this.currentActiveElementType == 'APP-WSPDF-QR-CODE') {
        this.elementSettingsForm.controls['elementQrCodeContent'].valueChanges.subscribe((elementQrCodeContent) => {
          if (this.currentSelectedElement && this.elementSettingsForm.get('elementQrCodeContent')?.valid) {
            ;(this.currentSelectedElement.instance as WspdfQrCodeComponent).setQrData(elementQrCodeContent || '')
          }
        })
      }

      this.elementSettingsForm.controls['elementRotation'].valueChanges.subscribe((elementRotation) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.transform = 'rotate(' + elementRotation + 'deg)'
        }
      })
      this.elementSettingsForm.controls['elementBackgroundColor'].valueChanges.subscribe((elementBackgroundColor) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.backgroundColor = String(elementBackgroundColor)
        }
      })
      // Todo: remove everywhere opacity is not supported by pdf
      // this.elementSettingsForm.controls['elementOpacity'].valueChanges.subscribe((elementOpacity) => {
      //   if (this.currentSelectedElement) {
      //     this.currentSelectedElement.instance.el.nativeElement.style.opacity = String(elementOpacity * 0.01)
      //   }
      // })
      this.elementSettingsForm.controls['elementVisibility'].valueChanges.subscribe((elementVisibility) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.display = elementVisibility ? 'block' : 'none'
        }
      })
      this.elementSettingsForm.controls['elementZIndex'].valueChanges.subscribe((elementZIndex) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.zIndex = elementZIndex
        }
      })
      this.elementSettingsForm.controls['elementBorderWidth'].valueChanges.subscribe((elementBorderWidth) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.borderWidth = elementBorderWidth + 'px'
        }
      })
      this.elementSettingsForm.controls['elementBorderStyle'].valueChanges.subscribe((elementBorderStyle) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.borderStyle = elementBorderStyle
        }
      })
      this.elementSettingsForm.controls['elementBorderColor'].valueChanges.subscribe((elementBorderColor) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.borderColor = elementBorderColor
        }
      })
      this.elementSettingsForm.controls['elementHorizontalAlign'].valueChanges.subscribe(() => {
        const horizontalAlignment = this.elementSettingsForm.controls['elementHorizontalAlign'].value
        if (this.currentSelectedElement && horizontalAlignment) {
          this.alignElementHorizontallyToBoundary(this.currentSelectedElement, horizontalAlignment)
          const currentSelectElement = this.currentSelectedElement.instance.el.nativeElement
          this.updateElementPositionInElementSettingsForm(
            currentSelectElement.offsetLeft,
            currentSelectElement.offsetTop
          )
        }
      })
      this.elementSettingsForm.controls['elementVerticalAlign'].valueChanges.subscribe(() => {
        const verticalAlignment = this.elementSettingsForm.controls['elementVerticalAlign'].value
        if (this.currentSelectedElement && verticalAlignment) {
          this.alignElementVerticallyToBoundary(this.currentSelectedElement, verticalAlignment)
          const currentSelectedElement = this.currentSelectedElement.instance.el.nativeElement
          this.updateElementPositionInElementSettingsForm(
            currentSelectedElement.offsetLeft,
            currentSelectedElement.offsetTop
          )
        }
      })
      if (this.checkIfElementSettingsHasChanged()) {
        this.captureUndoRedoTemplate()
        this.cachedElementSettingsFormValues = JSON.stringify(this.elementSettingsForm.getRawValue())
        this.cachedElementForElementSettings = null
      }
    }
  }

  alignElementVerticallyToBoundary(
    currentSelectedElement: ComponentRef<WspdfElementComponent>,
    verticalAlignment: string
  ) {
    const selectedElement = currentSelectedElement.instance.el.nativeElement
    let top = 0
    let bottom = 0
    let middle = `calc(50% - ${selectedElement.offsetHeight / 2}px)`

    if (currentSelectedElement.instance.elementBoundary === '#wspdfEditorInnerPageMarker') {
      const pageLayoutSettings = this.pdfPageSettings.pageLayoutForm.value
      const marginBottom = pageLayoutSettings['marginBottom'] || 0
      const marginTop = pageLayoutSettings['marginTop'] || 0
      const headerHeight = pageLayoutSettings['headerHeight'] || 0
      const footerHeight = pageLayoutSettings['footerHeight'] || 0
      const innerPageMarkerHeight = this.wspdfEditorInnerPageMarker.nativeElement.offsetHeight

      top = -(marginTop - headerHeight) * this.cmToPxFactor
      bottom = -(marginBottom - footerHeight) * this.cmToPxFactor
      middle = `${innerPageMarkerHeight / 2 - top * -1 - selectedElement.offsetHeight / 2}px`
    }

    switch (verticalAlignment) {
      case 'top':
        selectedElement.style.bottom = 'unset'
        selectedElement.style.top = top + 'px'
        break
      case 'bottom':
        selectedElement.style.top = 'auto'
        selectedElement.style.bottom = bottom + 'px'
        selectedElement.style.top = selectedElement.offsetTop.toString() + 'px'
        selectedElement.style.bottom = 'unset'
        break
      case 'middle':
        selectedElement.style.bottom = 'unset'
        selectedElement.style.top = middle
        selectedElement.style.top = selectedElement.offsetTop.toString() + 'px'
    }
  }

  alignElementHorizontallyToBoundary(
    currentSelectedElement: ComponentRef<WspdfElementComponent>,
    horizontalAlignment: string
  ) {
    const selectedElement = currentSelectedElement.instance.el.nativeElement
    const elementBoundaryIsInnerPageMarker =
      currentSelectedElement.instance.elementBoundary === '#wspdfEditorInnerPageMarker'

    const pageLayoutSettings = this.pdfPageSettings.pageLayoutForm.value
    const marginLeft = pageLayoutSettings['marginLeft'] || 0
    const marginRight = pageLayoutSettings['marginRight'] || 0
    const innerPageMarkerWidth = this.wspdfEditorInnerPageMarker.nativeElement.offsetWidth

    switch (horizontalAlignment) {
      case 'left': {
        selectedElement.style.left = `${!elementBoundaryIsInnerPageMarker ? 0 : -marginLeft * this.cmToPxFactor}px`
        break
      }
      case 'right': {
        selectedElement.style.left = !elementBoundaryIsInnerPageMarker
          ? `calc(100% - ${selectedElement.offsetWidth}px)`
          : `calc(100% + ${marginRight * this.cmToPxFactor}px - ${selectedElement.offsetWidth}px)`
        selectedElement.style.left = selectedElement.offsetLeft.toString() + 'px'
        break
      }
      case 'center':
        selectedElement.style.left = !elementBoundaryIsInnerPageMarker
          ? `calc(50% - ${selectedElement.offsetWidth / 2}px)`
          : `calc(${innerPageMarkerWidth / 2 - selectedElement.offsetWidth / 2 - marginLeft * this.cmToPxFactor}px)`
        selectedElement.style.left = selectedElement.offsetLeft.toString() + 'px'
    }
  }

  /**
   * Checks if element settings have changed, excluding values that should not influence capturing the template for undo/redo.
   * Specifically removes readonly values related to elementPositionHorizontal and elementPositionVertical.
   * @returns {boolean} True if changes are detected, false otherwise.
   */
  checkIfElementSettingsHasChanged(): boolean {
    const stripValuesToIgnore = (value: string) =>
      value
        .replace(/"elementPositionHorizontal":\d+([.]\d+,*)?,*/g, '')
        .replace(/"elementPositionVertical":\d+([.]\d+,*)?,*/g, '')

    const cachedFormValues = stripValuesToIgnore(this.cachedElementSettingsFormValues.toString())
    const actualFormValues = stripValuesToIgnore(JSON.stringify(this.elementSettingsForm.getRawValue()))

    return cachedFormValues !== actualFormValues
  }

  initElementSettingsForm() {
    if (!this.currentSelectedElement) {
      return
    }
    // when editor element has been clicked and thus set in focus, element settings are loaded in form.
    // any changes in form will be added to element's style

    // select element settings tab
    // this.selectedTabIndex =
    //   this.currentSelectedElement.instance.type == ComponentType.STATICLABEL ||
    //   this.currentSelectedElement.instance.type == ComponentType.LABEL
    //     ? 2
    //     : 1

    // load all current element settings
    const nativeElement = this.currentSelectedElement.instance.el.nativeElement
    const initialWidthCm = nativeElement.offsetWidth * this.pxToCmFactor
    const initialHeightCm = nativeElement.offsetHeight * this.pxToCmFactor
    const roundedInitialWidthCm = this.roundToTwoDecimalPlaces(initialWidthCm)
    const roundedInitialHeightCm = this.roundToTwoDecimalPlaces(initialHeightCm)

    // let scalingFactor = this.currentActiveElement.instance.aspectRatio
    const initialValues: any = {}
    if (this.currentActiveElementType == 'APP-WSPDF-IMAGE') {
      if (this.elementSettingsForm.get('elementImageUrl')?.valid) {
        initialValues.elementImageUrl = (this.currentSelectedElement?.instance as WspdfImageComponent).getImage()
      }
    } else if (this.currentActiveElementType == 'APP-WSPDF-QR-CODE') {
      initialValues.elementScaleProportionally = true
      if (this.elementSettingsForm.get('elementQrCodeContent')?.valid) {
        initialValues.elementQrCodeContent = (this.currentSelectedElement?.instance as WspdfQrCodeComponent).getQrData()
      }
    }
    initialValues.elementWidth = roundedInitialWidthCm
    initialValues.elementHeight = roundedInitialHeightCm
    initialValues.elementRotation = nativeElement.style.transform.match(/\d+/)

    if (nativeElement.style.backgroundColor) {
      initialValues.elementBackgroundColor = nativeElement.style.backgroundColor
    }
    initialValues.elementOpacity = Number(nativeElement.style.opacity) * 100

    initialValues.elementVisibility = nativeElement.style.display != 'none'

    initialValues.elementZIndex = nativeElement.style.zIndex

    // UNDER CONSTRUCTION: set border on component or child node? dismiss active element border.
    initialValues.elementBorderStyle = nativeElement.style.borderStyle ? nativeElement.style.borderStyle : 'none'
    initialValues.elementBorderWidth = nativeElement.style.borderWidth.match(/\d+/)

    initialValues.elementBorderColor = nativeElement.style.borderColor

    // END -----------------------------------------------------------------------------------------------------------------

    initialValues.elementPositionHorizontal = Number(nativeElement.style.left.match(/\d+/)) * this.pxToCmFactor

    initialValues.elementPositionVertical = Number(nativeElement.style.top.match(/\d+/)) * this.pxToCmFactor

    initialValues.elementBoundary = this.currentSelectedElement.instance.elementBoundary

    this.elementSettingsForm.patchValue(initialValues)
    this.cachedElementForElementSettings = this.currentSelectedElement
  }

  sideNavVisible(opened: boolean) {
    this.opened = opened
    if (opened) {
      // TODO: add needed logic when sidenav is opened
    }
  }

  elementWidthInputChanged(keyDownEvent: any) {
    const aspectRatio = this.currentSelectedElement?.instance.aspectRatio
    const elementWidth = this.elementSettingsForm.controls['elementWidth'].value
    if (!elementWidth) return

    //  calculate width in relation to editor dimensions
    if (this.currentSelectedElement && aspectRatio) {
      this.currentSelectedElement.instance.el.nativeElement.style.width = elementWidth * this.cmToPxFactor + 'px'

      if (this.currentSelectedElement.instance.type == ComponentType.QRCODE) {
        this.currentSelectedElement.instance.el.nativeElement.childNodes[0].style.height =
          elementWidth * this.cmToPxFactor + 'px'
        this.currentSelectedElement.instance.el.nativeElement.childNodes[0].childNodes[6].childNodes[0].childNodes[0].style.height =
          elementWidth + 'cm'
        this.currentSelectedElement.instance.el.nativeElement.childNodes[0].style.width =
          elementWidth * this.cmToPxFactor + 'px'
        this.currentSelectedElement.instance.el.nativeElement.childNodes[0].childNodes[6].childNodes[0].childNodes[0].style.width =
          elementWidth + 'cm'
      }

      // if (this.elementSettingsForm.controls['elementScaleProportionally'].value == true) {
      //   // add logic for proportional scaling
      //   const rescaledHeightCm = Math.floor(elementWidth * aspectRatio * 100000) / 100000
      //   const rescaledHeightPx = Math.floor(rescaledHeightCm * this.cmToPxFactor * 100000) / 100000
      //
      //   this.elementSettingsForm.controls['elementWidth'].setValue(rescaledHeightCm)
      //   this.currentSelectedElement.instance.el.nativeElement.style.height = rescaledHeightPx + 'px'
      // }
    }
  }

  elementHeightInputChanged(keyDownEvent: any) {
    const aspectRatio = this.currentSelectedElement?.instance.aspectRatio
    const elementHeight = this.elementSettingsForm.controls['elementHeight'].value
    if (!elementHeight) return

    //  calculate width in relation to editor dimensions
    if (this.currentSelectedElement && aspectRatio) {
      this.currentSelectedElement.instance.el.nativeElement.style.height = elementHeight * this.cmToPxFactor + 'px'

      if (this.currentSelectedElement.instance.type == ComponentType.QRCODE) {
        this.currentSelectedElement.instance.el.nativeElement.childNodes[0].style.height =
          elementHeight * this.cmToPxFactor + 'px'
        this.currentSelectedElement.instance.el.nativeElement.childNodes[0].childNodes[6].childNodes[0].childNodes[0].style.height =
          elementHeight + 'cm'
        this.currentSelectedElement.instance.el.nativeElement.childNodes[0].style.width =
          elementHeight * this.cmToPxFactor + 'px'
        this.currentSelectedElement.instance.el.nativeElement.childNodes[0].childNodes[6].childNodes[0].childNodes[0].style.width =
          elementHeight + 'cm'
      }
      // if (this.elementSettingsForm.controls['elementScaleProportionally'].value == true) {
      //   // add logic for proportional scaling
      //   const rescaledWidthCm = Math.floor(elementHeight * aspectRatio * 100000) / 100000
      //   const rescaledWidthPx = Math.floor(rescaledWidthCm * this.cmToPxFactor * 100000) / 100000
      //
      //   this.elementSettingsForm.controls['elementWidth'].setValue(rescaledWidthCm)
      //   this.currentSelectedElement.instance.el.nativeElement.style.width = rescaledWidthPx + 'px'
      // }
    }
  }

  rgbToHex(rgbColor: string) {
    const rgbArray = rgbColor.match(/\d+/g)
    if (!rgbArray) return rgbColor
    return (
      '#' +
      rgbArray
        .map((component) => {
          const hex = parseInt(component).toString(16)
          return hex.length === 1 ? '0' + hex : hex
        })
        .join('')
    )
  }

  initElementTextSettingsForm() {
    if (!this.currentSelectedElement) {
      return
    }
    const initialValues: any = {}
    if (this.currentSelectedElement.instance.type != ComponentType.DATASET) {
      initialValues.elementText = (this.currentSelectedElement?.instance as WspdfLabelComponent).getLabel()
    }
    const nativeElement = this.currentSelectedElement.instance.el.nativeElement
    initialValues.elementFont = nativeElement.style.fontFamily || fontOptions[0].value
    initialValues.elementFontSize = Number(nativeElement.style.fontSize.match(/\d+/)) || this.defaultFontSize
    initialValues.elementFontWeight = nativeElement.style.fontWeight || ''
    initialValues.elementFontStyle = nativeElement.style.fontStyle || ''
    initialValues.elementFontColor = nativeElement.style.color || '#000000'
    initialValues.elementTextUnderline = nativeElement.style.textDecoration || ''
    initialValues.elementTextTransform = nativeElement.style.textTransform || ''
    initialValues.elementTextAlign = nativeElement.style.textAlign || ''
    initialValues.elementTextVerticalAlign = nativeElement.childNodes[0].style.alignContent || ''
    initialValues.elementTextLineSpacing = nativeElement.style.lineHeight || ''
    initialValues.elementTextWordBreak = nativeElement.style.wordBreak || ''
    this.elementTextSettingsForm.patchValue(initialValues)
  }

  updateElementTextSettings(): void {
    if (
      this.currentSelectedElement &&
      (this.currentSelectedElement.instance.type == ComponentType.STATICLABEL ||
        this.currentSelectedElement.instance.type == ComponentType.LABEL ||
        this.currentSelectedElement.instance.type == ComponentType.DATASET)
    ) {
      if (this.cachedElementOfElementTextSettings !== this.currentSelectedElement) {
        this.initElementTextSettingsForm()
        this.cachedElementTextSettingsFormValues = JSON.stringify(this.elementTextSettingsForm.getRawValue())
        this.cachedElementOfElementTextSettings = this.currentSelectedElement
        this.cachedInnerTextCurrentSelectedElement = this.currentSelectedElement.instance.el.nativeElement.innerText
      }

      // listen for changes in element settings form
      this.elementTextSettingsFormSubscription = this.elementTextSettingsForm.controls[
        'elementText'
      ].valueChanges.subscribe((elementText) => {
        this.draftMode = true
        const compType = this.currentSelectedElement?.instance.type

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // ONLY EDITABLE IN EDITOR DIRECTLY
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // if(compType == ComponentType.STATICLABEL){
        //   let currentElement: WspdfStaticLabelComponent = (this.currentActiveElement?.instance as WspdfStaticLabelComponent)
        //   currentElement.setLabel(elementText)
        // }
        // if(compType == ComponentType.LABEL){
        //   let currentElement: WspdfLabelComponent = (this.currentActiveElement?.instance as WspdfLabelComponent)
        //   currentElement.setLabel(elementText)
        // }
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        if (compType == ComponentType.IMAGE) {
          const currentElement: WspdfImageComponent = this.currentSelectedElement?.instance as WspdfImageComponent
          currentElement.setImage(elementText, this.testDataJson)
        }
        if (compType == ComponentType.QRCODE) {
          const currentElement: WspdfQrCodeComponent = this.currentSelectedElement?.instance as WspdfQrCodeComponent
          currentElement.setQrData(elementText)
        }
      })
      this.elementTextSettingsForm.controls['elementFont'].valueChanges.subscribe((elementFont) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.fontFamily = elementFont
        }
      })
      this.elementTextSettingsForm.controls['elementFontSize'].valueChanges.subscribe((elementFontSize) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.fontSize = elementFontSize + 'px'
        }
      })
      this.elementTextSettingsForm.controls['elementFontWeight'].valueChanges.subscribe((elementFontWeight) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.fontWeight = elementFontWeight
        }
      })
      this.elementTextSettingsForm.controls['elementFontStyle'].valueChanges.subscribe((elementFontStyle) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.fontStyle = elementFontStyle
        }
      })
      this.elementTextSettingsForm.controls['elementFontColor'].valueChanges.subscribe((elementFontColor) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.color = elementFontColor
        }
      })
      this.elementTextSettingsForm.controls['elementTextUnderline'].valueChanges.subscribe((elementTextUnderline) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.textDecoration = elementTextUnderline
        }
      })
      this.elementTextSettingsForm.controls['elementTextTransform'].valueChanges.subscribe((elementTextTransform) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.textTransform = elementTextTransform
        }
      })
      this.elementTextSettingsForm.controls['elementTextAlign'].valueChanges.subscribe((elementTextAlign) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.textAlign = elementTextAlign
        }
      })
      this.elementTextSettingsForm.controls['elementTextVerticalAlign'].valueChanges.subscribe(
        (elementTextVerticalAlign) => {
          if (this.currentSelectedElement) {
            this.currentSelectedElement.instance.el.nativeElement.childNodes[0].style.alignContent =
              elementTextVerticalAlign
          }
        }
      )
      this.elementTextSettingsForm.controls['elementTextLineSpacing'].valueChanges.subscribe(
        (elementTextLineSpacing) => {
          if (this.currentSelectedElement) {
            this.currentSelectedElement.instance.el.nativeElement.style.lineHeight = elementTextLineSpacing
          }
        }
      )
      this.elementTextSettingsForm.controls['elementTextWordBreak'].valueChanges.subscribe((elementTextWordBreak) => {
        if (this.currentSelectedElement) {
          this.currentSelectedElement.instance.el.nativeElement.style.wordBreak = elementTextWordBreak
        }
      })

      if (
        this.cachedElementTextSettingsFormValues !== JSON.stringify(this.elementTextSettingsForm.getRawValue()) ||
        this.currentSelectedElement?.instance.el.nativeElement.innerText !== this.cachedInnerTextCurrentSelectedElement
      ) {
        this.captureUndoRedoTemplate()
        this.cachedElementOfElementTextSettings = null
      }
    }
  }

  // calculate A4 dimensions
  // Resizing editor container to Din A4 and calculating cm <-> px factors
  calculatePageDimensions(): void {
    // const pageOrientation = this.pageLayoutForm.value.size
    const dinA4Ratio = DinA4.HEIGHT / DinA4.WIDTH
    const fixedHeight = 1500
    const height = fixedHeight
    const width = fixedHeight / dinA4Ratio
    const boundingRect = (this.wspdfEditorDisplayContainer.nativeElement as Element).getBoundingClientRect()
    this.wspdfEditorDisplayContainer.nativeElement.style.height = height + 'px'
    this.wspdfEditorDisplayContainer.nativeElement.style.width = width + 'px'

    this.pxToCmFactor = DinA4.WIDTH / 10 / width
    this.cmToPxFactor = width / (DinA4.WIDTH / 10)
  }

  calcMarginLayout(cmVals: any): number[] {
    // top, height, left, width
    const returnLayout = [6.734006734, 86.531986532, 9.523809524, 80.952380952]

    returnLayout[0] = 100 * (cmVals['marginTop'] / 29.7)
    returnLayout[1] = 100 - 100 * ((cmVals['marginTop'] + cmVals['marginBottom']) / 29.7)
    returnLayout[2] = 100 * (cmVals['marginLeft'] / 21)
    returnLayout[3] = 100 - 100 * ((cmVals['marginRight'] + cmVals['marginLeft']) / 21)

    return returnLayout
  }

  getStyleDefinitionForPageLayout(margins: number[]): string {
    const styleString =
      'top: ' + margins[0] + '%; height: ' + margins[1] + '%; left: ' + margins[2] + '%; width: ' + margins[3] + '%;'

    return styleString
  }

  // function that returns default container for inserting new element
  getDefaultContainerRef(): ViewContainerRef {
    // only used for sections, that are not implemented yet
    // if (this.currentSelectedElement?.instance != undefined) {
    //   const potentialRef = this.currentSelectedElement?.instance.getContainerRef()
    //   if (potentialRef != undefined) {
    //     currentContainerRef = potentialRef
    //   }
    // }
    return this.pdfContentContainer
  }

  addReportComponentClicked(componentType: ComponentType, pageCounter?: boolean, datasetName?: string): void {
    this.draftMode = true
    this.addReportComponent(componentType, pageCounter, datasetName)

    setTimeout(() => {
      this.captureUndoRedoTemplate()
    }, 100)
  }

  // generic function that creates a component for the PDF
  addReportComponent(componentType: ComponentType, pageCounter?: boolean, datasetName?: string): void {
    let coref = null
    if (componentType == ComponentType.STATICLABEL) {
      coref = this.addStaticLabel(pageCounter ? 'Seite' : '')
      if (coref) {
        if (pageCounter) {
          coref.instance.el.nativeElement.classList.add('page-counter')
          // coref.location.nativeElement.id = 'wsreport-page-counter-' + this.templateComponents.size
        }
      }
    } else if (componentType == ComponentType.LABEL) {
      coref = this.addLabel()
    } else if (componentType == ComponentType.DATASET) {
      coref = this.addDataSet(datasetName || '')
    } else if (componentType == ComponentType.IMAGE) {
      coref = this.addImage()
    } else if (componentType == ComponentType.QRCODE) {
      coref = this.addQrCode()
      // } else if (componentType == ComponentType.SECTION) {
      //   coref = this.addEditorSection()
    }

    if (coref) {
      // initial element settings
      coref.instance.el.nativeElement.style.opacity = String(1)
      coref.instance.el.nativeElement.style.display = 'block'
      // coref.instance.el.nativeElement.style.transform = 'rotate(0deg)'
      coref.instance.el.nativeElement.style.zIndex = '1'

      this.deselectCurrentElementIfNecessary(coref.instance.el.nativeElement.id)
      this.selectElement(coref.instance.el.nativeElement.id)

      if (
        this.currentSelectedElement?.instance.type == ComponentType.STATICLABEL ||
        this.currentSelectedElement?.instance.type == ComponentType.LABEL ||
        this.currentSelectedElement?.instance.type == ComponentType.DATASET
      ) {
        this.currentSelectedElement.instance.el.nativeElement.style.fontSize = this.defaultFontSize + 'px'
        this.currentSelectedElement.instance.el.nativeElement.style.fontFamily = this.fontOptions[0].value
        this.currentSelectedElement.instance.el.nativeElement.style.textTransform = 'normal'
        this.currentSelectedElement.instance.el.nativeElement.style.fontStyle = 'normal'
        this.currentSelectedElement.instance.el.nativeElement.style.lineHeight = 1

        this.updateElementTextSettings()
      }

      // initially set width to make sure any width is set
      setTimeout(() => {
        if (!this.currentSelectedElement) return
        this.currentSelectedElement.instance.el.nativeElement.style.width =
          this.currentSelectedElement.instance.el.nativeElement.clientWidth + 'px'
      })
    }

    this.setTemplateComponentsAsArray()
    this.changeSideBarTab(this.SIDEBAR_TABS.ELEMENT)
  }

  // add a static label
  addStaticLabel(
    label?: string,
    containerRef: ViewContainerRef = this.getDefaultContainerRef()
  ): ComponentRef<WspdfStaticLabelComponent> {
    const newElementId = 'wsreport-static-label-' + this.templateComponents.size

    const coref = containerRef.createComponent(WspdfStaticLabelComponent)
    coref.location.nativeElement.id = newElementId
    coref.instance.el.nativeElement.addEventListener('mousedown', (event: Event) => this.elementClicked(event))
    coref.instance.el.nativeElement.style.width = '100px'

    if (label) {
      coref.instance.setLabel(label)
    }

    this.templateComponents.set(newElementId, coref)

    return coref
  }

  // add a placeholder label that is replaced with the payload
  addLabel(
    label?: string,
    containerRef: ViewContainerRef = this.getDefaultContainerRef()
  ): ComponentRef<WspdfLabelComponent> {
    const newElementId = 'wsreport-label-' + this.templateComponents.size

    const coref = containerRef.createComponent(WspdfLabelComponent)
    coref.location.nativeElement.id = newElementId
    coref.instance.el.nativeElement.addEventListener('mousedown', (event: Event) => this.elementClicked(event))
    coref.instance.el.nativeElement.style.width = '150px'

    if (label) {
      coref.instance.setLabel(label)
    }
    this.templateComponents.set(newElementId, coref)

    return coref
  }

  // add a data set
  addDataSet(
    dataset: string,
    containerRef: ViewContainerRef = this.getDefaultContainerRef(),
    columnStyles: any[] = []
  ): ComponentRef<WspdfDatasetComponent> {
    const newElementId = 'wsreport-dataset-' + this.templateComponents.size

    const coref = containerRef.createComponent(WspdfDatasetComponent)
    coref.location.nativeElement.id = newElementId
    coref.instance.el.nativeElement.addEventListener('mousedown', (event: Event) => this.elementClicked(event))
    if (this.testDataJson && this.testDataJson[dataset]) {
      const dataSetObject = this.testDataJson[dataset]['data'] || []
      const dataSetHeaders = this.testDataJson[dataset]['headers'] || {}
      const dataSetColumns = this.testDataJson[dataset]['columns'] || []

      coref.instance.setData(dataset, dataSetObject, dataSetHeaders, dataSetColumns, columnStyles)
    }
    this.templateComponents.set(newElementId, coref)

    return coref
  }

  // add image to editor
  addImage(
    imageSource?: string,
    containerRef: ViewContainerRef = this.getDefaultContainerRef()
  ): ComponentRef<WspdfImageComponent> {
    const newElementId = 'wsreport-image-' + this.templateComponents.size
    const coref = containerRef.createComponent(WspdfImageComponent)
    coref.location.nativeElement.id = newElementId
    coref.instance.el.nativeElement.addEventListener('mousedown', (event: Event) => this.elementClicked(event))
    if (imageSource) {
      coref.instance.setImage(imageSource, this.testDataJson)
    }
    this.templateComponents.set(newElementId, coref)

    return coref
  }

  // add QR code to editor
  addQrCode(
    qrData?: string,
    widthQrCode?: number,
    containerRef: ViewContainerRef = this.getDefaultContainerRef()
  ): ComponentRef<WspdfQrCodeComponent> {
    const newElementId = 'wsreport-qr-code-' + this.templateComponents.size

    const coref = containerRef.createComponent(WspdfQrCodeComponent)
    coref.location.nativeElement.id = newElementId
    coref.instance.el.nativeElement.addEventListener('mousedown', (event: Event) => this.elementClicked(event))
    if (qrData) {
      coref.instance.setQrData(qrData)
      if (widthQrCode) {
        coref.instance.setQrData(qrData, widthQrCode)
      }
    }
    coref.instance.el.nativeElement.style.width = '150px'
    coref.instance.el.nativeElement.style.height = '150px'
    this.templateComponents.set(newElementId, coref)

    return coref
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // DO WE NEED THIS?
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // add section for structuring the editor
  // addEditorSection(): ComponentRef<WspdfSectionComponent> | null {
  //   const currentContainerRef: ViewContainerRef = this.getCurrentActiveContainerRef()
  //
  //   if (currentContainerRef != undefined) {
  //     const newElementId = 'wsreport-section-' + this.templateComponents.size
  //
  //     let coref = currentContainerRef.createComponent(WspdfSectionComponent)
  //     coref.location.nativeElement.id = newElementId
  //     coref.instance.el.nativeElement.addEventListener('mousedown', (event: Event) => this.componentClicked(event))
  //     this.templateComponents.set(newElementId, coref)
  //
  //     return coref
  //   }
  //
  //   return null
  // }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  setTemplateToEdit(template: WspdfTemplate) {
    this.templateToEdit = template
    this.testDataJson = JSON.parse(template.testData)
    if (template.styles) {
      const pageLayoutStyles = JSON.parse(template.styles)['@page']
      this.processLoadedTemplateStyles(pageLayoutStyles)
    }

    this.processLoadedTemplateContent(template.content)
  }

  processLoadedTemplateContent(content: string): void {
    const temp = document.createElement('template')

    temp.innerHTML = content
    temp.content.childNodes.forEach((firstLevelChild) => {
      const firstLevelChildHtmlElement = firstLevelChild as HTMLElement
      if (
        firstLevelChildHtmlElement.nodeType === 1 &&
        firstLevelChildHtmlElement.hasAttribute('data-element-boundary')
      ) {
        const elementBoundary = firstLevelChildHtmlElement.getAttribute('data-element-boundary')
        if (elementBoundary === '#wspdfEditorPageMarker' || elementBoundary === '#wspdfEditorInnerPageMarker') {
          this.htmlTreeConstruction(firstLevelChildHtmlElement, this.pdfPageContainer)
        }
      } else {
        this.htmlTreeConstruction(firstLevelChildHtmlElement)
      }
    })

    this.cd.detectChanges()
  }

  htmlTreeConstruction(currentNode: HTMLElement, containerRef: ViewContainerRef = this.getDefaultContainerRef()): void {
    let componentRef: ComponentRef<WspdfElementComponent> | null = null
    if (currentNode.nodeName == 'APP-WSPDF-STATIC-LABEL') {
      const linebreakLabel = currentNode.querySelector('.wspdf-element__text')?.innerHTML.replaceAll('<br>', '\n')
      componentRef = this.addStaticLabel(linebreakLabel ? linebreakLabel : currentNode.innerText, containerRef)
    } else if (currentNode.nodeName == 'APP-WSPDF-LABEL') {
      const linebreakLabel = currentNode.querySelector('.wspdf-element__text')?.innerHTML.replaceAll('<br>', '\n')
      componentRef = this.addLabel(linebreakLabel ? linebreakLabel : currentNode.innerText, containerRef)
    } else if (currentNode.nodeName == 'APP-WSPDF-DATASET') {
      const columnStyles: any[] = []
      const colgroup = currentNode.querySelector('colgroup')
      if (colgroup) {
        colgroup.querySelectorAll('col').forEach((col) => {
          const computedStyles = col.style
          const styles: { [key: string]: string } = {}
          for (let i = 0; i < computedStyles.length; i++) {
            const propertyName = computedStyles[i]
            let propertyValue = computedStyles.getPropertyValue(propertyName)
            if (propertyValue.includes('cm')) {
              const cmVal = Number(propertyValue.substring(0, propertyValue.length - 2))
              propertyValue = cmVal * this.cmToPxFactor + 'px'
            }
            styles[propertyName] = propertyValue
          }
          columnStyles.push(styles)
        })
      }

      componentRef = this.addDataSet(
        currentNode.querySelector('.wspdf-element__table')?.id || '',
        containerRef,
        columnStyles
      )
    } else if (currentNode.nodeName == 'APP-WSPDF-IMAGE') {
      let imageSource = (currentNode.querySelector('.wspdf-element__image') as HTMLElement)?.style.backgroundImage
        .replace(/url\(["']?/, '')
        .replace(/["']?\)/, '')

      //Todo: remove when older templates are edited and saved again
      // src only used in older verions
      if (!imageSource) {
        imageSource = (currentNode.querySelector('.wspdf-element__image') as HTMLElement)?.getAttribute('src') || ''
      }

      componentRef = this.addImage(imageSource || '', containerRef)
    } else if (currentNode.nodeName == 'APP-WSPDF-QR-CODE') {
      // uses qrdata from dataset because the attributes with "ng-reflect-..." are not available in production
      const qrData = (currentNode.childNodes[0] as HTMLElement).dataset['qrdata']

      componentRef = this.addQrCode(
        qrData,
        Number(currentNode.style.width.replace('cm', '')) * this.cmToPxFactor,
        containerRef
      )
    } else if (currentNode.nodeName == 'DIV' && currentNode.id == 'content-wrapper') {
      // timeout needed for pdfHeaderContainer to be defined after form has just been updated to true
      setTimeout(() => {
        currentNode.childNodes.forEach((headerChild) => {
          // replace html entities to show them correctly in editor
          this.htmlTreeConstruction(headerChild as HTMLElement, this.pdfContentContainer)
        })
      }, 100)
    } else if (currentNode.nodeName == 'HEADER') {
      // timeout needed for pdfHeaderContainer to be defined after form has just been updated to true
      setTimeout(() => {
        currentNode.childNodes.forEach((headerChild) => {
          this.htmlTreeConstruction(headerChild as HTMLElement, this.pdfHeaderContainer)
        })
      }, 100)
    } else if (currentNode.nodeName == 'FOOTER') {
      // timeout needed for pdfFooterContainer to be defined after form has just been updated to true
      setTimeout(() => {
        currentNode.childNodes.forEach((footerChild) => {
          this.htmlTreeConstruction(footerChild as HTMLElement, this.pdfFooterContainer)
        })
      }, 100)
    }

    if (!componentRef) {
      return
    }

    // TODO: check if this deletion and adding is needed, templateComponets Map is already updated in add... functions
    // this.templateComponents.delete(componentRef.location.nativeElement.id)
    // componentRef.location.nativeElement.id = currentNode.id
    // this.templateComponents.set(componentRef.location.nativeElement.id, componentRef)

    // get current node datasets and set them to the component
    Object.entries(currentNode.dataset).forEach(([datasetName, datasetValue]) => {
      if (!componentRef) return
      componentRef.location.nativeElement.dataset[datasetName] = datasetValue
    })

    // convert all style attributes with cm to px
    Object.entries(currentNode.style).forEach((currentProp, idx) => {
      if (currentNode.style.hasOwnProperty(idx)) {
        const prop: string = currentProp[1] as string
        const prevVal = currentNode.style.getPropertyValue(prop)
        if (prevVal.includes('cm')) {
          const cmVal = parseFloat(prevVal.substring(0, prevVal.length - 2))
          currentNode.style.setProperty(prop, cmVal / this.pxToCmFactor + 'px')
        }
      }
    })

    // set style and class attributes of the current node to the component element
    componentRef.location.nativeElement.setAttribute('style', currentNode.getAttribute('style'))
    componentRef.location.nativeElement.setAttribute('class', currentNode.getAttribute('class'))
    this.setTemplateComponentsAsArray()
  }

  getContainerRefForContainerId(containerId: string): ViewContainerRef {
    switch (containerId) {
      case '#header':
        return this.pdfHeaderContainer
      case '#footer':
        return this.pdfFooterContainer
      case '#wspdfEditorPageMarker':
        return this.pdfPageContainer
      case '#wspdfEditorInnerPageMarker':
        return this.pdfPageContainer
      default:
        return this.pdfContentContainer
    }
  }

  moveComponentToContainer(
    component: WspdfElementComponent,
    oldComponentBoundary: string,
    newComponentBoundary: string
  ): void {
    const oldContainer: ViewContainerRef = this.getContainerRefForContainerId(oldComponentBoundary)
    const newContainer: ViewContainerRef = this.getContainerRefForContainerId(newComponentBoundary)
    if (oldContainer == newContainer) {
      console.warn('Cannot move element: Component is already in the target container')
      return
    }

    const componentElementId = component.el.nativeElement.id
    const coRef = this.templateComponents.get(componentElementId)

    if (!coRef) {
      console.warn('Cannot move element: Component not found in templateComponents Map')
      return
    }

    const viewRef = coRef.hostView

    const indexOld = oldContainer.indexOf(viewRef)
    if (indexOld !== -1) {
      oldContainer.detach(indexOld)
    }
    newContainer.insert(viewRef)

    // reset position of component to prevent positioning outside of page
    coRef.instance.el.nativeElement.style.top = 0
    coRef.instance.el.nativeElement.style.left = 0
    this.updateElementSettings()
  }

  processLoadedTemplateStyles(stylesObj: any): void {
    const loadedStyles = {
      marginTop: 2,
      marginRight: 2,
      marginBottom: 2,
      marginLeft: 2,
      backgroundImage: '',
      backgroundColor: '#FFFFFF'
    }

    if (stylesObj['defaultFontSize'] == undefined) {
      stylesObj['defaultFontSize'] = this.defaultFontSize
    }
    if (stylesObj['fontFamily'] == undefined) {
      stylesObj['fontFamily'] = fontOptions[0].value
    }

    if (stylesObj != undefined) {
      if (stylesObj['margin-top'] != undefined) {
        loadedStyles['marginTop'] = stylesObj['margin-top']
      }
      if (stylesObj['margin-right'] != undefined) {
        loadedStyles['marginRight'] = stylesObj['margin-right']
      }
      if (stylesObj['margin-bottom'] != undefined) {
        loadedStyles['marginBottom'] = stylesObj['margin-bottom']
      }
      if (stylesObj['margin-left'] != undefined) {
        loadedStyles['marginLeft'] = stylesObj['margin-left']
      }
      if (stylesObj['background-image'] != undefined) {
        this.pdfPageSettings.setBackgroundImage(stylesObj['background-image'], this.testDataJson)
        loadedStyles['backgroundImage'] = this.pdfPageSettings.getBackgroundImagePlaceholder()
      }
      if (stylesObj['background-color'] != undefined && stylesObj['background-color'] !== '') {
        loadedStyles['backgroundColor'] = stylesObj['background-color']
      }
      // if (stylesObj['size'] != undefined) {
      //   loadedStyles['size'] = stylesObj['size'] == Orientation.PORTRAIT ? Orientation.PORTRAIT : Orientation.LANDSCAPE
      // }
    }

    this.pageLayoutFormSubscription = this.pdfPageSettings.pageLayoutForm.valueChanges.subscribe((nextValues) => {
      this.calculatePageDimensions()
      this.setPageLayoutProperties(nextValues)
    })

    this.pdfPageSettings.pageLayoutForm.patchValue(loadedStyles)
    this.pdfPageSettings.pageLayoutForm.controls['header'].setValue(stylesObj['header'])
    this.pdfPageSettings.pageLayoutForm.controls['footer'].setValue(stylesObj['footer'])
    this.pdfPageSettings.pageLayoutForm.controls['headerHeight'].setValue(stylesObj['headerHeight'])
    this.pdfPageSettings.pageLayoutForm.controls['footerHeight'].setValue(stylesObj['footerHeight'])

    //capture template for undo/redo
    this.pdfPageSettings.pageLayoutForm.valueChanges
      .pipe(
        debounceTime(1500), // Adjust the time (in milliseconds) as needed
        distinctUntilChanged((prev, curr) => {
          return JSON.stringify(prev) === JSON.stringify(curr)
        })
      )
      .subscribe(() => {
        if (this.cachedPageSettingsFormValues !== JSON.stringify(this.pdfPageSettings.pageLayoutForm.getRawValue())) {
          this.captureUndoRedoTemplate()
          this.cachedPageSettingsFormValues = JSON.stringify(this.pdfPageSettings.pageLayoutForm.getRawValue())
        }
      })

    this.cachedPageSettingsFormValues = JSON.stringify(this.pdfPageSettings.pageLayoutForm.getRawValue())
  }

  private setPageLayoutProperties(nextValues: Partial<any>): void {
    const headerBoundaryOption = this.elementBoundaryOptions.find((option) => option.value === '#header')
    if (headerBoundaryOption) {
      headerBoundaryOption.disabled = !nextValues['header']
    }

    const footerBoundaryOption = this.elementBoundaryOptions.find((option) => option.value === '#footer')
    if (footerBoundaryOption) {
      footerBoundaryOption.disabled = !nextValues['footer']
    }

    this.elementBoundaryOptions = [...this.elementBoundaryOptions]

    const showHeader = nextValues['header']
    this.pdfHeaderWrapperContainer.nativeElement.style.setProperty('display', showHeader ? '' : 'none')
    const headerHeight = showHeader ? this.cmToPxFactor * nextValues['headerHeight'] + 'px' : '0px'
    this.pdfHeaderWrapperContainer.nativeElement.style.setProperty('height', headerHeight)

    const showFooter = nextValues['footer']
    this.pdfFooterWrapperContainer.nativeElement.style.setProperty('display', showFooter ? '' : 'none')
    const footerHeight = showFooter ? this.cmToPxFactor * nextValues['footerHeight'] + 'px' : '0px'
    this.pdfFooterWrapperContainer.nativeElement.style.setProperty('height', footerHeight)

    this.wspdfEditorInnerPageMarker.nativeElement.style.setProperty('top', headerHeight)
    this.wspdfEditorInnerPageMarker.nativeElement.style.setProperty('left', 0)
    const wspdfEditorInnerPageMarkerHeight = `calc(100% - ${headerHeight} - ${footerHeight})`
    this.wspdfEditorInnerPageMarker.nativeElement.style.setProperty('height', wspdfEditorInnerPageMarkerHeight)

    this.setPdfWrapperStyles(
      this.pdfContentWrapper.nativeElement.style,
      nextValues['marginBottom'],
      nextValues['marginTop'],
      nextValues['marginLeft'],
      nextValues['marginRight'],
      showHeader ? nextValues['headerHeight'] : 0,
      showFooter ? nextValues['footerHeight'] : 0
    )

    if (
      this.pdfPageSettings.pageLayoutForm.get('backgroundImage')?.valid &&
      nextValues['backgroundImage'] != undefined &&
      nextValues['backgroundImage'] !== 'none'
    ) {
      this.pdfPageSettings.setBackgroundImage(nextValues['backgroundImage'] || '', this.testDataJson)
      const backgroundImageUrl = `url(${this.pdfPageSettings.getBackgroundImage()})`

      this.wspdfEditorPageMarker.nativeElement.style.setProperty('background-image', backgroundImageUrl)
      this.wspdfEditorPageMarker.nativeElement.style.setProperty('background-size', 'cover')
    }

    const backgroundColor = nextValues['backgroundColor']
    this.wspdfEditorPageMarker.nativeElement.style.setProperty('background-color', backgroundColor)
  }

  setPdfWrapperStyles(
    wrapperStyle: CSSStyleDeclaration,
    marginBottom: number,
    marginTop: number,
    marginLeft: number,
    marginRight: number,
    headerHeight: number,
    footerHeight: number,
    withoutInnerPageMarker = false
  ): void {
    let top = this.cmToPxFactor * marginTop
    let height = 'calc(100% - ' + this.cmToPxFactor * (marginTop + marginBottom) + 'px)'
    const left = this.cmToPxFactor * marginLeft + 'px'
    const width = `calc(100% - ${this.cmToPxFactor * (marginLeft + marginRight)}px)`

    if (!withoutInnerPageMarker) {
      top -= this.cmToPxFactor * headerHeight
      height = `${
        this.wspdfEditorPageMarker.nativeElement.offsetHeight -
        this.cmToPxFactor * marginTop -
        this.cmToPxFactor * marginBottom
      }px`
    }

    wrapperStyle.setProperty('top', `${top}px`)
    wrapperStyle.setProperty('left', left)
    wrapperStyle.setProperty('width', width)
    wrapperStyle.setProperty('height', height)
  }

  textTransformOptions: SelectOption[] = [
    { label: 'None', value: 'none' },
    { label: 'Uppercase', value: 'uppercase' },
    { label: 'Lowercase', value: 'lowercase' }
  ]

  wordBreakOptions: SelectOption[] = [
    { label: 'Normal', value: 'normal' },
    { label: 'Break all', value: 'break-all' },
    { label: 'Keep all', value: 'keep-all' },
    { label: 'Break word', value: 'break-word' }
  ]

  pxToCmNodeStyles(nodeToRecalc: any): any {
    if (
      nodeToRecalc.id == 'wspdfEditorPageMarker' ||
      nodeToRecalc.id == 'content-wrapper' ||
      nodeToRecalc.id == 'header' ||
      nodeToRecalc.id == 'footer' ||
      nodeToRecalc.classList.contains('wspdf-editable-element')
    ) {
      const nodesToIgnore: any[] = []
      nodeToRecalc.childNodes.forEach((currentNode: any, idx: any) => {
        if (currentNode.nodeName != '#comment') {
          if (currentNode.attributes == undefined || currentNode.attributes['wspdf-ignore'] != undefined) {
            nodesToIgnore.push(currentNode)
          }
          this.pxToCmNodeStyles(currentNode)
        }
      })

      //nodeToRecalc.remove()
      nodesToIgnore.forEach((x) => {
        x.remove()
      })

      if (nodeToRecalc.nodeName === 'APP-WSPDF-DATASET') {
        this.pxToCmNodeStylesDataset(nodeToRecalc)
      }

      this.overwritePxToCmNodeStyles(nodeToRecalc)
    }
  }

  /**
   * Recursively processes nodes and converts pixel values to centimeters
   * in column styles when a 'col' element is found.
   * @param nodeToRecalc - The root node to begin the traversal
   */
  pxToCmNodeStylesDataset(nodeToRecalc: any) {
    nodeToRecalc.childNodes.forEach((child: any) => {
      if (child.nodeName == 'COL') {
        this.overwritePxToCmNodeStyles(child)
      } else {
        this.pxToCmNodeStylesDataset(child)
      }
    })
  }

  /**
   * Overwrites pixel (px) and point (pt) values to centimeters (cm) in the styles of a given node.
   * @param nodeToRecalc - The DOM node to convert pixel and point values to centimeters.
   */
  overwritePxToCmNodeStyles(nodeToRecalc: any) {
    Object.entries(nodeToRecalc.style).forEach((currentProp, idx) => {
      if (nodeToRecalc.style.hasOwnProperty(idx)) {
        const prop: string = currentProp[1] as string
        let prevVal = nodeToRecalc.style[prop]
        if (prevVal) {
          if (prevVal.includes('px')) {
            prevVal = prevVal.substring(0, prevVal.length - 2)
            nodeToRecalc.style[prop] = prevVal * this.pxToCmFactor + 'cm'
          } else if (prevVal.includes('pt')) {
            prevVal = prevVal.substring(0, prevVal.length - 2)
            nodeToRecalc.style[prop] = ((prevVal * 4) / 3) * this.pxToCmFactor + 'cm'
          }
        }
      }
    })
  }

  getTemplateContentFromEditor(): string {
    const currentTemplate = this.wspdfEditorPageMarker.nativeElement.cloneNode(true)
    const contentWrapperElement = currentTemplate.querySelectorAll('#content-wrapper')[0]

    //InnerPageMarker only needed in Editor for having a boundary between Header and Footer
    const elementToRemove = currentTemplate.querySelectorAll('#wspdfEditorInnerPageMarker')[0]
    elementToRemove.removeChild(contentWrapperElement)

    const pageLayoutSettings = this.pdfPageSettings.pageLayoutForm.value
    const showFooter = pageLayoutSettings['footer']
    const showHeader = pageLayoutSettings['header']

    if (!showHeader) {
      pageLayoutSettings['headerHeight'] = 0
    }
    if (!showFooter) {
      pageLayoutSettings['footerHeight'] = 0
    }

    this.setPdfWrapperStyles(
      contentWrapperElement.style,
      pageLayoutSettings['marginBottom'] || 0,
      pageLayoutSettings['marginTop'] || 0,
      pageLayoutSettings['marginLeft'] || 0,
      pageLayoutSettings['marginRight'] || 0,
      pageLayoutSettings['headerHeight'] || 0,
      pageLayoutSettings['footerHeight'] || 0,
      true
    )

    currentTemplate.removeChild(elementToRemove)
    currentTemplate.insertBefore(contentWrapperElement, currentTemplate.querySelectorAll('#footer')[0])

    let contentWidth = pageLayoutSettings['marginLeft'] ? pageLayoutSettings['marginLeft'] : 0
    const contentOffsetLeft = contentWidth
    contentWidth += pageLayoutSettings['marginRight'] || 0

    let contentHeight = pageLayoutSettings['marginTop'] ? pageLayoutSettings['marginTop'] : 0
    const contentOffsetTop =
      contentHeight - (pageLayoutSettings['header'] ? 0 : pageLayoutSettings['headerHeight'] || 0)
    contentHeight += pageLayoutSettings['marginBottom'] || 0

    contentWrapperElement.setAttribute(
      'style',
      'top: ' +
        contentOffsetTop +
        'cm; left: ' +
        contentOffsetLeft +
        'cm; width: ' +
        (DinA4.WIDTH / 10 - contentWidth) +
        'cm; height: ' +
        (DinA4.HEIGHT / 10 - contentHeight) +
        'cm;'
    )

    this.pxToCmNodeStyles(currentTemplate)

    // replace all <div> with \n for text elements to keep line breaks
    const textElements = currentTemplate.querySelectorAll('.wspdf-element__text')
    textElements.forEach((currentNode: any, idx: any) => {
      currentNode.innerHTML = currentNode.innerHTML.replace(/\n/gm, '<br>')
      currentNode.innerHTML = currentNode.innerHTML.replace(/<div>(.*?)<\/div>/gm, '<br>$1')
    })

    const editorOnlyElements = currentTemplate.querySelectorAll('.wspdf-editor-only')
    editorOnlyElements.forEach((currentNode: any, idx: any) => {
      currentNode.remove()
    })

    return currentTemplate.innerHTML
  }

  getTemplateStylesFromEditor(): string {
    return JSON.stringify({
      '@page': {
        'margin-top': this.pdfPageSettings.pageLayoutForm.value['marginTop'] || 0,
        'margin-right': this.pdfPageSettings.pageLayoutForm.value['marginRight'] || 0,
        'margin-bottom': this.pdfPageSettings.pageLayoutForm.value['marginBottom'] || 0,
        'margin-left': this.pdfPageSettings.pageLayoutForm.value['marginLeft'] || 0,
        'counter-increment': 'page',
        'counter-reset': 'page 1',
        header: this.pdfPageSettings.pageLayoutForm.value['header'],
        footer: this.pdfPageSettings.pageLayoutForm.value['footer'],
        headerHeight: this.pdfPageSettings.pageLayoutForm.value['headerHeight'],
        footerHeight: this.pdfPageSettings.pageLayoutForm.value['footerHeight'],
        'background-image': this.pdfPageSettings.pageLayoutForm.get('backgroundImage')?.valid
          ? this.pdfPageSettings.pageLayoutForm.value['backgroundImage'] || 'none'
          : 'none',
        'background-color': this.pdfPageSettings.pageLayoutForm.value['backgroundColor'] || '#FFFFFF'
      }
    })
  }

  saveTemplateChanges(): void {
    if (!this.templateToEdit) {
      return
    }

    // set template content to editor content before updating
    this.templateToEdit.content = this.getTemplateContentFromEditor()
    this.templateToEdit.styles = this.getTemplateStylesFromEditor()
    this.templateToEdit.testData = JSON.stringify(this.testDataJson)

    // Check if template is same as last saved version
    if (this.isTemplateUnchangedFromLastSavedVersion()) {
      // const messageDetail = 'template.message.saveNoChanges'
      // this.notifications.createNotification(this.translate.instant(messageDetail), {
      //   severity: 'warning'
      // })
      this.pdfService.setTemplateIsReadyToPreview(true)
      this.draftMode = false
    } else {
      this.save.emit(this.templateToEdit)
    }
  }

  templateIsModified() {
    return this.draftMode || !this.isTemplateUnchangedFromLastSavedVersion()
  }

  isTemplateUnchangedFromLastSavedVersion() {
    return (
      this.templateToEdit?.testData === this.latestSavedTemplate.testData &&
      this.template?.styles === this.latestSavedTemplate.styles &&
      this.templateToEdit?.content === this.latestSavedTemplate.content
    )
  }

  toggleElementVisibility(element: ComponentRef<WspdfElementComponent>) {
    element.instance.hidden = !element.instance.hidden
    this.captureUndoRedoTemplate()
  }

  // copyElement(elementToBeCopied?: ComponentRef<WspdfElementComponent>): void {
  //   let coref: any
  //   if (elementToBeCopied) {
  //     this.currentSelectedElement = elementToBeCopied
  //   }
  //   const element = this.currentSelectedElement?.instance.el.nativeElement
  //
  //   switch (this.currentSelectedElement?.instance.type) {
  //     case ComponentType.STATICLABEL:
  //       coref = this.addStaticLabel(element.querySelector('span').innerHTML)
  //       break
  //     case ComponentType.LABEL:
  //       coref = this.addLabel(element.querySelector('span').innerHTML)
  //       break
  //     case ComponentType.IMAGE:
  //       coref = this.addImage(element.querySelector('img').src)
  //       break
  //     case ComponentType.QRCODE:
  //       coref = this.addQrCode(element.querySelector('qrcode').getAttribute('ng-reflect-qrdata'))
  //       break
  //     // case ComponentType.SECTION:
  //     //   coref = this.addEditorSection()
  //     //   break;
  //   }
  //   coref.instance.el.nativeElement.setAttribute('style', element.getAttribute('style'))
  //   coref.instance.el.nativeElement.style.left = element.offsetLeft + 15 + 'px'
  //   coref.instance.el.nativeElement.style.top = element.offsetTop + 15 + 'px'
  //
  //   this.setTemplateComponentsAsArray()
  // }

  removeElementClicked(eventId: string) {
    this.removeElement(eventId)
    setTimeout(() => {
      this.captureUndoRedoTemplate()
    }, 500)
  }

  removeElement(elementId: string): void {
    this.deselectCurrentElementIfNecessary(elementId, true)
    const componentToBeRemoved = this.templateComponents.get(elementId)

    if (!componentToBeRemoved) {
      return
    }

    // unregister EventListener for mousedown to avoid memory leaks
    componentToBeRemoved.instance.el.nativeElement.removeEventListener('mousedown', (event: Event) =>
      this.elementClicked(event)
    )
    // destroy component to remove it from the DOM
    componentToBeRemoved.destroy()
    // remove component from templateComponents map
    this.templateComponents.delete(elementId)
    this.setTemplateComponentsAsArray()
  }

  highlightElement(elementId: string): void {
    this.deselectCurrentElementIfNecessary(elementId)
    this.selectElement(elementId)
  }

  previewPdf(): void {
    if (!this.template || !this.templateToEdit) {
      return
    }

    this.pdfService.setTemplateIsReadyToPreview(false)

    // Wait until the current template is saved then create preview
    let reviewSubscription: Subscription | undefined

    reviewSubscription = this.pdfService.getTemplateIsReadyToPreview().subscribe((templateIsReadyToPreview) => {
      if (templateIsReadyToPreview) {
        this.createPreviewPdf()
        if (reviewSubscription) {
          reviewSubscription.unsubscribe()
        }
      }
    })

    this.saveTemplateChanges()
  }

  createPreviewPdf(): void {
    const templateId = this.template!.wsPdfTemplateId
    const testData = this.templateToEdit!.testData

    const isPreview = true
    this.pdfService
      .createPdfFromTemplate(templateId, testData, isPreview)
      .pipe(first())
      .subscribe({
        next: (result: any) => {
          const blob = new Blob([result], { type: 'application/pdf' })
          const downloadURL = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = downloadURL
          link.download = 'preview.pdf'
          link.click()
          window.open(downloadURL)
        },
        error: () => {
          // const errorMessage: Message = {
          //   severity: 'error',
          //   detail: this.translate.instant('coupon.download_error')
          // }
          // this.loadingResponseMessages = [errorMessage]
          // this.messageLoading = true
        }
      })
  }

  openEditTestDataDialog(): void {
    const editTestDataDialogConfig = new MatDialogConfig()

    editTestDataDialogConfig.disableClose = true
    editTestDataDialogConfig.autoFocus = true
    editTestDataDialogConfig.minWidth = '50vw'
    editTestDataDialogConfig.data = this.testDataJson

    const editTestDataDialogRef = this.dialog.open(PdfEditTestDataDialogComponent, editTestDataDialogConfig)

    editTestDataDialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.testDataJson = response
        this.saveTemplateChanges()
      }
    })
  }

  toggleHelpingGrid(): void {
    this.enableHelpingGrid = this.enableHelpingGrid == 'enable-helping-grid' ? '' : 'enable-helping-grid'
  }

  openDatasetChoiceDialog(): void {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.disableClose = false
    dialogConfig.autoFocus = true
    dialogConfig.width = '400px'
    dialogConfig.maxHeight = '50vh'

    const datasetChoices = Object.keys(this.testDataJson)
      .filter(
        (key: string) =>
          this.testDataJson[key] instanceof Object &&
          'columns' in this.testDataJson[key] &&
          'headers' in this.testDataJson[key] &&
          'data' in this.testDataJson[key]
      )
      .map((filteredKey) => {
        return { label: filteredKey, value: filteredKey }
      })
    dialogConfig.data = datasetChoices

    const dialogRef = this.dialog.open(PdfDatasetChoiceDialogComponent, dialogConfig)

    dialogRef.afterClosed().subscribe((datasetName) => {
      if (!datasetName) {
        return
      }
      this.addReportComponent(ComponentType.DATASET, false, datasetName)
    })
  }

  openHelpDialog(): void {
    console.log('open help dialog pending...')
  }

  // @HostListener('window:keydown.control.d', ['$event']) copyKeyboadEvent(event: KeyboardEvent) {
  //   if (!this.currentSelectedElement) {
  //     return
  //   }
  //   event.preventDefault()
  //   this.copyElement()
  // }

  @HostListener('window:keyup.delete', ['$event']) deleteKeyboadEvent(event: KeyboardEvent) {
    const activeElementIsInput = ['INPUT', 'TEXTAREA'].includes(document.activeElement?.tagName || '')
    const activeElementIsContentEditable = document.activeElement?.getAttribute('contenteditable') === 'true'

    if (!this.currentSelectedElement || activeElementIsInput || activeElementIsContentEditable) {
      return
    }
    event.preventDefault()
    // delete element from template
    this.removeElementClicked(this.currentSelectedElement.instance.el.nativeElement.id)

    // TODO: move this to where the element is removed and only call when needed
    // Note: reset after deleting throws error in console!

    // this.elementSettingsForm.reset()
    // this.elementTextSettingsForm.reset()
  }

  @HostListener('window:keydown.control.s', ['$event']) saveChangesKeyboardEvent(event: KeyboardEvent) {
    event.preventDefault()
    this.saveTemplateChanges()
  }
  @HostListener('window:keydown.control.p', ['$event']) previewKeyboardEvent(event: KeyboardEvent) {
    event.preventDefault()
    this.previewPdf()
  }

  moveCurrentSelectedElement(offsetLeft = 0, offsetTop = 0): void {
    if (!this.currentSelectedElement) {
      return
    }

    //avoid exceeding boundary
    const boundaryElement = this.getBoundaryElementOfCurrentSelectedElement()
    if (boundaryElement) {
      const boundaryPosition = boundaryElement.nativeElement.getBoundingClientRect()
      const currentSelectedElementPosition =
        this.currentSelectedElement.instance.el.nativeElement.getBoundingClientRect()

      if (offsetTop < 0) {
        const distanceTop = boundaryPosition.top - currentSelectedElementPosition.top
        offsetTop = this.adjustOffset(distanceTop, offsetTop)
      } else if (offsetTop > 0) {
        const distanceBot = boundaryPosition.bottom - currentSelectedElementPosition.bottom
        offsetTop = this.adjustOffset(distanceBot, offsetTop)
      } else if (offsetLeft < 0) {
        const distanceLeft = boundaryPosition.left - currentSelectedElementPosition.left
        offsetLeft = this.adjustOffset(distanceLeft, offsetLeft)
      } else if (offsetLeft > 0) {
        const distanceRight = boundaryPosition.right - currentSelectedElementPosition.right
        if (Math.abs(distanceRight) < Math.abs(offsetLeft)) {
          offsetLeft = this.adjustOffset(distanceRight, offsetLeft)
        }
      }
    }

    const component = this.currentSelectedElement.instance.el.nativeElement
    const y = component.offsetTop + offsetTop
    const x = component.offsetLeft + offsetLeft
    component.style.top = y + 'px'
    component.style.left = x + 'px'
    this.updateElementPositionInElementSettingsForm(x, y)
  }

  adjustOffset(distance: number, offset: number): number {
    if (Math.abs(distance) < Math.abs(offset)) {
      return distance
    }
    return offset
  }

  handleArrowKeysPress(event: KeyboardEvent, offsetLeft = 0, offsetTop = 0): void {
    const activeElementIsInput = ['INPUT', 'TEXTAREA'].includes(document.activeElement?.tagName || '')
    const activeElementIsContentEditable = document.activeElement?.getAttribute('contenteditable') === 'true'

    if (this.currentSelectedElement && !activeElementIsInput && !activeElementIsContentEditable) {
      event.preventDefault()
      if (this.isElementInsideBoundary(offsetLeft, offsetTop)) {
        this.moveCurrentSelectedElement(offsetLeft, offsetTop)
      }
    }
  }

  isElementInsideBoundary(offsetLeft = 0, offsetTop = 0) {
    if (!this.currentSelectedElement) {
      return false
    }
    const elementBoundary = this.currentSelectedElement.instance.elementBoundary
    if (elementBoundary) {
      const currentSelectedElementPosition =
        this.currentSelectedElement.instance.el.nativeElement.getBoundingClientRect()

      const boundaryElement: ElementRef | undefined = this.getBoundaryElementOfCurrentSelectedElement()

      if (boundaryElement) {
        const boundaryPosition = boundaryElement.nativeElement.getBoundingClientRect()
        const isOutsideLeftBoundary = offsetLeft < 0 && currentSelectedElementPosition.left <= boundaryPosition.left
        const isOutsideRightBoundary = offsetLeft > 0 && currentSelectedElementPosition.right >= boundaryPosition.right
        const isOutsideTopBoundary = offsetTop < 0 && currentSelectedElementPosition.top <= boundaryPosition.top
        const isOutsideBottomBoundary =
          offsetTop > 0 && currentSelectedElementPosition.bottom >= boundaryPosition.bottom

        if (isOutsideLeftBoundary || isOutsideRightBoundary || isOutsideTopBoundary || isOutsideBottomBoundary) {
          return false
        }
      }
    }
    return true
  }

  getBoundaryElementOfCurrentSelectedElement() {
    if (!this.currentSelectedElement) {
      return undefined
    }
    switch (this.currentSelectedElement.instance.elementBoundary) {
      case this.HEADER_BOUNDARY:
        return this.pdfHeaderWrapperContainer
      case this.FOOTER_BOUNDARY:
        return this.pdfFooterWrapperContainer
      case this.WSPDF_EDITOR_PAGE_MARKER_BOUNDARY:
        return this.wspdfEditorInnerPageMarker
      case this.WSPDF_EDITOR_INNER_PAGE_MARKER_BOUNDARY:
        return this.wspdfEditorInnerPageMarker
      case this.CONTENT_WRAPPER_BOUNDARY:
        return this.pdfContentWrapper
      default:
        return undefined
    }
  }

  @HostListener('window:keydown.arrowUp', ['$event']) arrowUpKeyboardEvent(event: KeyboardEvent) {
    this.handleArrowKeysPress(event, 0, -1)
  }

  @HostListener('window:keydown.arrowDown', ['$event']) arrowDownKeyboardEvent(event: KeyboardEvent) {
    this.handleArrowKeysPress(event, 0, 1)
  }

  @HostListener('window:keydown.arrowLeft', ['$event']) arrowLeftKeyboardEvent(event: KeyboardEvent) {
    this.handleArrowKeysPress(event, -1, 0)
  }

  @HostListener('window:keydown.arrowRight', ['$event']) arrowRightKeyboardEvent(event: KeyboardEvent) {
    this.handleArrowKeysPress(event, 1, 0)
  }

  @HostListener('window:keydown.shift.arrowUp', ['$event']) arrowUpShiftKeyboardEvent(event: KeyboardEvent) {
    this.handleArrowKeysPress(event, 0, -10)
  }

  @HostListener('window:keydown.shift.arrowDown', ['$event']) arrowDownShiftKeyboardEvent(event: KeyboardEvent) {
    this.handleArrowKeysPress(event, 0, 10)
  }

  @HostListener('window:keydown.shift.arrowLeft', ['$event']) arrowLeftShiftKeyboardEvent(event: KeyboardEvent) {
    this.handleArrowKeysPress(event, -10, 0)
  }

  @HostListener('window:keydown.shift.arrowRight', ['$event']) arrowRightShiftKeyboardEvent(event: KeyboardEvent) {
    this.handleArrowKeysPress(event, 10, 0)
  }

  @HostListener('window:keyup', ['$event'])
  arrowKeysKeyUpEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowRight':
        this.captureUndoRedoTemplate()
        break
    }
  }

  @HostListener('window:keydown.control.z', ['$event'])
  undo() {
    const activeElementIsInput = ['INPUT', 'TEXTAREA'].includes(document.activeElement?.tagName || '')
    const activeElementIsContentEditable = document.activeElement?.getAttribute('contenteditable') === 'true'
    if (activeElementIsInput || activeElementIsContentEditable) {
      return
    }

    this.restoringTemplate = true
    const undoTemplate = this.wsPdfUndoRedoService.undo()
    if (undoTemplate) {
      this.resetTemplateComponents()
      this.setTemplateToEdit(undoTemplate)
    }
    this.restoringTemplate = false
  }

  @HostListener('window:keydown.control.y', ['$event'])
  @HostListener('window:keydown.control.shift.z', ['$event'])
  redo() {
    const activeElementIsInput = ['INPUT', 'TEXTAREA'].includes(document.activeElement?.tagName || '')
    const activeElementIsContentEditable = document.activeElement?.getAttribute('contenteditable') === 'true'
    if (activeElementIsInput || activeElementIsContentEditable) {
      return
    }

    this.restoringTemplate = true
    const redoTemplate = this.wsPdfUndoRedoService.redo()
    if (redoTemplate) {
      this.resetTemplateComponents()
      this.setTemplateToEdit(redoTemplate)
    }
    this.restoringTemplate = false
  }

  loadingTemplateHistory = false

  loadTemplateHistory() {
    this.loadingTemplateHistory = true
    this.latestSavedTemplate = Object.assign({}, this.template)
    this.pdfService
      .historyOfTemplate(this.template?.wsPdfTemplateId || 0)
      .pipe(first())
      .subscribe({
        next: (templateHistory: WspdfTemplateHistory[]) => {
          this.templateHistory = templateHistory

          // add latest saved template to history
          const latestHistory = new WspdfTemplateHistory()
          latestHistory.name = this.latestSavedTemplate.name
          latestHistory.styles = this.latestSavedTemplate.styles
          latestHistory.content = this.latestSavedTemplate.content
          latestHistory.testData = this.latestSavedTemplate.testData
          latestHistory.created = new Date()

          this.templateHistory.unshift(latestHistory)
        },
        complete: () => {
          this.loadingTemplateHistory = false
        }
      })
  }

  resetTemplateComponents() {
    this.templateComponentsArray.forEach((component) => {
      this.removeElement(component.instance.el.nativeElement.id)
    })
    this.pdfContentContainer.clear()
  }

  onHistoryVersionSelect(historyEntry: WspdfTemplateHistory) {
    const dialog = this.openConfirmUnsavedChangesDialog()
    dialog.afterClose$.subscribe((e: any) => {
      switch (e) {
        case 'confirm':
          this.handleHistoryTemplateChange(historyEntry)
          this.wsPdfUndoRedoService.clearStack()
          setTimeout(() => {
            this.captureUndoRedoTemplate()
          }, 100)
      }
    })
  }

  handleHistoryTemplateChange(wspdfTemplateHistory: WspdfTemplateHistory) {
    if (!wspdfTemplateHistory || !this.template) {
      return
    }

    this.template.content = wspdfTemplateHistory.content
    this.template.styles = wspdfTemplateHistory.styles
    this.template.testData = wspdfTemplateHistory.testData

    this.resetTemplateComponents()
    this.setTemplateToEdit(this.template)
  }

  openConfirmUnsavedChangesDialog() {
    return this.wsDialogService.open({
      dialogTitleText: 'unsavedChanges',
      dialogContentText: 'unsavedChangesLost'
    })
  }
}
