import { Injectable } from '@angular/core'
import { AbstractPlatformService } from '@ws-core/core-library'

@Injectable({
  providedIn: 'root'
})
export class PlatformService extends AbstractPlatformService {
  breakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536
  }
  width = document.body.clientWidth
  height = document.body.clientHeight

  constructor() {
    super()
    window.addEventListener('resize', this.calculateWindowSizes)
  }

  calculateWindowSizes = () => {
    this.width = document.body.clientWidth
    this.height = document.body.clientHeight
  }
  get isMobile(): boolean {
    return this.width < this.breakpoints.md
  }
  get hasTouch(): boolean {
    return navigator.maxTouchPoints > 0
  }
  get size(): 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' {
    if (this.width < this.breakpoints.sm) return 'xs'
    else if (this.width > this.breakpoints.sm && this.width < this.breakpoints.md) return 'sm'
    else if (this.width > this.breakpoints.md && this.width < this.breakpoints.lg) return 'md'
    else if (this.width > this.breakpoints.lg && this.width < this.breakpoints.xl) return 'lg'
    else if (this.width > this.breakpoints.xl && this.width < this.breakpoints['2xl']) return 'xl'
    else return '2xl'
  }
}
