<div
  style="width: 100%; height: 100%"
  [attr.data-qrdata]="qrData"
  class="h-full block cursor-move"
  [cdkDragBoundary]="elementBoundary"
  (cdkDragStarted)="repositionEditorElementComponentStarted($event)"
  (cdkDragEnded)="repositionEditorElementComponent($event)"
  cdkDrag
>
  <span
    *ngIf="inFocus"
    class="h-full dragHandle corner"
    cdkDrag
    (cdkDragStarted)="resizeDragStarted($event)"
    (cdkDragEnded)="resizeDragEnded($event)"
    (cdkDragMoved)="resizeDragMoved('corner', $event)"
  ></span>
  <qrcode class="h-full" elementType="img" [qrdata]="qrData" [width]="widthQrCode" errorCorrectionLevel="M"></qrcode>
</div>
