import { ApplicationRef, Injectable, ViewContainerRef } from '@angular/core'
import { DialogContainerComponent } from './dialog-container/dialog-container.component'
import { DialogComponent } from './dialog/dialog.component'
import { DialogData } from '../../types/notifications/dialog-data'
import { TranslateService } from '@ngx-translate/core'

/**
 * This service provides all methods to open dynamically created dialogs.
 * @example
 * constructor(private dialogService: WsDialogService) {}
 * this.dialogService.open({
 *  dialogTitleText: 'Title for the dialog',
 *  dialogContentText: 'Text to display in the dialog',
 *})
 *
 *
 * You can also pass a template to the dialog:
 * @example
 * <ng-template #simpleDialogContentTemplateRef>
 *   <!-- Here you can place any HTML element you want to display in the dialog. -->
 * </ng-template>
 * '@ViewChild('simpleDialogContentTemplateRef', { static: true }) simpleDialogContentTemplateRef!: TemplateRef<Element>'
 * this.dialogService.open({
 *       dialogContentTemplateRef: this.simpleDialogContentTemplateRef
 * })
 *
 * You can subscribe to the afterClose event to get the action that was performed by the user:
 * @example
 * dialog.afterClose.subscribe((e: any) => {
 *  console.log('dialog closed with action: ', e)
 * })
 */
@Injectable({
  providedIn: 'root'
})
export class WsDialogService {
  /**
   * The dialog container reference.
   * @private
   */
  private static overlayRef: DialogContainerComponent

  /**
   * The constructor for the dialog service.
   * @param applicationRef The application reference
   * @param translate The translate service
   */
  constructor(private applicationRef: ApplicationRef, protected translate: TranslateService) {
    this.initDialogContainer()
  }

  /**
   * Initializes the dialog container by creating a new instance of the dialog container component.
   */
  initDialogContainer() {
    if (WsDialogService.overlayRef) return

    const applicationRootComponent = this.applicationRef.components[0]
    if (!applicationRootComponent) return

    const rootViewContainerRef = applicationRootComponent.injector.get(ViewContainerRef)
    const componentRef = rootViewContainerRef.createComponent(DialogContainerComponent)
    WsDialogService.overlayRef = componentRef.instance
  }

  /**
   * Opens a dialog with the given dialog data.
   * @param dialogData The dialog data
   */
  open(dialogData?: DialogData): DialogComponent {
    if (!WsDialogService.overlayRef) {
      this.initDialogContainer()
    }

    return WsDialogService.overlayRef.openDialog(dialogData ? dialogData : {})
  }

  confirm(key: string | Array<string>, interpolateParams?: Object): DialogComponent {
    return this.open({
      dialogContentText: this.translate.instant(key, interpolateParams),
      dialogActions: [
        { label: 'cancel', action: 'cancel', buttonType: 'secondary' },
        { label: 'confirm', action: 'confirm', buttonType: 'primary' }
      ]
    })
  }
}
