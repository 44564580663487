import { Component, ViewChild } from '@angular/core'
import { DialogDirective } from '../../../directives/dialog.directive'
import { DialogData } from '../../../types/notifications/dialog-data'
import { DialogComponent } from '../dialog/dialog.component'

@Component({
  selector: 'ws-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss']
})
export class DialogContainerComponent {
  @ViewChild(DialogDirective, { static: true }) dialogContainerHost!: DialogDirective
  constructor() {}

  openDialog(dialogData: DialogData): DialogComponent {
    const componentRef = this.dialogContainerHost.viewContainerRef.createComponent<DialogComponent>(DialogComponent)

    if (dialogData.dialogTitleText) {
      componentRef.instance.dialogTitleText = dialogData.dialogTitleText
    }
    if (dialogData.dialogContentText) {
      componentRef.instance.dialogContentText = dialogData.dialogContentText
    }
    if (dialogData.dialogActions) {
      componentRef.instance.dialogActions = dialogData.dialogActions
    }
    if (dialogData.dialogTitleTemplateRef) {
      componentRef.instance.dialogTitleTemplateRef = dialogData.dialogTitleTemplateRef
    }
    if (dialogData.dialogContentTemplateRef) {
      componentRef.instance.dialogContentTemplateRef = dialogData.dialogContentTemplateRef
    }
    if (dialogData.dialogActionsTemplateRef) {
      componentRef.instance.dialogActionsTemplateRef = dialogData.dialogActionsTemplateRef
    }

    if (dialogData.dialogOptions) {
      componentRef.instance.dialogOptions = dialogData.dialogOptions
    }

    componentRef.instance.afterClose.subscribe(() => {
      setTimeout(() => {
        componentRef.destroy()
      }, 300)
    })

    return componentRef.instance
  }
}
