<h1 mat-dialog-title>{{ 'template.create' | translate }}</h1>
<mat-dialog-content [formGroup]="createTemplateForm">
  <ws-form-input label="{{ 'template.name' | translate }}" formControlName="templateName"></ws-form-input>

  <!--  <div id="orientation" class="flex flex-col">-->
  <!--    <mat-label> {{ 'template.settings.orientation' | translate }} </mat-label>-->
  <!--    <mat-radio-group formControlName="size" aria-label="Select an option">-->
  <!--      <mat-radio-button value="{{ Orientation.PORTRAIT }}">{{-->
  <!--        'template.settings.portrait' | translate-->
  <!--      }}</mat-radio-button>-->
  <!--      <mat-radio-button value="{{ Orientation.LANDSCAPE }}">{{-->
  <!--        'template.settings.landscape' | translate-->
  <!--      }}</mat-radio-button>-->
  <!--    </mat-radio-group>-->
  <!--  </div>-->
</mat-dialog-content>

<div mat-dialog-actions class="flex justify-between">
  <button
    mat-icon-button
    class="mat-button"
    (click)="save()"
    matTooltip="{{ 'template.save' | translate }}"
    matTooltipPosition="below"
  >
    <mat-icon>check</mat-icon>
  </button>
  <button
    mat-icon-button
    class="mat-button"
    (click)="close()"
    matTooltip="{{ 'template.close' | translate }}"
    matTooltipPosition="below"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
