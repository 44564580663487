import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { ComponentType } from '../wspdf-editor/wspdf-editor.component'
import { WspdfElementComponent } from '../wspdf-element/wspdf-element.component'

@Component({
  selector: 'app-wspdf-dataset',
  templateUrl: './wspdf-dataset.component.html',
  styleUrls: ['../wspdf-element/wspdf-element.component.scss', './wspdf-dataset.component.scss']
})
export class WspdfDatasetComponent extends WspdfElementComponent implements OnInit {
  public override type: ComponentType = ComponentType.DATASET
  public dataSetName = 'sales'
  public dataSetObjects: { [key: string]: any }[] = []
  public dataSetHeaders: string[] = []
  public dataSetColumns: string[] = []
  public dataSetColumnStyles: { [key: string]: any }[] = []

  @ViewChild('wspdfDatatable') wspdfDatatable!: ElementRef
  @ViewChildren('resizableElement', { read: ElementRef }) resizableElements!: QueryList<ElementRef>
  columnIsResizing = false
  initialXColumn = 0
  indexResizeElement = 0

  onMouseDown(event: any, index: number) {
    this.resizeDragStarted(event)
    this.indexResizeElement = index
    this.columnIsResizing = true
    this.initialXColumn = event.clientX

    const nextElement = this.dataSetColumnStyles[this.indexResizeElement + 1]

    // Temporarily remove width to prevent blocking of column resizing process
    if (nextElement) {
      delete nextElement['width']
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: any) {
    const resizableElement = this.resizableElements.get(this.indexResizeElement)
    if (this.columnIsResizing && this.initialXColumn !== 0 && resizableElement) {
      const offsetX = event.clientX - this.initialXColumn

      const currentWidth = resizableElement.nativeElement.offsetWidth
      const newWidth = currentWidth + offsetX

      this.dataSetColumnStyles[this.indexResizeElement]['width'] = newWidth + 'px'
    }
    this.initialXColumn = event.clientX
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    if (!this.columnIsResizing) return

    for (let i = 0; i < this.resizableElements.length; i++) {
      const element = this.resizableElements.get(i)

      // Give other columns width again
      if (i !== this.indexResizeElement && element) {
        this.dataSetColumnStyles[i]['width'] = element.nativeElement.offsetWidth + 'px'
      }
    }

    this.columnIsResizing = false
    this.isBeingResized = false
    this.initialXColumn = 0
    this.resizeDragEnded()
  }

  private getDataSetColumnsFromKeys(dataSetObjects: { [key: string]: any }[]) {
    if (dataSetObjects.length === 0) return []
    return Object.keys(dataSetObjects[0])
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.el.nativeElement.classList.add('wspdf-dataset')
  }

  setData(
    dataSetName: string,
    dataSetObjects: { [key: string]: any }[] = [],
    dataSetHeaders: string[],
    dataSetColumns: string[],
    columnStyles: { [key: string]: any }[] = []
  ): void {
    this.dataSetName = dataSetName
    this.dataSetObjects = dataSetObjects
    this.dataSetColumns = dataSetColumns
    this.dataSetHeaders = dataSetHeaders
    if (this.dataSetHeaders.length == 0) {
      this.dataSetHeaders = dataSetHeaders
    }
    if (columnStyles.length === 0) {
      this.dataSetColumns.forEach(() => columnStyles.push({}))
    }
    this.dataSetColumnStyles = columnStyles
  }
}
