export class WspdfTemplateHistory {
  wsPdfTemplateHistoryId = 0
  name = ''
  content = ''
  styles = ''
  testData = ''
  owner = -1
  user = { userId: 0, userName: '', subUserId: 0 }
  created: Date = new Date()
}
