import { Component, OnInit } from '@angular/core'
import { PdfService } from '../pdf.service'
import { first } from 'rxjs'
import { WspdfTemplate } from '../../types/wspdf-template'
import { SelectOption } from '@ws-core/core-library'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { PdfCreateTemplateDialogComponent } from '../pdf-create-template-dialog/pdf-create-template-dialog.component'
import { PdfDeleteTemplateDialogComponent } from '../pdf-delete-template-dialog/pdf-delete-template-dialog.component'
import { Router } from '@angular/router'

@Component({
  selector: 'app-wspdf-admin',
  templateUrl: './wspdf-admin.component.html',
  styleUrls: ['./wspdf-admin.component.scss']
})
export class WspdfAdminComponent implements OnInit {
  constructor(private pdfService: PdfService, public dialog: MatDialog, private router: Router) {}

  ngOnInit() {
    this.loadAllTemplates()
  }

  templatesOptions: SelectOption[] = []

  duplicateTemplate(templateId: number): void {
    this.pdfService
      .duplicateTemplate(templateId)
      .pipe(first())
      .subscribe({
        next: (response: WspdfTemplate) => {
          this.loadAllTemplates()
          this.handleResponse(200)
        },
        error: (err: any) => {
          this.handleResponse(500)
          console.log(err)
        }
      })
  }

  deleteTemplate(templateId: number | null): void {
    if (templateId != null) {
      this.pdfService
        .deleteTemplate(templateId)
        .pipe(first())
        .subscribe({
          next: (response: any) => {
            this.loadAllTemplates()
            this.handleResponse(200)
          },
          error: (err: any) => {
            this.handleResponse(500)
            console.log(err)
          }
        })
    }
  }

  openDeleteTemplateDialog(templateToDeleteId: number): void {
    const deleteTemplateDialogConfig = new MatDialogConfig()

    deleteTemplateDialogConfig.disableClose = true
    deleteTemplateDialogConfig.autoFocus = true
    deleteTemplateDialogConfig.maxWidth = '70vw'
    deleteTemplateDialogConfig.maxHeight = '90vh'
    deleteTemplateDialogConfig.data = this.templatesOptions.find(
      (templateOption) => templateOption.value === templateToDeleteId
    )?.label

    const deleteTemplateDialogRef = this.dialog.open(PdfDeleteTemplateDialogComponent, deleteTemplateDialogConfig)

    deleteTemplateDialogRef.afterClosed().subscribe((deleteTemplate) => {
      if (deleteTemplate) {
        this.deleteTemplate(templateToDeleteId)
        this.loadAllTemplates()
      }
    })
  }

  openCreateTemplateDialog(): void {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.disableClose = false
    dialogConfig.autoFocus = true
    dialogConfig.width = '400px'
    dialogConfig.maxHeight = '50vh'

    const dialogRef = this.dialog.open(PdfCreateTemplateDialogComponent, dialogConfig)

    dialogRef.afterClosed().subscribe((newTemplate: WspdfTemplate) => {
      console.log('NEW TEMPLATE CREATED | TEMPLATE: ', newTemplate)
      // this.templatesOptions = [this.templateToOptions([newTemplate])[0], ...this.templatesOptions]
      this.router.navigate(['/editor/template', newTemplate.wsPdfTemplateId])
    })
  }

  loadAllTemplates(): void {
    this.pdfService
      .loadAllTemplates()
      .pipe(first())
      .subscribe(
        (templates: WspdfTemplate[]) => {
          this.templatesOptions = this.templateToOptions(templates)
        },
        (err: any) => {
          // const errorMessage: Message = { severity: 'error', detail: this.translate.instant('template.loadTemplatesError') }
          // this.loadingTemplatesErrorMsg = [errorMessage]
        }
      )
  }

  public templateToOptions(templates: WspdfTemplate[]): SelectOption[] {
    return templates.map((template: WspdfTemplate) => {
      return { label: template.name, value: template.wsPdfTemplateId }
    })
  }

  private handleResponse(code: number) {
    console.log('HANDLE RESPONSE | CODE: ', code)
  }
}
