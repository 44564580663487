import { NgModule } from '@angular/core'
import { LocaleNumberPipe } from './locale-number.pipe'
import { LocaleCurrencyPipe } from './locale-currency.pipe'
import { LocalizedPipe } from './localized.pipe'
import { TranslateService } from '@ngx-translate/core'
import { ObjectValuePipe } from './object-value.pipe'
import { FileSizeFormatPipe } from './file-size-format.pipe'
import { ImageFileToObjectUrlPipe } from './image-file-to-object-url.pipe'
import { SelectedOptionsPipe } from './selected-options.pipe'
import { LimitTextPipe } from './limit-text.pipe'

@NgModule({
  declarations: [LocaleNumberPipe, LocaleCurrencyPipe, LocalizedPipe, ObjectValuePipe, SelectedOptionsPipe, LimitTextPipe],
  imports: [],
  providers: [
    TranslateService,
    LocaleCurrencyPipe,
    LocaleNumberPipe,
    LocalizedPipe,
    ObjectValuePipe,
    FileSizeFormatPipe,
    ImageFileToObjectUrlPipe,
    SelectedOptionsPipe,
    LimitTextPipe
  ],
  exports: [LocaleCurrencyPipe, LocaleNumberPipe, LocalizedPipe, ObjectValuePipe, SelectedOptionsPipe, LimitTextPipe]
})
export class WsPipesModule {}
