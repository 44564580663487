import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core'
import { ComponentType } from '../wspdf-editor/wspdf-editor.component'
import { WspdfElementComponent } from '../wspdf-element/wspdf-element.component'

@Component({
  selector: 'app-wspdf-section',
  templateUrl: './wspdf-section.component.html',
  styleUrls: ['../wspdf-element/wspdf-element.component.scss', './wspdf-section.component.scss']
})
export class WspdfSectionComponent extends WspdfElementComponent implements OnInit {
  public override type: ComponentType = ComponentType.SECTION

  @ViewChild('editorContainer') editorContainer!: ViewContainerRef

  override ngOnInit(): void {
    super.ngOnInit()
    this.el.nativeElement.classList.add('wspdf-section')
  }

  getContainerRef(): ViewContainerRef | undefined {
    return this.editorContainer
  }
}
