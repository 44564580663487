import { Component } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'
import { FieldComponent } from '../field/field.component'

@Component({
  selector: 'ws-form-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends FieldComponent implements ControlValueAccessor {
  public override value = false

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public override onChange(newValue: boolean) {}

  public override registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn
  }

  public override writeValue(value: boolean): void {
    this.value = value
  }

  public override onFieldChange(input: boolean) {
    super.onFieldChange(input)
  }
}
