import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { PdfService } from '../pdf.service'
import { WspdfTemplate } from '../../types/wspdf-template'
import { ActivatedRoute } from '@angular/router'
import { first, Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { WspdfEditorComponent } from '../wspdf-editor/wspdf-editor.component'
import { WsNotificationsService } from '@ws-core/core-library'

@Component({
  selector: 'app-wspdf-view',
  templateUrl: './wspdf-view.component.html',
  styleUrls: ['./wspdf-view.component.scss']
})
export class WspdfViewComponent implements OnInit, OnDestroy {
  @ViewChild(WspdfEditorComponent) wspdfEditorComponent!: WspdfEditorComponent

  constructor(
    private pdfService: PdfService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private wsNotificationsService: WsNotificationsService
  ) {}

  ngOnInit(): void {
    this.templateSubscription = this.route.data.subscribe((data) => {
      this.template = data['template']
    })

    if (location.hostname !== 'localhost') {
      window.onbeforeunload = function (e) {
        const confirmationMessage =
          'It looks like you have been editing something. ' + 'If you leave before saving, your changes will be lost.'

        ;(e || window.event).returnValue = confirmationMessage //Gecko + IE
        return confirmationMessage //Gecko + Webkit, Safari, Chrome etc.
      }
    }
  }

  ngOnDestroy(): void {
    window.onbeforeunload = null
  }

  template?: WspdfTemplate
  private templateSubscription?: Subscription
  savingTemplate = false

  saveTemplate(template: WspdfTemplate): void {
    this.savingTemplate = true
    this.pdfService
      .updateTemplate(template)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          this.handleResponse(200)
          this.wspdfEditorComponent.loadTemplateHistory()
          this.savingTemplate = false
        },
        error: (err: any) => {
          this.handleResponse(500)
          this.savingTemplate = false
        }
      })
  }

  public handleResponse(status: number) {
    this.pdfService.setTemplateIsReadyToPreview(true)
    this.wspdfEditorComponent.draftMode = false
    let messageSeverity: 'success' | 'error' = 'error'
    let messageDetail = 'template.message.'

    switch (status) {
      case 500:
        messageDetail += 'createTemplateInvalidNameError'
        break
      case 400:
        messageDetail += 'createTemplateInvalidNameError'
        break
      default:
        messageSeverity = 'success'
        messageDetail += 'saveSuccess'
    }

    this.wsNotificationsService.createNotification(this.translate.instant(messageDetail), {
      severity: messageSeverity
    })
  }
}
