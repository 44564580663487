{
  "save": "Save",
  "delete": "delete",
  "edit": "edit",
  "height": "Height",
  "layers": "Layers",
  "unsavedChanges": "Unsaved changes will be lost",
  "unsavedChangesLost": "If you continue, your unsaved changes will be lost",
  "template": {
    "create" : "Create template",
    "enterTextHint": "Use letters and numbers only",
    "save": "Save template",
    "duplicate": "Duplicate template",
    "name": "Template name",
    "rename": "Rename template",
    "saveChanges" : "Save changes",
    "edit" : "Edit template",
    "select": "Select template",
    "delete": "Delete template",
    "deleteTemplateDialog": "Are you sure to delete this template?",
    "datasetChoiceDialog": "Choose dataset",
    "close": "Close",
    "enterName" : "Enter template name",
    "nameExample" : "e.g. confirmation",
    "objectSettings": "Object",
    "layerOverview": "Layers",
    "content": "Content",
    "settings": {
      "settings": "Settings",
      "page": "Page",
      "save": "Save settings",
      "pageLayout": "Page layout",
      "header": "Header",
      "footer": "Footer",
      "margins": "Page margins",
      "marginTop": "Margin top",
      "marginBottom": "Margin bottom",
      "marginLeft": "Margin left",
      "marginRight": "Margin right",
      "orientation": "Page orientation",
      "portrait": "portrait",
      "landscape": "landscape",
      "displayPageCounter": "display page counter",
      "hidePageCounter": "hide page counter",
      "displayHeaderOnFirstPage": "display header on first page only",
      "displayFooterOnFirstPage": "display footer on first page only"
    },
    "configuration": {
      "open": "Show configuration",
      "configuration": "Configurations",
      "hide": "Hide configuration",
      "overview": "Overview",
      "element": "Element",
      "textSettings": "Text settings"
    },
    "help": "Help",
    "element": {
      "content": "content",
      "imageUrl": "Image URL",
      "name": "Element name",
      "visibility": "visibility",
      "show": "show",
      "hide": "hide",
      "copy": "copy",
      "delete": "delete",
      "zIndex": "Layer",
      "size": "size",
      "height": "Height",
      "width": "Width",
      "scaleProportionally": "scale proportionally",
      "position": "position",
      "positionInfo": "of element's upper left corner",
      "horizontal": "Horizontal",
      "horiz": "Horiz.",
      "vertical": "Vertical",
      "vert": "Vert.",
      "border": "border",
      "borderNone": "none",
      "borderColor": "color",
      "background": "Background",
      "backgroundImageUrl": "Background image URL",
      "backgroundColor": "Background color",
      "rotation": "rotation",
      "degrees": "degrees",
      "opacity": "opacity",
      "text": "text",
      "textFormat": "text format",
      "textAlign": "text align",
      "textAlignLeft": "align left",
      "textAlignCenter": "align center",
      "textAlignRight": "align right",
      "textAlignJustify": "justify",
      "textAlignTop": "align top",
      "textAlignBottom": "align bottom",
      "textWordBreak": "word break",
      "textWordBreakNormal": "normal",
      "textWordBreakBreakAll": "all",
      "textWordBreakKeepAll": "none",
      "textWordBreakBreakWord": "words",
      "textTransformUpperCase": "upper case",
      "textTransformLowerCase": "lower case",
      "textTransformNone": "normal",
      "textUnderline": "Underline",
      "textLineSpacing": "Line height",
      "font": "Font",
      "fontFamily": "Font family",
      "fontSize": "Font size",
      "fontStyle": "Font style",
      "fontItalic": "Italic",
      "fontWeight": "Font weight",
      "fontBold": "Bold",
      "fontColor": "Font color",
      "boundary": "Element boundary",
      "alignment": "Element alignment"
    },
    "editor": {
      "label": "Add text field",
      "placeholder": "Add placeholder",
      "image": "Add image",
      "qrCode": "Add qr code",
      "container": "Add container",
      "newDataObject": "{ data object }",
      "newTextField": "New text field",
      "preview": "PDF preview",
      "testData": "Test data",
      "editTestData": "Edit test data",
      "pageCounter": "Add page counter",
      "dataObject": "Add data object",
      "templateOverview": "All templates",
      "helpingGrid": "Helping grid",
      "toggleHelpingGridOn": "Display grid",
      "toggleHelpingGridOff": "Hide grid"
    },
    "fields": "Custom fields for template",
    "field": {
      "add": "Add custom field",
      "key": "Key of field",
      "value": "Content",
      "deleteConfirm": "Are you sure to delete the field \"{{key}}\" in \"{{lang}}\"?",
      "delete": "Delete field"
    },
    "message": {
      "saveSuccess" : "Template saved successfully",
      "saveNoChanges" : "Template has no modifications",
      "createSuccess" : "Template created successfully",
      "createError" : "Template could not be created",
      "invalidNameError" : "Template could not be created. Template name is invalid.",
      "loadingError" : "Loading templates failed",
      "doesNotExistError": "Template does not exist"
    }
  }
}
