import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { WspdfViewComponent } from './wspdf-view/wspdf-view.component'
import { templateResolver } from './template.resolver'
import { WspdfAdminComponent } from './wspdf-admin/wspdf-admin.component'

const routes: Routes = [
  {
    path: '',
    component: WspdfAdminComponent
  },
  {
    path: 'editor/template/:templateId',
    component: WspdfViewComponent,
    resolve: {
      template: templateResolver
    }
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
