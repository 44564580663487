import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core'
import { ComponentType } from '../wspdf-editor/wspdf-editor.component'
import { CdkDragEnd, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop'

export class WspdfEditorConstants {
  public static CLASS_ACTIVE_ELEMENT = 'wspdf-active-element'
  public static CLASS_EDITABLE_ELEMENT = 'wspdf-editable-element'
}

@Component({
  selector: 'app-wspdf-element',
  templateUrl: './wspdf-element.component.html',
  styleUrls: ['./wspdf-element.component.scss', '../wspdf-editor/wspdf-editor.component.scss']
})
export class WspdfElementComponent implements OnInit, DoCheck {
  @HostBinding('class.' + WspdfEditorConstants.CLASS_ACTIVE_ELEMENT) inFocus = false
  @HostBinding('class.' + WspdfEditorConstants.CLASS_EDITABLE_ELEMENT) isEditable = true

  public type!: ComponentType
  public doubleClicked = false
  public id = ''
  // public elementBoundary: string | ElementRef<HTMLElement> | HTMLElement = '#content-wrapper'

  set elementBoundary(elementBoundary: string) {
    this.el.nativeElement.dataset.elementBoundary = elementBoundary
  }
  get elementBoundary(): string {
    return this.el.nativeElement.dataset.elementBoundary || '#content-wrapper'
  }

  public oldElementBoundary = ''

  ngDoCheck(): void {
    if (this.oldElementBoundary !== this.elementBoundary) {
      this.emitElementChange('boundaryChanged', { oldElementBoundary: this.oldElementBoundary })
      this.oldElementBoundary = this.elementBoundary
      if (this.elementBoundary === '#header') this.el.nativeElement.classList.add('header')
      if (this.elementBoundary === '#footer') this.el.nativeElement.classList.add('footer')
    }
  }

  constructor(public el: ElementRef, public viewContainerRef: ViewContainerRef, public cdr: ChangeDetectorRef) {
    this.resizeObserver = new ResizeObserver((entries) => {
      this.elementResized()
    })
    this.resizeObserver.observe(this.el.nativeElement)
  }

  ngOnInit(): void {
    this.el.nativeElement.classList.add('wspdf-element')
    this.el.nativeElement.classList.add('wspdf-resize')
    this.el.nativeElement.style.position = 'absolute'
    this.id = this.getId()
  }

  setFocus(inFocus: boolean): void {
    this.inFocus = inFocus
    this.doubleClicked = false
  }

  getId(): string {
    return this.el.nativeElement.id
  }

  get title(): string {
    return this.el.nativeElement.title
  }
  set title(title: string) {
    this.el.nativeElement.title = title
  }

  get zIndex(): number {
    return this.el.nativeElement.style.zIndex
  }
  set zIndex(zIndex: number) {
    this.el.nativeElement.style.zIndex = zIndex
  }

  get hidden(): boolean {
    return this.el.nativeElement.style.display === 'none'
  }
  set hidden(hidden: boolean) {
    this.el.nativeElement.style.display = hidden ? 'none' : 'block'
  }

  repositionEditorElementComponentStarted(dragStart: CdkDragStart) {
    this.emitElementChange('moveStarted', {})
  }

  repositionEditorElementComponent(elementDragEnd: CdkDragEnd): void {
    const component = elementDragEnd.source.element.nativeElement.parentElement

    if (component) {
      const top = component.offsetTop + elementDragEnd.source.getFreeDragPosition().y
      const left = component.offsetLeft + elementDragEnd.source.getFreeDragPosition().x
      component.style.top = top + 'px'
      component.style.left = left + 'px'

      this.emitElementChange('moved', { x: left, y: top })

      elementDragEnd.source.setFreeDragPosition({ x: 0, y: 0 })
    }
  }

  @Output() elementChangedEvent: EventEmitter<{
    event: string
    data?: any
    component: WspdfElementComponent
  }> = new EventEmitter<{
    event: string
    data?: any
    component: WspdfElementComponent
  }>()
  private resizeObserver: ResizeObserver = new ResizeObserver(() => {})
  public isBeingResized = false
  public aspectRatio = 1
  public keepAspectRatio = false
  public pxToCmFactor = 1
  public cmToPxFactor = 1

  elementResized() {
    this.aspectRatio = this.el.nativeElement.offsetWidth / this.el.nativeElement.offsetHeight
    this.emitElementChange('resized', {
      width: this.el.nativeElement.offsetWidth,
      height: this.el.nativeElement.offsetHeight,
      isBeingResized: this.isBeingResized
    })
  }

  emitElementChange(event: string, data?: any) {
    this.elementChangedEvent.emit({ event: event, component: this, data: data })
  }

  resizeDragStarted(event: any) {
    this.isBeingResized = true
    this.emitElementChange('resizeDragStarted', {
      width: this.el.nativeElement.offsetWidth,
      height: this.el.nativeElement.offsetHeight,
      isBeingResized: this.isBeingResized
    })
  }

  resizeDragEnded(dragEnd: CdkDragEnd | null = null) {
    setTimeout(() => {
      this.isBeingResized = false
      this.emitElementChange('resizeDragEnded', {
        width: this.el.nativeElement.offsetWidth,
        height: this.el.nativeElement.offsetHeight,
        isBeingResized: this.isBeingResized
      })
    }, 100)
  }

  resizeDragMoved(dragHandlePosition: 'right' | 'bottom' | 'corner', dragMove: CdkDragMove) {
    this.isBeingResized = true

    const parentElement = dragMove.source.element.nativeElement.parentElement?.parentElement
    let containerParentElement = parentElement?.parentElement
    const editor = document.getElementById('wspdfEditorDisplayContainer')

    if (this.elementBoundary === '#wspdfEditorInnerPageMarker') {
      // set containerParentElement to innerPageMarker instead of contentWrapper
      containerParentElement = containerParentElement?.parentElement
    }

    if (!parentElement || !editor) return

    const parentElementBoundaries = parentElement.getBoundingClientRect()
    let containerElementBoundaries = parentElementBoundaries
    if (containerParentElement != undefined) {
      containerElementBoundaries = containerParentElement.getBoundingClientRect()
    }

    // max width or height depending on boundaries of parent element
    const currentMaxWidth = editor.offsetWidth - parentElementBoundaries.x + editor.offsetLeft
    const currentMaxHeight = editor.offsetHeight - parentElementBoundaries.y + editor.offsetTop

    // targeted width or height depending on pointer position
    const targetWidth =
      Math.min(containerElementBoundaries.right, dragMove.pointerPosition.x) - parentElementBoundaries.x
    const targetHeight =
      Math.min(containerElementBoundaries.bottom, dragMove.pointerPosition.y) - parentElementBoundaries.y

    // new width or height depending on targeted width or height and max width or height
    const newWidth = Math.min(targetWidth, currentMaxWidth)
    let newHeight = Math.min(targetHeight, currentMaxHeight)

    const newWidthString = newWidth + 'px'
    const newHeightString = newHeight + 'px'

    if (['right', 'corner'].includes(dragHandlePosition)) {
      parentElement.style.width = newWidthString
      // When Shift key is pressed, don't keep the aspect ratio
      if (dragMove.event.shiftKey && !this.keepAspectRatio) {
        parentElement.style.height = newHeightString
      } else {
        parentElement.style.height = 'auto'
        parentElement.style.height = parentElement.offsetHeight + 'px'
        //adjust if exceeds boundary
        if (parentElement.offsetHeight + parentElementBoundaries.top > containerElementBoundaries.bottom) {
          newHeight = containerElementBoundaries.bottom - parentElementBoundaries.top
          parentElement.style.height = newHeight + 'px'
          parentElement.style.width = 'auto'
          parentElement.style.width = parentElement.offsetWidth + 'px'
        }
      }
    }

    if (['bottom', 'corner'].includes(dragHandlePosition) && !this.keepAspectRatio) {
      parentElement.style.height = newHeightString
    }

    // used to reset drag position of handle for it to stay at the same position, only the size of the element changes
    dragMove.source.setFreeDragPosition({ x: 0, y: 0 })
  }

  ngOnDestroy() {
    this.resizeObserver.unobserve(this.el.nativeElement)
  }
}
