import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
import { inject } from '@angular/core'
import { PdfService } from './pdf.service'
import { WspdfTemplate } from '../types/wspdf-template'
import { first } from 'rxjs'

export const templateResolver: ResolveFn<WspdfTemplate> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const pdfService = inject(PdfService)
  const templateId = route.paramMap.get('templateId')

  return pdfService.loadTemplateById(Number(templateId)).pipe(first())
}
