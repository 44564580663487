@if (ngControl) {
  <mat-form-field class="w-full" [class.hide-subscript]="!hint && !hasErrors">
    @if (label && !hideLabel) {
      <mat-label>{{ label }}</mat-label>
    }
    <input
      matNativeControl
      #inputElement
      [attr.step]="step"
      [attr.min]="min"
      [attr.max]="max"
      [attr.maxLength]="maxLength"
      [placeholder]="placeholder"
      [type]="showPassword ? 'text' : type"
      [formControl]="formControl"
      [matAutocompleteDisabled]="options.length === 0"
      [matAutocomplete]="auto"
      [autocomplete]="autocomplete"
      (input)="handleInputChange()"
      (blur)="handleInputBlur()"
      (ngModelChange)="onFieldChange($event)"
    />
    @if (prefix) {
      <span matTextPrefix>{{ prefix }}</span>
    }
    @if (suffix) {
      <span matTextSuffix>{{ suffix }}</span>
    }

    @if (clearable && formControl.value) {
      <mat-icon class="cursor-pointer" (click)="clearInputValue()" matSuffix>close</mat-icon>
    } @else if (type === 'password') {
      <mat-icon class="cursor-pointer" (click)="showPassword = !showPassword" matSuffix>
        {{ showPassword ? 'visibility_off' : 'visibility' }}
      </mat-icon>
    } @else if (speechRecognition && speechRecognitionService.speechRecognitionSupported) {
      <mat-icon
        class="cursor-pointer ws-input_speech-recognition-icon ws-speech-recognition-icon"
        [ngClass]="{ recording: listening }"
        matSuffix
        (click)="toggleSpeechRecognition()"
        (touchstart)="touchStartSpeechRecognition()"
        (touchend)="touchStopSpeechRecognition()"
        >mic
      </mat-icon>
    } @else if (enableCopyToClipboard) {
      <mat-icon class="cursor-pointer" [cdkCopyToClipboard]="value ? value.toString() : ''" matSuffix
        >content_copy
      </mat-icon>
    } @else if (!!icon) {
      <mat-icon matSuffix [class.cursor-pointer]="iconClick.observed" (click)="iconClick.emit(icon)"
        >{{ icon }}
      </mat-icon>
    }

    <mat-autocomplete
      #auto="matAutocomplete"
      [autoActiveFirstOption]="true"
      (optionSelected)="optionSelected.emit($event)"
    >
      @for (option of filteredOptions; track option.value) {
        <mat-option [disabled]="!!option.disabled" [value]="option.value">
          {{ option.label | translate }}
        </mat-option>
      }
    </mat-autocomplete>

    @if (hasErrors) {
      <mat-error>{{ validationErrorMessage }}</mat-error>
    }

    @if (hint) {
      <mat-hint>{{ hint }}</mat-hint>
    }
  </mat-form-field>
}
