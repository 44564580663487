<div
  style="position: absolute; width: 100%; height: 100%"
  cdkDragBoundary="#wspdfEditorDisplayContainer"
  (cdkDragStarted)="repositionEditorElementComponentStarted($event)"
  (cdkDragEnded)="repositionEditorElementComponent($event)"
  cdkDrag
>
  <span class="dragHandle corner" cdkDrag (cdkDragMoved)="resizeDragMoved('corner', $event)"></span>
  <span
    #dragHandleRight
    cdkDragLockAxis="x"
    class="dragHandle right"
    cdkDrag
    (cdkDragStarted)="resizeDragStarted($event)"
    (cdkDragMoved)="resizeDragMoved('right', $event)"
  ></span>
  <span
    #dragHandleBottom
    cdkDragLockAxis="y"
    class="dragHandle bottom"
    cdkDrag
    (cdkDragStarted)="resizeDragStarted($event)"
    (cdkDragMoved)="resizeDragMoved('bottom', $event)"
  ></span>
  <ng-template #editorContainer></ng-template>
</div>
