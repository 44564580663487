import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'localeCurrency'
})
export class LocaleCurrencyPipe implements PipeTransform {
  transform(
    value: number,
    currency = '€',
    minimumFractionDigits = 2,
    currencyDisplay?: 'symbol' | 'narrowSymbol' | 'code' | 'name'
  ): string {
    return value.toLocaleString(undefined, {
      currency,
      style: 'currency',
      minimumFractionDigits,
      maximumFractionDigits: 2,
      currencyDisplay,
      currencySign: 'accounting'
    })
  }
}
