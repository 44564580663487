import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatTabsModule } from '@angular/material/tabs'
import * as emojisJson from '../../../data/emoji.json'

@Component({
  selector: 'ws-form-emoji-insert',
  standalone: true,
  imports: [CommonModule, MatTabsModule],
  templateUrl: './emoji-insert.component.html',
  styleUrl: './emoji-insert.component.scss'
})
export class EmojiInsertComponent implements OnInit {
  emojiMap = new Map<string, string[]>()

  @Output() emojiClicked = new EventEmitter<string>()

  ngOnInit() {
    this.mapEmojis()
  }

  mapEmojis() {
    for (const [key, value] of Object.entries(emojisJson)) {
      if (this.emojiMap.has(value.category)) {
        this.emojiMap.get(value.category)?.push(value.emoji)
      } else {
        if (value.category) {
          this.emojiMap.set(value.category, [value.emoji])
        }
      }
    }
    this.emojiMap.delete('undefined')
  }

  emojiSelected(event: MouseEvent, emoji: string) {
    event.preventDefault()
    this.emojiClicked.emit(emoji)
  }
}
