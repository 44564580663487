<mat-dialog-content>
  <div class="flex flex-col items-center">
    <span mat-dialog-title class="flex">{{ 'template.deleteTemplateDialog' | translate }}</span>
    {{ this.data }}
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex justify-between">
  <button
    mat-icon-button
    class="mat-button"
    (click)="save()"
    matTooltip="{{ 'template.delete' | translate }}"
    matTooltipPosition="below"
  >
    <mat-icon>check</mat-icon>
  </button>
  <button
    mat-icon-button
    class="mat-button"
    (click)="close()"
    matTooltip="{{ 'template.close' | translate }}"
    matTooltipPosition="below"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
