import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { SelectOption } from '@ws-core/core-library'

@Component({
  selector: 'lib-pdf-dataset-choice-dialog',
  templateUrl: './pdf-dataset-choice-dialog.component.html',
  styleUrls: ['./pdf-dataset-choice-dialog.component.scss']
})
export class PdfDatasetChoiceDialogComponent {
  public selectedDataSet = ''

  constructor(
    private dialogRef: MatDialogRef<PdfDatasetChoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectOption[] = []
  ) {}

  ngOnInit() {}

  save() {
    this.dialogRef.close(this.selectedDataSet)
  }

  close() {
    this.dialogRef.close()
  }
}
