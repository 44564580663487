<div class="mat-mdc-form-field w-full" *ngIf="ngControl">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-button-toggle-group [value]="value" (change)="onFieldChange($event.value)">
    <mat-button-toggle
      [disabled]="option.disabled"
      class="flex-grow"
      *ngFor="let option of options"
      [value]="option.value"
    >
      {{ option.label | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <div class="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align" *ngIf="hasErrors">
    <div class="mat-mdc-form-field-error-wrapper">
      <mat-error>
        {{ validationErrorMessage }}
      </mat-error>
    </div>
  </div>
</div>
