import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { FormGroup } from '@angular/forms'

@Injectable({
  providedIn: 'root'
})
export class WsFormsService {
  translationTabGroupsLanguage: {
    [key: string]: BehaviorSubject<string>
  } = {}

  public getTranslationGroupSubject(group: string, language: string): BehaviorSubject<string> {
    if (!this.translationTabGroupsLanguage[group]) {
      this.translationTabGroupsLanguage[group] = new BehaviorSubject<string>(language)
    }

    return this.translationTabGroupsLanguage[group]
  }

  /**
   * Validates all form fields of a given form group
   *
   * @param {FormGroup} formGroup - form group to validate
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field)
      if (control) {
        control.markAsTouched({ onlySelf: true })
        control.markAsDirty()
        control.updateValueAndValidity()
      }
    })
  }
}
