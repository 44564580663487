import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { WspdfViewComponent } from './wspdf-view/wspdf-view.component'
import { WspdfEditorComponent } from './wspdf-editor/wspdf-editor.component'
import { QRCodeModule } from 'angularx-qrcode'
import { TranslateModule } from '@ngx-translate/core'
import { MatCardModule } from '@angular/material/card'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { WsFormsModule } from '@ws-core/core-library'
import { MatRadioModule } from '@angular/material/radio'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTableModule } from '@angular/material/table'
import { MatListModule } from '@angular/material/list'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatSliderModule } from '@angular/material/slider'
import { MatSelectModule } from '@angular/material/select'
import { WspdfElementComponent } from './wspdf-element/wspdf-element.component'
import { WspdfStaticLabelComponent } from './wspdf-static-label/wspdf-static-label.component'
import { WspdfLabelComponent } from './wspdf-label/wspdf-label.component'
import { PdfDatasetChoiceDialogComponent } from './pdf-dataset-choice-dialog/pdf-dataset-choice-dialog.component'
import { WspdfSectionComponent } from './wspdf-section/wspdf-section.component'
import { PdfCreateTemplateDialogComponent } from './pdf-create-template-dialog/pdf-create-template-dialog.component'
import { WspdfImageComponent } from './wspdf-image/wspdf-image.component'
import { WspdfQrCodeComponent } from './wspdf-qr-code/wspdf-qr-code.component'
import { PdfDeleteTemplateDialogComponent } from './pdf-delete-template-dialog/pdf-delete-template-dialog.component'
import { PdfEditTestDataDialogComponent } from './pdf-edit-test-data-dialog/pdf-edit-test-data-dialog.component'
import { WspdfDatasetComponent } from './wspdf-dataset/wspdf-dataset.component'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HeaderInterceptor } from './core/header.interceptor'
import { WspdfAdminComponent } from './wspdf-admin/wspdf-admin.component'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { PdfPageSettingsComponent } from './pdf-page-settings/pdf-page-settings.component'
import { WsPipesModule } from '@ws-core/core-library'
import { PlatformService } from '../../../demo/src/app/platform.service'
import { MatMenuModule } from '@angular/material/menu'

@NgModule({ declarations: [
        AppComponent,
        WspdfViewComponent,
        WspdfEditorComponent,
        WspdfElementComponent,
        WspdfStaticLabelComponent,
        WspdfLabelComponent,
        WspdfElementComponent,
        WspdfStaticLabelComponent,
        WspdfSectionComponent,
        PdfCreateTemplateDialogComponent,
        WspdfImageComponent,
        WspdfQrCodeComponent,
        PdfDeleteTemplateDialogComponent,
        PdfEditTestDataDialogComponent,
        WspdfDatasetComponent,
        PdfDatasetChoiceDialogComponent,
        WspdfAdminComponent,
        PdfPageSettingsComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        QRCodeModule,
        TranslateModule,
        MatCardModule,
        ReactiveFormsModule,
        WsFormsModule,
        FormsModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        DragDropModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatRadioModule,
        MatSidenavModule,
        MatTabsModule,
        MatTableModule,
        MatListModule,
        MatCheckboxModule,
        MatSliderModule,
        MatSelectModule,
        MatSnackBarModule,
        BrowserAnimationsModule,
        WsPipesModule,
        MatMenuModule], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
        { provide: 'PlatformService', useClass: PlatformService },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
