<span matSnackBarLabel>
  {{ data.message | translate }}
</span>

<span *ngIf="data.action || data.icon" matSnackBarActions>
  <button mat-button matSnackBarAction (click)="snackBarRef.dismissWithAction()">
    <mat-icon *ngIf="data.icon && data.iconPosition === 'right'" iconPositionEnd>{{ data.icon }}</mat-icon>
    <mat-icon *ngIf="data.icon && data.iconPosition === 'left'">{{ data.icon }}</mat-icon>

    <ng-container *ngIf="data.action">{{ data.action | translate }}</ng-container>
  </button>
</span>
